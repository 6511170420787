import ModalComponent from "../Modal/index";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../theme/colors";
import API from "../../api";
import { useSelector } from "react-redux";

const CheckBoxesContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

const CheckBoxWraper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  a {
    color: ${colors.blue};
    text-decoration: underline;
    padding-left: 4px;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const HeaderLabel = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin-top: 20px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

const AcceptTermsModal = () => {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && user.needsToAcceptTCPP) {
      setShowTermsModal(true);
    }
  }, [user]);

  const handlers = {
    acceptClick: async () => {
      //API Call
      setLoading(true);
      try {
        await API.User.UserAcceptTCPP();
        setShowTermsModal(false);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
  };

  return (
    <>
      <ModalComponent
        headerText={"Accept Terms and Conditions"}
        open={showTermsModal}
      >
        <HeaderLabel>
          Before using the Boreas Portal, you must accept the following terms:
        </HeaderLabel>
        <div>
          <CheckBoxesContainer>
            <CheckBoxWraper>
              I accept the Boreas
              <a target="_blank" href="/terms-of-service">
                Terms of Service
              </a>
            </CheckBoxWraper>
            <CheckBoxWraper>
              I accept the Boreas
              <a target="_blank" href="/privacy-policy">
                Privacy Policy
              </a>
            </CheckBoxWraper>
          </CheckBoxesContainer>
        </div>
        <ActionButtonsContainer>
          {/* <Button onClick={handlers?.cancel}>LOGOUT</Button> */}
          {loading ? (
            <Button disabled variant="contained">
              Saving&#8230;
            </Button>
          ) : (
            <Button onClick={handlers?.acceptClick} variant="contained">
              I ACCEPT
            </Button>
          )}
        </ActionButtonsContainer>
      </ModalComponent>
    </>
  );
};

export default AcceptTermsModal;
