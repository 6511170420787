import styled from "styled-components";
import { Container, Toolbar, Box } from "@mui/material";
import {
  MenuBook as MenuBookIcon,
  Help as HelpIcon,
  GppGood as AdminIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import colors from "../../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import { updateShowSupportModal } from "../../redux-store/uxSlice";

const ContentArea = styled(Container)`
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const toolBarStyles = {
  backgroundColor: colors.blue,
  minHeight: "0 !important",
  padding: "8px 0",
  borderRadius: "20px",
  margin: "5px",
};

const AdminLinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${colors.white};
  text-decoration: none;
  &:hover {
    opacity: 0.7;
    text-decoration: underline;
  }
  font-size: 12px;
`;
const SupportLinksContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  gap: 20px;
  a {
    color: ${colors.white};
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    &:hover {
      opacity: 0.7;
      text-decoration: underline;
    }
  }
`;

const SupportBarContainer = ({ isAdminArea = false, isAdminUser = false }) => {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organization);

  if (isAdminArea) {
    toolBarStyles.backgroundColor = colors.orange;
  } else {
    toolBarStyles.backgroundColor = colors.blue;
  }
  return (
    <Toolbar disableGutters sx={toolBarStyles}>
      <ContentArea maxWidth="xl">
        {isAdminUser ? (
          <AdminLinkContainer to="/admin/organizations">
            <AdminIcon fontSize={"small"} />
            ADMINISTRATION
            {!isAdminArea && organization.name && (
              <span> &#62; {organization.name}</span>
            )}
          </AdminLinkContainer>
        ) : (
          <div></div>
        )}
        <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
          <SupportLinksContainer>
            <a target="_blank" href="/knowledge-base">
              <MenuBookIcon fontSize={"small"} />
              KNOWLEDGE BASE
            </a>
            <Link
              onClick={() => {
                dispatch(updateShowSupportModal(true));
              }}
            >
              <HelpIcon fontSize={"small"} />
              SUPPORT
            </Link>
          </SupportLinksContainer>
        </Box>
      </ContentArea>
    </Toolbar>
  );
};

export default SupportBarContainer;
