import { get } from "aws-amplify/api";

const ActivityGet = async ({ resource, resourceId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "activity",
      options: {
        queryParams: { resource, resourceId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const ActivityGetAll = async ({ organizationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "activity/all",
      options: {
        queryParams: { organizationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const Methods = {
  ActivityGet,
  ActivityGetAll,
};

export default Methods;
