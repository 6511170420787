// Boiler Plate Imports
import styled from "styled-components";
import colors from "../../theme/colors";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import { update as updateUserProfile } from "../../redux-store/userSlice";
import API from "../../api";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

// Conatiner Component Imports
import { Button, Card } from "@mui/material";
import Table from "../../components/Table";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";
import TableHeader from "../../components/Page/tableHeader";
import TableSubHeader from "../../components/Page/tableSubHeader";

const HalfWidthWidgetContainer = styled.div`
  margin: 20px 0;
  display: flex;
  gap: 20px;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;
const HalfWidthWidget = styled(Card)`
  width: 100%;
  padding: 20px;
`;
const WidgetHeader = styled.div`
  font-size: 17px;
  font-weight: bold;
  border-bottom: 1px solid ${colors.gray};
  padding: 0 0 6px 0;
  margin: 0 0 6px 0;
`;

const WidgetSubHeader = styled.div`
  font-size: 12px;
  color: ${colors.gray};
`;

const WidgetHeaderContainer = styled.div`
  width: 100%;
`;

const WidgetBody = styled.div`
  font-size: 12px;
  color: ${colors.gray};
`;

const WidgetText = styled.div`
  font-size: 12px;
  color: ${colors.black};
`;

const BodyHeader = styled.div`
  margin: 20px 0 8px;
  font-size: 15px;
  color: ${colors.black};
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StatusTag = styled.div`
  border-radius: 15px;
  text-align: center;
  padding: 2px;
  width: 87px;
  font-weight: bold;
  background: ${colors.grayLight};
  color: ${colors.grayDark};
  border: 1px solid ${colors.gray};
  &.alarm {
    background: ${colors.redLightError};
    color: ${colors.redError};
    border: 1px solid ${colors.redError};
  }
  &.progress {
    background: ${colors.yellowLight};
    color: ${colors.yellow};
    border: 1px solid ${colors.yellow};
  }
  &.resolved {
    background: ${colors.greenLightSuccess};
    color: ${colors.greenSuccess};
    border: 1px solid ${colors.greenSuccess};
  }
`;

const OrganizationAlarmsContainer = () => {
  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);

  const [alarmTableRows, setAlarmTableRows] = useState([]);
  const [loadingAlarms, setLoadingAlarms] = useState(true);
  const dispatch = useDispatch();

  const { organizationId } = useParams();

  const alarmTableColumns = [
    {
      title: "Alarm Type",
      propertyName: "alarmInfoDisplayName",
      sortProperty: "alarmInfoDisplayName",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/alarms/${row.id}`;
      },
    },
    {
      title: "Alarm Status",
      propertyName: "active",
      sortProperty: "active",
      sortPropertyType: "string",
      sortDefault: "desc",
      render: (row) => {
        const statusString = row.active === "true" ? "Active" : "Resolved";
        return (
          <StatusTag className={row.active === "true" ? "alarm" : "resolved"}>
            {statusString}
          </StatusTag>
        );
      },
    },
    {
      title: "Resolution Status",
      propertyName: "resolutionStatus",
      sortProperty: "resolutionStatus",
      sortPropertyType: "string",
      render: (row) => {
        let statusString = "Not Started";
        let className = "alarm";
        if (row.resolutionStatus === "resolved") {
          statusString = "Resolved";
          className = "resolved";
        } else if (row.resolutionStatus === "progress") {
          statusString = "In Progress";
          className = "progress";
        }
        return <StatusTag className={className}>{statusString}</StatusTag>;
      },
    },
    {
      title: "Tank Name",
      propertyName: "tankName",
      sortProperty: "tankName",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/tanks/${row.tankId}`;
      },
    },
    {
      title: "Tank Location",
      propertyName: "locationName",
      sortProperty: "locationName",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/locations/${row.locationId}`;
      },
    },
    {
      title: "Alarm Created",
      propertyName: "created",
      sortProperty: "created",
      sortPropertyType: "number",
      render: (row) => new Date(row.created).toLocaleString(),
    },
    {
      title: "Alarm Resolved",
      propertyName: "resolved",
      sortProperty: "resolvedDate",
      sortPropertyType: "number",
      render: (row) => {
        if (row.resolvedDate) {
          return new Date(row.resolvedDate).toLocaleString();
        } else {
          return "-";
        }
      },
    },
  ];

  useEffect(() => {
    if (organizationId) {
      dataLayer.GetOrganizationAlarms();
    }
  }, [organizationId]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "Alarms",
          link: "/alarms",
        },
      ])
    );
  }, [organization]);

  const dataLayer = {
    GetOrganizationAlarms: async () => {
      setLoadingAlarms(true);
      const alarms = await API.Alarm.AlarmsGet({ organizationId });
      const alarmsRows = alarms.map((alarm) => {
        return {
          ...alarm,
          alarmInfoDisplayName: alarm.alarmInfo.displayName,
          tankName: alarm.tank.name, //flatten for the table and sorting
          locationName: alarm.tank.location.name,
        };
      });
      alarmsRows.sort((a, b) => {
        return b.created - a.created;
      });
      setAlarmTableRows(alarmsRows);
      setLoadingAlarms(false);
    },
  };

  const handlers = {};

  return (
    <>
      <BreadCrumbs />
      <Card>
        <TableHeader>
          <div>
            <h2>Alarms</h2>
            <TableSubHeader>
              Below is a list of all Alarms within this Organization
            </TableSubHeader>
          </div>

          {/* <Button
            onClick={handlers.addLocationClick}
            variant="contained"
            color="primary"
            size="large"
          >
            ADD ALARM
          </Button> */}
        </TableHeader>
        <Table
          columns={alarmTableColumns}
          rows={alarmTableRows}
          loading={loadingAlarms}
          placeholder="There are no alarms yet"
        />
      </Card>
      {/* <ModalComponent headerText="Add Location" open={openModal}>
        <OrganizationLocationForm
          handleSave={handlers.saveLocation}
          handleCancel={handlers.closeLocationModal}
          loading={savingLocation}
          error={savingLocationError}
        />
      </ModalComponent> */}
      <HalfWidthWidgetContainer>
        <HalfWidthWidget>
          <WidgetHeaderContainer>
            <WidgetHeader>Types of Alarms</WidgetHeader>
            <WidgetSubHeader>
              The following types of alarms are supported by the Boreas system
            </WidgetSubHeader>
          </WidgetHeaderContainer>
          <WidgetBody>
            <BodyHeader>Low Weight</BodyHeader>
            <div>
              When a tank's liquid nitrogen level falls below the tank's set
              threshold
            </div>
            <BodyHeader>High Consumption Rate</BodyHeader>
            <div>
              When a tank's liquid nitrogen consumption rate is above tank's
              normal levels
            </div>
            <BodyHeader>Low External Temperature</BodyHeader>
            <div>
              When a tank's external temperature is lower than the tank's set
              threshold
            </div>
            <BodyHeader>Low Battery</BodyHeader>
            <div>When a tank's scale battery is low</div>
            <BodyHeader>Tank Offline</BodyHeader>
            <div>
              When a tank has been unreachable for over 3 hours 30 minutes
            </div>
          </WidgetBody>
        </HalfWidthWidget>
        {/* <HalfWidthWidget>
          <WidgetHeader>On Call</WidgetHeader>
          <WidgetSubHeader>
            Below is a summary of your current on call setup.
          </WidgetSubHeader>
          <WidgetBody>
            <BodyHeader>
              On Call is not setup{" "}
              <WarningAmberIcon sx={{ color: "#ED6C02}" }} />
            </BodyHeader>
            <WidgetText>
              Manage your on call settings to ensure notifications are sent
              properly
            </WidgetText>
            <Button
              sx={{marginTop: '20px'}}
              component={Link}
              to={"/oncall"}
              variant="contained"
              color="primary"
              size="large"
            >
              MANAGE ON CALL
            </Button>
          </WidgetBody>
        </HalfWidthWidget> */}
      </HalfWidthWidgetContainer>
    </>
  );
};

export default OrganizationAlarmsContainer;
