const FormatAddress = ({ address1, address2, city, state, zip }) => {
  return `${address1}, ${
    address2 ? address2 + "," : ""
  } ${city}, ${state} ${zip}`;
};

const FormatAddressShort = ({ address1, address2 }) => {
  if (address2) {
    return `${address1}, ${address2}`;
  } else {
    return `${address1}`;
  }
};

export { FormatAddress, FormatAddressShort };
