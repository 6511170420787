import styled from "styled-components";
import { Button, Paper } from "@mui/material";
import colors from "../../theme/colors";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const MetricBoxesContainer = styled.div`
  padding: 0 15px 20px;
  @media (max-width: 1080px) {
    padding: 0 0px 20px;
  }
  @media print {
    display: none !important;
  }
`;

const MetricsHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const MetricBoxesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;

const MetricBox = styled(Paper)`
  padding: 20px 15px;
  width: 100%;
  @media (max-width: 1080px) {
    width: calc(50% - 10px);
  }
`;

const MetricValue = styled.div`
  font-weight: bold;
  padding: 5px 0 0 5px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.$redText ? colors.redError : colors.black)};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : "35px")};
`;

const MetricTitle = styled.div`
  font-size: 13px;
  white-space: nowrap;
  color: ${colors.gray};
`;

const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const MetricBoxesComponent = ({
  title = "Metrics",
  metrics = [],
  showHeader = true,
  organizationId,
}) => {
  // const { isAdminArea } = useSelector((state) => state.ux);
  // const organization = useSelector((state) => state.organization);
  const { organizationId: _orgId } = useParams();
  const _organizationId = organizationId || _orgId;
  return (
    <MetricBoxesContainer>
      {showHeader && <MetricsHeader>{title}</MetricsHeader>}
      <MetricBoxesWrapper>
        {metrics.map((metric, i) => {
          return (
            <MetricBox key={`mtr-box${i}`}>
              <BoxHeader>
                <MetricTitle>{metric.title}</MetricTitle>
                {metric.to && (
                  <Button
                    sx={{
                      boxShadow: 2,
                      fontSize: "10px",
                      padding: "4px 12px",
                      minWidth: "auto",
                    }}
                    size="small"
                    color="black"
                    to={`/${_organizationId}${metric.to}`}
                    component={Link}
                    disabled={!metric.value?.toString() || metric.disabled}

                  >
                    View
                  </Button>
                )}
              </BoxHeader>

              <MetricValue
                $fontSize={metric.fontSize}
                $redText={metric.redText}
              >
                {metric.value}
              </MetricValue>
            </MetricBox>
          );
        })}
      </MetricBoxesWrapper>
    </MetricBoxesContainer>
  );
};

export default MetricBoxesComponent;
