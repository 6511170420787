import styled from "styled-components";
import { Button, Card, TextField } from "@mui/material";
import colors from "../../theme/colors";
import { AddCommentIcon } from "../../theme/icons";
import { formatDateTimeStamp } from "../../utils/date";
import { useState } from "react";
import FormFieldErrorClass from "../../components/Forms/models/formFieldError";
import { useEffect } from "react";
import API from "../../api";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";

const Widget = styled(Card)`
  width: 100%;
  padding: 20px;
`;

const WidgetHeader = styled.div`
  font-size: 17px;
  font-weight: bold;
  border-bottom: 1px solid ${colors.gray};
  padding: 0 0 6px 0;
  margin: 0 0 6px 0;
`;

const WidgetBody = styled.div`
  font-size: 12px;
  color: ${colors.gray};
`;

const ActivityListWrapper = styled.div`
  overflow: auto;
  max-height: 600px;
`;

const ActvityItemContainer = styled.div`
  display: flex;
  gap: 6px;
  font-size: 14px;
  border-bottom: 1px solid ${colors.grayLight};
  padding: 12px 0;
`;

const ActivityIcon = styled.div`
  color: ${colors.gray};
  svg {
    font-size: 22px;
  }
`;

const ActivityDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ActivityUsername = styled.div`
  color: ${colors.grayDark};
  font-size: 12px;
`;

const ActivityBody = styled.div`
  color: ${colors.black};
`;

const ActivityDateTime = styled.div`
  color: ${colors.black};
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NotesWidget = () => {
  const dispatch = useDispatch();
  const { tankId, organizationId } = useParams();

  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState("");
  const [noteError, setNoteError] = useState(new FormFieldErrorClass());
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);

  //Polling Setup
  const [dataInterval, setDataInterval] = useState();
  let refreshIntervalSeconds = 60;
  const [pollingStarted, setPollingStarted] = useState(false);
  const [nextRefreshInSeconds, setNextRefreshInSeconds] = useState(
    refreshIntervalSeconds
  );

  useEffect(() => {
    if (!pollingStarted) {
      FetchData();
      PollData();
      setPollingStarted(true);
    }
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(dataInterval);
    };
  }, []);

  useEffect(() => {
    if (nextRefreshInSeconds === 0) {
      FetchData();
      setNextRefreshInSeconds(refreshIntervalSeconds);
    }
  }, [nextRefreshInSeconds]);

  const PollData = async () => {
    clearInterval(dataInterval);
    const d = setInterval(() => {
      setNextRefreshInSeconds((s) => s - 1);
    }, 1000);
    setDataInterval(d);
  };

  const FetchData = async () => {
    await dataLayer.GetNotes();
  };

  const dataLayer = {
    GetNotes: async () => {
      const _notes = await API.Tank.TankGetNotes({
        tankId,
      });
      _notes.forEach((item) => {
        item.display = {
          userName: item.userName,
          body: item.data.note,
        };
      });
      setNotes(_notes);
    },
    PostTankNote: async () => {
      setSavingError("");
      setSaving(true);
      try {
        const _note = await API.Tank.TankCreateNote({
          tankId,
          note,
        });
        setNote("");
        //Reload Data
        dataLayer.GetNotes();
        //Create Success Notification
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Note Added",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSaving(false);
    },
  };

  const validate = {
    note: async (value) => {
      setNoteError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setNoteError({
          hasError: true,
          message: "Note Text Required",
        });
        return;
      }
      if (value.length > 1000) {
        setNoteError({
          hasError: true,
          message: "Note Max 1000 Charaters",
        });
        return;
      }
    },
  };

  const handlers = {
    onChange: async (e) => {
      const { value } = e.target;
      validate.note(value);
      setNote(value);
    },
    submit: async () => {
      validate.note(note);
      if (noteError.hasError) {
        return;
      }
      const _res = await dataLayer.PostTankNote();
    },
  };

  return (
    <Widget>
      <WidgetHeader>Tank Notes</WidgetHeader>
      <WidgetBody>
        <ActivityListWrapper>
          {notes.map((item) => {
            return (
              <ActvityItemContainer key={item.id}>
                <ActivityIcon>
                  <AddCommentIcon sx={{ color: colors.blueLight }} />
                </ActivityIcon>
                <ActivityDetailsContainer>
                  <ActivityUsername>{item.display.userName}</ActivityUsername>
                  <ActivityBody>{item.display.body}</ActivityBody>
                  <ActivityDateTime>
                    {formatDateTimeStamp(item.created)}
                  </ActivityDateTime>
                </ActivityDetailsContainer>
              </ActvityItemContainer>
            );
          })}
        </ActivityListWrapper>

        <TextField
          sx={{ width: "100%", margin: "10px 0px" }}
          id="tank-notes"
          label="Add Notes"
          variant="outlined"
          multiline
          rows={3}
          value={note}
          onChange={handlers.onChange}
          helperText={noteError.message}
          error={noteError.hasError}
        />
        <ButtonContainer>
          {saving ? (
            <Button disabled variant="contained">
              Saving&#8230;
            </Button>
          ) : (
            <Button onClick={handlers.submit} variant="contained">
              Add Note
            </Button>
          )}
        </ButtonContainer>
        <ButtonContainer>
          <div>{savingError}</div>
        </ButtonContainer>
      </WidgetBody>
    </Widget>
  );
};

export default NotesWidget;
