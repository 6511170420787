import { useEffect, useState } from "react";
import Login from "./components/Auth/login";
import Routing from "./components/Routing";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import API from "./api";
import { useSelector, useDispatch } from "react-redux";
import { update as updateUserProfile } from "./redux-store/userSlice";
import {
  updateReloadOrganization,
  updateIsAdminArea,
  updateOrganizationWarningBanner,
  updateIsAdminUser,
} from "./redux-store/uxSlice";
import {
  updateOrganization,
  clearOrganization,
} from "./redux-store/organizationSlice";
import TermsOfService from "./components/TermsConditionsPrivacyPolicy/terms-of-service";
import PrivacyPolicy from "./components/TermsConditionsPrivacyPolicy/privacy-policy";
import KnowledgeBase from "./components/KnowledgeBase";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAdminArea, reloadOrganization } = useSelector((state) => state.ux);

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { route } = useAuthenticator((context) => [context.route]);
  const [userProfile, setUserProfile] = useState();

  const dispatch = useDispatch();

  const dataLayer = {
    GetOrganization: async ({ organizationId }) => {
      //Reset States
      dispatch(
        updateOrganizationWarningBanner({
          show: false,
          warningType: "",
        })
      );

      dispatch(clearOrganization());

      const dataPromises = [];
      dataPromises.push(
        API.Organization.OrganizationGet({
          organizationId,
        })
      );
      dataPromises.push(API.Location.LocationsGet({ organizationId }));
      dataPromises.push(API.Tank.TanksGet({ organizationId }));
      dataPromises.push(
        API.OnCall.OnCallGetOrganizationOncall({ organizationId })
      );
      dataPromises.push(API.User.UserGetOrganizationUsers({ organizationId }));
      const [organization, locations, tanks, oncall, users] = await Promise.all(
        dataPromises
      );

      dispatch(updateOrganization(organization));

      //Users Checks
      if (!users || !users.length) {
        //Create Banner Warning
        dispatch(
          updateOrganizationWarningBanner({
            show: true,
            warningType: "no-users",
          })
        );
        return;
      }

      //Location Check
      if (!locations || locations.length === 0) {
        //Create Banner Warning
        dispatch(
          updateOrganizationWarningBanner({
            show: true,
            warningType: "no-locations",
          })
        );
        return;
      }

      // Tanks Check
      if (!tanks || tanks.length === 0) {
        //Create Banner Warning
        dispatch(
          updateOrganizationWarningBanner({
            show: true,
            warningType: "no-tanks",
          })
        );
        return;
      }

      //On Call Check
      let noOnCall = false;
      oncall.forEach((item) => {
        if (!item.oncall?.currentOnCallUserId) {
          noOnCall = true;
        }
      });

      if (noOnCall) {
        dispatch(
          updateOrganizationWarningBanner({
            show: true,
            warningType: "no-oncall",
          })
        );
        return;
      }
    },
  };

  useEffect(() => {
    if (reloadOrganization) {
      const pathPieces = location.pathname.split("/").filter((p) => p);
      if (pathPieces.length >= 2) {
        const _orgId = pathPieces[0];
        dataLayer.GetOrganization({ organizationId: _orgId });
      }
      dispatch(updateReloadOrganization(false));
    }
  }, [reloadOrganization]);

  useEffect(() => {
    if (!isAdminArea && userProfile) {
      // Get OrganizationId from path (router params aren't in scope here)
      const pathPieces = location.pathname.split("/").filter((p) => p);
      if (pathPieces.length >= 2) {
        const _orgId = pathPieces[0];
        dataLayer.GetOrganization({ organizationId: _orgId });
      }
    }
  }, [isAdminArea, userProfile]);

  useEffect(() => {
    const pathPieces = location.pathname.split("/").filter((p) => p);
    const _isAdminArea = pathPieces[0] === "admin";
    dispatch(updateIsAdminArea(_isAdminArea));
  }, [location]);

  useEffect(() => {
    console.log(route);
    switch (route) {
      case "signOut":
        setUserProfile(null);
        navigate("/login");
        break;
      case "signIn":
        break;
      default:
        break;
    }
  }, [route]);

  useEffect(() => {
    const GetUserProfile = async () => {
      const userProfile = await API.User.UserGet();
      console.log(userProfile);
      dispatch(updateUserProfile(userProfile));
      dispatch(
        updateIsAdminUser(
          userProfile.role === "ADMIN" && userProfile.type === "EMPLOYEE"
        )
      );
      if (userProfile.role !== "ADMIN" && userProfile.type !== "EMPLOYEE") {
        dispatch(clearOrganization());
      }
      setUserProfile(userProfile);
    };
    if (authStatus === "authenticated") {
      console.log("get-user-profile");
      GetUserProfile();
    }
  }, [authStatus]);

  useEffect(() => {
    switch (authStatus) {
      case "authenticated":
        console.log("user-authenticated");
        break;
      case "configuring":
        console.log("loading-authentication-state");
        break;
      default:
        if (location?.pathname !== "/login") {
          navigate("/login", { replace: true });
        }
        console.log("user-not-authenticated");
        break;
    }
  }, [authStatus, location]);

  useEffect(() => {
    console.log(userProfile?.type);
    console.log(userProfile?.role);
    console.log(location.pathname);
    //Route user to landing page
    if (userProfile?.type === "EMPLOYEE") {
      if (location.pathname === "/" || location.pathname === "/login") {
        //TODO: If the admin has a customer selected allow them to go to the root, else take them to the admin orgs page
        navigate("admin/organizations");
      }
    } else if (userProfile?.type === "CUSTOMER") {
      if (location.pathname === "/" || location.pathname === "/login") {
        dataLayer.GetOrganization({
          organizationId: userProfile.organizationId,
        });
        navigate(`${userProfile.organizationId}/organization`);
      }
    }
  }, [userProfile, location]);

  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }, [pathname]);
    return null;
  };

  if (authStatus === "authenticated") {
    console.log("location.pathname", location.pathname);
    return (
      <Routes>
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="knowledge-base" element={<KnowledgeBase />} />
        {/* Default Route */}
        <Route
          path="*"
          element={
            <Layout>
              <ScrollToTop />
              <Routing />
            </Layout>
          }
        />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Login></Login>} />
        <Route path="/login" element={<Login></Login>} />
        {/* Terms of Service and Privacy Policy */}
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />

        {/* Error Routes */}
        <Route path="*" element={<Login></Login>} />
      </Routes>
    );
  }
}

export default App;
