import ModalComponent from "../Modal";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import colors from "../../theme/colors";
import AdminUserForm from "../Forms/adminUserForm";
import { updateShowMySettingsModal } from "../../redux-store/uxSlice";
import API from "../../api";
import { update as updateUserProfile } from "../../redux-store/userSlice";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";

const ModalEditHeaderUserName = styled.span`
  color: ${colors.gray};
  margin-left: 5px;
`;

const MySettingsModal = () => {
  const dispatch = useDispatch();
  const [savingUser, setSavingUser] = useState(false);
  const [savingUserError, setSavingUserError] = useState(false);
  //Get User from Redux
  const user = useSelector((state) => state.user);
  const ux = useSelector((state) => state.ux);

  const dataLayer = {
    GetUserProfile: async () => {
      const userProfile = await API.User.UserGet();
      dispatch(updateUserProfile(userProfile));
    },
  };

  useEffect(() => {
    if (ux.showMySettingsModal) {
      dataLayer.GetUserProfile();
    }
  }, [ux.showMySettingsModal]);

  const handlers = {
    saveEditUser: async (data) => {
      setSavingUserError("");
      setSavingUser(true);
      try {
        await API.User.UserUpdate({
          editUserData: { ...user, ...data },
        });

        //Reload User in redux
        await dataLayer.GetUserProfile();

        //Create Success Notification and Close Modal
        dispatch(updateShowMySettingsModal(false));

        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Settings updated successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingUserError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingUser(false);
    },
    closeEditUserModal: async () => {
      dispatch(updateShowMySettingsModal(false));
    },
  };

  return (
    <ModalComponent
      headerText={
        <>
          My Settings
          <ModalEditHeaderUserName>
            / {user.firstName} {user.lastName}
          </ModalEditHeaderUserName>
        </>
      }
      open={ux.showMySettingsModal}
    >
      <AdminUserForm
        user={user}
        handleSave={handlers.saveEditUser}
        handleCancel={handlers.closeEditUserModal}
        loading={savingUser}
        error={savingUserError}
        isEdit={true}
        handleDeactivateUser={handlers.confirmDeactivateUserModal}
        handleReactivateUser={handlers.confirmReactivateUserModal}
      />
    </ModalComponent>
  );
};

export default MySettingsModal;
