const dateDiffToString = (epoch1, epoch2) => {
  const diffMilliseconds = Math.abs(epoch1 - epoch2);
  const hours = parseInt(diffMilliseconds / (1000 * 60 * 60));
  const milliseconds_remainder = diffMilliseconds % (1000 * 60 * 60);
  const minutes = parseInt(milliseconds_remainder / (1000 * 60));
  return `${hours} hr ${minutes} min `;
};

const formatDateTimeStamp = (epoch) => {
  const dateTime = new Date(epoch);
  return dateTime.toLocaleString();
};

export { dateDiffToString, formatDateTimeStamp };
