import { TextField } from "@mui/material";

export const FormatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  // if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 4) {
    return `(${phoneNumber.slice(0, phoneNumberLength)}`;
  }

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

const PhoneNumberInput = ({
  onChange,
  value,
  label = "Phone Number",
  ...props
}) => {

  function handleOnChange(e) {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = FormatPhoneNumber(e.target.value);
    const unformattedPhoneNumber = formattedPhoneNumber.replace(/[^\d]/g, "");

    onChange({ formattedPhoneNumber, unformattedPhoneNumber });
  }

  return (
    <TextField
      label={label}
      pattern="[(]\d{1,3}[)]\s\d{3}[-]\d{4}"
      type="text"
      //   placeholder="(###) ###-####"
      onChange={(e) => handleOnChange(e)}
      value={FormatPhoneNumber(value)}
      {...props}
    />
  );
};

export default PhoneNumberInput;
