// Boiler Plate Imports
import { useEffect, useState } from "react";
import colors from "../../theme/colors";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import { useNavigate } from "react-router-dom";

// Conatiner Component Imports
import { Button, Card } from "@mui/material";
import Table from "../../components/Table";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";
import TableHeader from "../../components/Page/tableHeader";

// Page Component Imports
import AdminOrganizationForm from "../../components/Forms/adminOrganizationForm";
import { FormatPhoneNumber } from "../../components/PhoneNumberInput";
import MetricBoxes from "../../components/Page/metricBoxes";

const ActiveAlarmsTagNone = styled.div`
  background: ${colors.grayLight};
  color: ${colors.grayDark};
  border: 1px solid ${colors.gray};
  border-radius: 15px;
  text-align: center;
  padding: 2px;
  width: 60px;
`;

const ActiveAlarmsTagSome = styled.div`
  background: ${colors.redLightError};
  color: ${colors.redError};
  border: 1px solid ${colors.redError};
  border-radius: 15px;
  text-align: center;
  padding: 2px;
  width: 60px;
  font-weight: bold;
`;

const PaddingWrapper = styled.div`
  padding: 0 15px 10px;
  @media (max-width: 1080px) {
    padding: 0 0px 10px;
  }
`;

const AdminOrgsContainer = () => {
  const navigate = useNavigate();

  const userProfile = useSelector((state) => state.user);

  const [savingOrganization, setSavingOrganization] = useState(false);
  const [savingOrganizationError, setSavingOrganizationError] = useState(false);
  const [organizationTableRows, setOrganizationTableRows] = useState([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(true);
  const [organizationMetrics, setOrganizationMetrics] = useState([]);

  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);
  // const [openEditUserModal, setOpenEditUserModal] = useState(false);
  // const [currentUserEdit, setCurrentUserEdit] = useState({});
  const dispatch = useDispatch();

  const organizationTableColumns = [
    {
      title: "Name",
      propertyName: "name",
      sortProperty: "name",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${row.id}/organization/`;
      },
    },
    {
      title: "Active Alarms",
      propertyName: "activeAlarmsCount",
      sortProperty: "activeAlarmsCount",
      sortPropertyType: "number",
      sortDefault: "desc",
      render: (row) => {
        if (row.activeAlarmsCount === 0) {
          return <ActiveAlarmsTagNone>none</ActiveAlarmsTagNone>;
        } else {
          return (
            <ActiveAlarmsTagSome>{row.activeAlarmsCount}</ActiveAlarmsTagSome>
          );
        }
      },
    },
    {
      title: "Total Locations",
      propertyName: "locationsCount",
      sortProperty: "locationsCount",
      sortPropertyType: "number",
    },
    {
      title: "Total Tanks",
      propertyName: "tanksCount",
      sortProperty: "tanksCount",
      sortPropertyType: "number",
    },
    {
      title: "Total Users",
      propertyName: "usersCount",
      sortProperty: "usersCount",
      sortPropertyType: "number",
    },
    {
      title: "Date Created",
      propertyName: "dateCreatedString",
      sortProperty: "created",
      sortPropertyType: "number",
    },
  ];

  useEffect(() => {
    dataLayer.GetAllOrganizations();
    dispatch(
      setBreadCrumbs([
        {
          title: "Admin Organizations",
          link: "/admin/organizations",
        },
      ])
    );
    setOrganizationMetrics([
      {
        title: "ACTIVE ALARMS",
        value: "",
      },
      {
        title: "ORGANIZATIONS",
        value: "",
      },
      {
        title: "LOCATIONS",
        value: "",
      },
      {
        title: "TANKS",
        value: "",
      },
      {
        title: "USERS",
        value: "",
      },
    ]);
  }, []);

  const dataLayer = {
    GetAllOrganizations: async () => {
      const { organizations, metrics } =
        await API.Organization.OrganizationsGetAll();

      setOrganizationMetrics([
        {
          title: "ACTIVE ALARMS",
          value: metrics.activeAlarmsCount,
        },
        {
          title: "ORGANIZATIONS",
          value: metrics.organizationsCount,
        },
        {
          title: "LOCATIONS",
          value: metrics.locationsCount,
        },
        {
          title: "TANKS",
          value: metrics.tanksCount,
        },
        {
          title: "USERS",
          value: metrics.usersCount,
        },
      ]);

      const orgRows = organizations.map((org) => {
        return {
          ...org,
          status: (() => {
            if (org.deleted) {
              return "Deactivated";
            } else {
              return "Active";
            }
          })(),
          dateCreatedString: new Date(org.created).toLocaleString(),
          phoneString: FormatPhoneNumber(org.phone),
        };
      });
      orgRows.sort((a, b) => {
        return b.name - a.name;
      });
      setOrganizationTableRows(orgRows);
      setLoadingOrganizations(false);
    },
  };

  const handlers = {
    addOrganizationClick: () => {
      setOpenOrganizationModal(true);
    },
    closeOrganizationModal: () => {
      setOpenOrganizationModal(false);
    },
    saveOrganization: async (data) => {
      setSavingOrganizationError("");
      setSavingOrganization(true);
      try {
        await API.Organization.OrganizationCreate({
          createOrganizationData: { ...data },
        });
        //Reload Users
        dataLayer.GetAllOrganizations();
        //Create Success Notification
        setOpenOrganizationModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Organization created successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingOrganizationError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingOrganization(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <MetricBoxes title={"Boreas Overview"} metrics={organizationMetrics} />
      <PaddingWrapper>
        <Card>
          <TableHeader>
            <h2>Organizations</h2>
            <Button
              onClick={handlers.addOrganizationClick}
              variant="contained"
              color="primary"
              size="large"
            >
              ADD ORGANIZATION
            </Button>
          </TableHeader>
          <Table
            columns={organizationTableColumns}
            rows={organizationTableRows}
            loading={loadingOrganizations}
            placeholder="There are no organizations created yet"
          />
        </Card>
      </PaddingWrapper>
      <ModalComponent
        headerText="Add New Organization"
        open={openOrganizationModal}
        handleClose={handlers.closeUserModal}
      >
        <AdminOrganizationForm
          handleSave={handlers.saveOrganization}
          handleCancel={handlers.closeOrganizationModal}
          loading={savingOrganization}
          error={savingOrganizationError}
        />
      </ModalComponent>
    </>
  );
};

export default AdminOrgsContainer;
