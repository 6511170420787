// Boiler Plate Imports
import { useEffect, useState, useMemo, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import colors from "../../theme/colors";
import { Card, Button } from "@mui/material";
import styled from "styled-components";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import { useNavigate, useParams } from "react-router-dom";

// Conatiner Component Imports
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import DetailsWidgetComponent from "../../components/Widgets/detailsWidget";
import ActivityWidget from "../../components/Widgets/activityWidget";
import MetricBoxesComponent from "../../components/Page/metricBoxes";
import HighStockComponent from "../../components/HighCharts/highstock";
import AlarmHistoryWidgetComponent from "../../components/Widgets/alarmHistoryWidget";
import TankGreenImage from "../../theme/images/Tank_Green.svg";
import TankRedImage from "../../theme/images/Tank_Red.svg";
import NotesWidget from "../../components/Widgets/notesWidget";
import TankFillCalendar from "../../components/Widgets/tankFillCalendar";

import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";

const HighStockComponentMemo = memo(HighStockComponent);

const PrintWrapper = styled.div`
  display: none;
  @media print {
    display: block !important;
  }
`;

const PrintSignatureContainer = styled(PrintWrapper)`
  margin: 100px 0 20px 20px;
`;

const SignatureContainer = styled.div``;
const SignatureLine = styled.div`
  width: 500px;
  margin-top: 50px;
  border-top: 1px solid black;
`;

const NoPrintWrapper = styled.div`
  @media print {
    display: none !important;
  }
`;

const PrintHeader = styled.div`
  font-weight: bold;
  font-size: 30px;
  display: none;
  padding: 20px;
  @media print {
    display: block;
  }
`;

const PrintLocation = styled.div`
  font-weight: bold;
  font-size: 20px;
  display: none;
  padding: 0px 20px 20px;
  @media print {
    display: block;
  }
`;

const PrintOrg = styled.div`
  font-weight: bold;
  font-size: 20px;
  display: none;
  padding: 0px 20px;
  @media print {
    display: block;
  }
`;

const NotesContainer = styled.div`
  margin-top: 20px;
  display: none;
  @media print {
    display: block;
  }
`;

const NotesLabel = styled.div`
  font-size: 16px;
`;

const NotesBody = styled.div`
  width: 100%;
  height: 500px;
  border: 1px solid black;
`;

const TankWidget = styled(Card)`
  padding: 20px;
  height: 300px;
  position: relative;
  z-index: 2;
  width: 100%;
  ${"" /* page-break-before: always; */}
`;

const TankHeader = styled.div`
  font-size: 20px;
  padding: 0 0 0 15px;
  color: ${colors.blue};
  @media (max-width: 1080px) {
    padding: 0 0px 0px;
  }
`;

const TankDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;
  font-size: 12px;
  color: ${colors.grayDarkText};
  hr {
    padding: 0;
    margin: 0;
    border: 1px dashed ${colors.grayDark};
  }
`;

const TankImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
  }
`;

const TankWidgetHeader = styled.div`
  font-size: 14px;
  text-align: center;
`;

const WeightDetailsWrapper = styled.div`
  margin-top: 60px;
`;

const CurrentTemperature = styled.div`
  margin-top: 30px;
`;

const CurrentWeightContainer = styled.div`
  text-align: center;
`;

const Weight = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const WeightLabel = styled.div``;

const DetailWeight = styled.div`
  font-weight: bold;
`;

const ColumnWrapper = styled.div`
  display: flex;
  @media (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const LeftColumn = styled.div`
  width: 100%;
  min-width: 0px;
`;
const RightColumn = styled.div`
  width: 100%;
  max-width: 350px;
  min-width: 0px;
  @media (max-width: 1080px) {
    max-width: 100%;
  }
`;
const PaddingWrapper = styled.div`
  padding: 0 15px 10px;
  @media (max-width: 1080px) {
    padding: 0 0px 10px;
  }
`;
const ActivityWidgetWrapper = styled.div``;

const TestButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media print {
    display: none !important;
  }
`;

const DataLastReceivedContainer = styled.div`
  color: ${colors.grayDark};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 10px 0px;
  font-size: 12px;
  margin-top: -10px;
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const OrganizationTankContainer = () => {
  const navigate = useNavigate();
  const { organizationId, tankId } = useParams();

  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const ux = useSelector((state) => state.ux);

  const [tank, setTank] = useState({});
  const [tankFillEvents, setTankFillEvents] = useState([]);
  const [tankLoaded, setTankLoaded] = useState(false);

  const [metrics, setMetrics] = useState();
  const [consumptionRateMetric, setConsumptionRateMetric] = useState();
  const [chartData, setChartData] = useState();
  const [chartAnnotations, setChartAnnotations] = useState([]);
  const [chartDataLoaded, setChartDataLoaded] = useState(false);

  const [savingCreateTestAlarm, setSavingCreateTestAlarm] = useState(false);

  const dispatch = useDispatch();

  //Polling Setup
  const [dataInterval, setDataInterval] = useState();
  let refreshIntervalSeconds = 60;
  const [pollingStarted, setPollingStarted] = useState(false);
  const [nextRefreshInSeconds, setNextRefreshInSeconds] = useState(
    refreshIntervalSeconds
  );

  useEffect(() => {
    return () => {
      clearInterval(dataInterval);
    };
  }, []);

  useEffect(() => {
    if (organizationId) {
      if (!pollingStarted) {
        FetchData();
        PollData();
        setPollingStarted(true);
      }
    }
  }, [organizationId]);

  useEffect(() => {
    if (nextRefreshInSeconds === 0) {
      FetchData();
      setNextRefreshInSeconds(refreshIntervalSeconds);
    }
  }, [nextRefreshInSeconds]);

  const PollData = async () => {
    clearInterval(dataInterval);
    const d = setInterval(() => {
      setNextRefreshInSeconds((s) => s - 1);
    }, 1000);
    setDataInterval(d);
  };

  const FetchData = async () => {
    dataLayer.GetTank();
    dataLayer.GetTankWeightTrend();
    dataLayer.GetDashboardMetrics();
    dataLayer.GetTankFillEvents();
  };

  useEffect(() => {
    //create annotations for the chart
    let _annotations = [];
    tankFillEvents.forEach((e) => {
      _annotations.push({
        id: e.id,
        x: e.exactFillTimeStamp,
        y: e.exactFillWeight,
        text: `Fill: ${Math.round(e.eventFillAmount * 100) / 100} kg`,
      });
    });
    setChartAnnotations(_annotations);
  }, [tankFillEvents]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "Tanks",
          link: "/tanks",
        },
        {
          title: "...",
          link: "/tanks/",
        },
      ])
    );
  }, [organization]);

  useEffect(() => {
    if (tankLoaded && tank && organization.name) {
      dispatch(
        setBreadCrumbs([
          {
            title: organization.name,
            link: "/organization",
          },
          {
            title: "Tanks",
            link: "/tanks",
          },
          {
            title: tank.name,
            link: `/tanks/${tank.id}`,
          },
        ])
      );
    }
  }, [tank, tankLoaded, organization]);

  const dataLayer = {
    GetTank: async () => {
      const _tank = await API.Tank.TankGet({ tankId });
      setTank(_tank);
      setTankLoaded(true);
    },
    GetTankFillEvents: async () => {
      const _tank = await API.Tank.TankGetFillEvents({ tankId });
      setTankFillEvents(_tank);
    },
    GetTankWeightTrend: async () => {
      const _tankWeightTrend = await API.Charts.TankWeightTrendGet({ tankId });
      const lineColors = [colors.purple, colors.blueMedLight];
      _tankWeightTrend.seriesData.forEach((s, i) => {
        s.yAxis = i;
        s.color = lineColors[i];
      });

      if (_tankWeightTrend?.seriesData[1]?.data?.length) {
        const [_consumptionRate] =
          _tankWeightTrend.seriesData[1].data.slice(-1);
        setConsumptionRateMetric(_consumptionRate[1]);
      }

      setChartData(_tankWeightTrend);
      setChartDataLoaded(true);
    },
    GetDashboardMetrics: async () => {
      try {
        const _metrics = await API.Metrics.MetricsDashboardGet({
          resource: "tank",
          resourceId: tankId,
        });
        setMetrics(_metrics);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    PostCreateTestAlarm: async () => {
      setSavingCreateTestAlarm(true);
      await API.Alarm.AlarmPostCreateTestAlarm({
        tankId,
      });
      setSavingCreateTestAlarm(false);
      FetchData();
      const id = new Date().getTime();
      dispatch(
        addNotification({
          id,
          severity: "success",
          title: "Success",
          message: "Test Alarm Created",
        })
      );
      setTimeout(() => {
        dispatch(removeNotification(id));
      }, 3000);
    },
  };

  const handlers = {
    tankEditClick: async () => {
      navigate("edit");
    },
    createTestAlarmClick: async () => {
      dataLayer.PostCreateTestAlarm();
    },
    printReportClick: useCallback(() => {
      window.print();
    }, []),
  };

  const noDataPlaceholderValues = useMemo(() => {
    return {
      header: "NO WEIGHT DATA",
      body: "To get started, connect tank to a scale",
      buttonText: "Connect Scale",
      buttonPath: "edit",
    };
  }, []);

  const tankImage =
    metrics?.currentWeight < tank?.alarmLowLevelWeightKg
      ? TankRedImage
      : TankGreenImage;

  return (
    <>
      <PrintHeader>Boreas Monitoring - Tank Report</PrintHeader>
      <PrintOrg>Date: {new Date().toLocaleDateString()}</PrintOrg>
      <PrintOrg>Organization: {organization.name}</PrintOrg>
      <PrintLocation>Location: {tank?.location?.name}</PrintLocation>
      <PrintWrapper>
        <DetailsWidgetComponent
          loading={!tankLoaded}
          showEdit={true}
          onEdit={handlers.tankEditClick}
          title={tank.name}
          subtitle="Tank Details"
          details={[
            `Scale Id: ${tank.scaleId}`,
            `Tank Contents: ${tank.contentsDisplayName}`,
            `Tank Type: ${tank.typeDisplayName}`,
            `Tank Make: ${tank.makeDisplayName}`,
            `Tank Model: ${tank.modelDisplayName}`,
          ]}
        />
      </PrintWrapper>
      <BreadCrumbs />

      <DataLastReceivedContainer>
        <TankHeader>{tank?.name}</TankHeader>
        <div>
          Data Last Received:{" "}
          {metrics?.timestamp
            ? new Date(metrics?.timestamp * 1000).toLocaleString()
            : "N/A"}
        </div>
      </DataLastReceivedContainer>
      <MetricBoxesComponent
        showHeader={false}
        metrics={[
          {
            title: "Active Alarms",
            value: tank?.activeAlarmsCount,
            to: "/alarms",
            redText: tank?.activeAlarmsCount > 0,
          },
          {
            title: "Location",
            value: tank?.location?.name,
            to: `/locations/${tank?.location?.id}`,
            fontSize: "16px",
          },
          {
            title: "Consumption Rate Last 24hr",
            value: consumptionRateMetric
              ? `${consumptionRateMetric} kg`
              : "--",
            fontSize: "20px",
          },
          {
            title: "Signal Strength",
            value: metrics?.signalStrength
              ? `${Math.round(metrics?.signalStrength)}%`
              : "--",
            // to: "/oncall",
          },
          {
            title: "Battery Level",
            value: metrics?.batteryLevel
              ? `${Math.round(metrics?.batteryLevel)}%`
              : "--",
            // to: "/oncall",
          },
        ]}
      />
      <ColumnWrapper>
        <LeftColumn>
          <PaddingWrapper>
            <HighStockComponentMemo
              title="Tank Health Trends"
              metricLabel="kg"
              keyTitle="Tanks Key"
              seriesData={chartData?.seriesData}
              loaded={chartDataLoaded}
              showPrintReportButton={true}
              printReportClickHandler={handlers.printReportClick}
              noDataPlaceholderValues={noDataPlaceholderValues}
              // annotations={chartAnnotations}
            />
          </PaddingWrapper>
          {/* <PaddingWrapper>
            <HighStockComponentMemo
              title="LN Consumption Rate"
              metricLabel="kg"
              keyTitle="Tanks Key"
              seriesData={chartData?.seriesData}
              loaded={chartDataLoaded}
              showPrintReportButton={false}
              noDataPlaceholderValues={noDataPlaceholderValues}
              // annotations={chartAnnotations}
            />
          </PaddingWrapper> */}
          <PaddingWrapper>
            <AlarmHistoryWidgetComponent tankId={tankId} />
          </PaddingWrapper>
          <PaddingWrapper>
            <NotesWidget></NotesWidget>
          </PaddingWrapper>
        </LeftColumn>
        <RightColumn>
          <PaddingWrapper>
            <TankWidget>
              <TankDetailsContainer>
                <TankWidgetHeader>Tank Health Summary</TankWidgetHeader>
                <WeightDetailsWrapper>
                  <div>
                    <DetailWeight>{tank?.fullWeightKG}kg</DetailWeight>
                    <hr></hr>
                    <div>Full Weight</div>
                  </div>
                  <CurrentWeightContainer>
                    <Weight>{metrics?.currentWeight?.toFixed(2)}kg</Weight>
                    <WeightLabel>Current Weight</WeightLabel>
                  </CurrentWeightContainer>
                  <div>
                    <DetailWeight>{tank?.alarmLowLevelWeightKg}kg</DetailWeight>
                    <hr></hr>
                    <div>Low Weight Alarm</div>
                  </div>
                </WeightDetailsWrapper>
                <CurrentTemperature>
                  Current Temperature:{" "}
                  {metrics?.currentTemperature
                    ? metrics?.currentTemperature?.toFixed(2)
                    : ""}{" "}
                  &#8451;
                </CurrentTemperature>
              </TankDetailsContainer>
              <TankImageContainer>
                <img alt="tank" src={tankImage} />
              </TankImageContainer>
            </TankWidget>
          </PaddingWrapper>

          <TankFillCalendar tankFillEvents={tankFillEvents} />

          <TestButtonContainer>
            <Button
              disabled={savingCreateTestAlarm}
              onClick={handlers.createTestAlarmClick}
              variant="outlined"
            >
              {savingCreateTestAlarm ? (
                <span>Creating Test Alarm&#8230;</span>
              ) : (
                "Create Test Alarm"
              )}
            </Button>
          </TestButtonContainer>
          <NoPrintWrapper>
            <DetailsWidgetComponent
              loading={!tankLoaded}
              showEdit={userProfile?.role && userProfile.role !== "TECH"}
              onEdit={handlers.tankEditClick}
              title={tank.name}
              subtitle="Tank Details"
              details={[
                `Scale Id: ${tank.scaleId}`,
                `Tank Contents: ${tank.contentsDisplayName}`,
                `Tank Type: ${tank.typeDisplayName}`,
                `Tank Make: ${tank.makeDisplayName}`,
                `Tank Model: ${tank.modelDisplayName}`,
              ]}
            />
          </NoPrintWrapper>
          <NoPrintWrapper>
            <ActivityWidgetWrapper>
              <ActivityWidget
                header="Tank Activity Log"
                resource="tank"
                organizationId={organizationId}
                resourceId={tankId}
                ready={tankLoaded}
              />
            </ActivityWidgetWrapper>
          </NoPrintWrapper>
        </RightColumn>
      </ColumnWrapper>

      <PrintSignatureContainer>
        <SignatureContainer>
          <SignatureLine>Print Name, Position</SignatureLine>
          <SignatureLine>Sign Name</SignatureLine>
          <SignatureLine>Date</SignatureLine>
        </SignatureContainer>
        <SignatureContainer>
          <SignatureLine>Print Name, Position</SignatureLine>
          <SignatureLine>Sign Name</SignatureLine>
          <SignatureLine>Date</SignatureLine>
        </SignatureContainer>
      </PrintSignatureContainer>

      <NotesContainer>
        <NotesLabel>Notes</NotesLabel>
        <NotesBody></NotesBody>
      </NotesContainer>
    </>
  );
};

export default OrganizationTankContainer;
