const roles = [
  {
    title: "Technician",
    value: "TECH",
  },
  {
    title: "Supervisor",
    value: "SUPERVISOR",
  },
  {
    title: "Director",
    value: "DIRECTOR",
  },
];

export default roles;
