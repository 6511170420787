import { Dialog, DialogContent } from "@mui/material";
import styled from "styled-components";

const Divider = styled.hr`
  border: 0.5px solid #e2dfdf;
`;

const ModalHeader = styled.h3`
  font-size: 20px;
  font-weight: 500;
  padding: 0;
  margin: 0;
`;

const ModalComponent = ({
  open = true,
  handleClose = () => {},
  headerText = "Modal",
  children,
}) => {
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={handleClose}>
      <DialogContent>
        <ModalHeader>{headerText}</ModalHeader>
        <Divider />
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default ModalComponent;
