import styled from "styled-components";
import colors from "../../theme/colors";
import { Link } from "react-router-dom";

export const WarningBanner = styled.div`
  background-color: ${colors.redLightError};
  color: ${colors.redError};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  p {
    padding: 0;
    margin: 0;
  }
  @media print {
    display: none !important;
  }
`;
export const WarningBannerLink = styled(Link)`
  color: ${colors.blue};
  white-space: "nowrap";
  text-decoration: underline;
  padding: 0 5px;
  cursor: pointer;
`;
