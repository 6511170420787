import Logo from "../../theme/images/Boreas_Logo_Color_Horizontal.png";
import { useAuthenticator } from "@aws-amplify/ui-react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import SupportBar from "./supportbar";
import { ArrowDropDown, Person, Logout } from "@mui/icons-material";
import colors from "../../theme/colors";
import API from "../../api";

import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  Divider,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Avatar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { clear as clearUserRedux } from "../../redux-store/userSlice";
import {
  updateShowMySettingsModal,
  updateShowSupportModal,
} from "../../redux-store/uxSlice";
import { updateOrganization } from "../../redux-store/organizationSlice";

const AppBarCustom = styled(AppBar)`
  @media print {
    display: none !important;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  border-right: 1px solid #0000001f;
  margin-right: 10px;
  padding-right: 10px;
  img {
    height: 42px;
    vertical-align: middle;
  }
  @media (max-width: 900px) {
    border-right: 0;
    img {
      height: 35px;
    }
  }
`;

const DesktopNavLink = styled(Link)`
  color: ${colors.grayDark};
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 0 5px;
  padding: 0 10px;
  font-size: 12px;
  letter-spacing: 0.03rem;
  position: relative;
  height: 62px;
  user-select: none;
  white-space: nowrap;
  &:hover {
    color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.05);
  }
  &.active {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }
  &.active::after {
    content: "";
    cursor: default;
    height: 4px;
    width: 100%;
    background-color: ${(props) =>
      props.$isAdminArea ? colors.orange : colors.blue};
    ${"" /* background-color: ${colors.blue}; */}
    position: absolute;
    bottom: -3px;
    left: 0;
    border-radius: 2px;
    @media (max-width: 575px) {
      bottom: 0px;
    }
  }
  &::before {
    content: "";
    display: ${(props) => (props.$hasActiveAlarm ? "block" : "none")};
    position: absolute;
    margin-top: -17px;
    right: 1px;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background: ${colors.redError};
  }
`;

const SettingsMenuContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const SettingMenuItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const UserLabelContainer = styled.div`
  display: flex;
  white-space: nowrap;
  font-size: 14px;
  align-items: center;
  justify-content: flex-end;
`;

const OrgLabelContainer = styled.div`
  font-size: 11px;
  white-space: nowrap;
  color: gray;
  justify-content: flex-end;
  padding-right: 22px;
  text-align: right;
`;

const NavBar = () => {
  const dispatch = useDispatch();
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const user = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const { isAdminArea } = useSelector((state) => state.ux);
  const [organizationName, setOrganizationName] = useState();
  const [isAdminUser, setIsAdminUser] = useState(false);
  const location = useLocation();
  const [navLinks, setNavLinks] = useState([]);

  const [pathPieces, setPathPieces] = useState(
    location.pathname.split("/").filter((p) => p)
  );

  //Polling Setup Logic
  const [dataInterval, setDataInterval] = useState();
  let refreshIntervalSeconds = 15;
  const [pollingStarted, setPollingStarted] = useState(false);
  const [nextRefreshInSeconds, setNextRefreshInSeconds] = useState(
    refreshIntervalSeconds
  );

  useEffect(() => {
    if (organization?.id && !isAdminArea && !pollingStarted) {
      FetchData();
      PollData();
      setPollingStarted(true);
    }

    if (isAdminArea) {
      console.log(dataInterval);
      clearInterval(dataInterval);
      setPollingStarted(false);
    }
  }, [isAdminArea, organization]);

  useEffect(() => {
    return () => {
      clearInterval(dataInterval);
      setPollingStarted(false);
    };
  }, []);

  useEffect(() => {
    if (nextRefreshInSeconds === 0) {
      FetchData();
      setNextRefreshInSeconds(refreshIntervalSeconds);
    }
  }, [nextRefreshInSeconds]);

  const PollData = async () => {
    clearInterval(dataInterval);
    const d = setInterval(() => {
      setNextRefreshInSeconds((s) => s - 1);
    }, 1000);
    setDataInterval(d);
  };

  const FetchData = async () => {
    const pathPieces = location.pathname.split("/").filter((p) => p);
    if (pathPieces.length >= 2) {
      const organizationId = pathPieces[0];
      dataLayer.GetOrganization({ organizationId });
    }
  };

  const dataLayer = {
    GetOrganization: async ({ organizationId }) => {
      const _org = await API.Organization.OrganizationGet({
        organizationId,
      });
      dispatch(updateOrganization(_org));
    },
  };
  //End Polling Logic

  useEffect(() => {
    setPathPieces(location.pathname.split("/").filter((p) => p));
  }, [location]);

  useEffect(() => {
    if (user.type === "EMPLOYEE" && user.role === "ADMIN") {
      setOrganizationName(`BOREAS ${user.role}`);
      setIsAdminUser(true);
    } else if (user.type === "CUSTOMER") {
      setIsAdminUser(false);
      setOrganizationName(user.organization.name);
    }
  }, [user]);

  useEffect(() => {
    if (isAdminArea) {
      setNavLinks([
        {
          to: "admin/organizations",
          text: "ORGANIZATIONS",
          active:
            pathPieces[0] === "admin" && pathPieces[1] === "organizations",
        },
        {
          to: "admin/devices",
          text: "DEVICES",
          active:
            pathPieces[0] === "admin" && pathPieces[1] === "devices",
        },
        {
          to: "admin/users",
          text: "ADMIN USERS",
          active: pathPieces[0] === "admin" && pathPieces[1] === "users",
        },
      ]);
    } else {
      const pathPieces = location.pathname.split("/").filter((p) => p);
      const organizationId = pathPieces[0];
      setNavLinks([
        {
          to: `/${organizationId}/organization`,
          text: "MY ORGANIZATION",
          active: pathPieces[1] === "organization",
        },
        {
          to: `/${organizationId}/locations`,
          text: "LOCATIONS",
          active: pathPieces[1] === "locations",
        },
        {
          to: `/${organizationId}/tanks`,
          text: "TANKS",
          active: pathPieces[1] === "tanks",
        },
        {
          to: `/${organizationId}/alarms`,
          text: "ALARMS",
          active: pathPieces[1] === "alarms",
          hasActiveAlarm: organization.activeAlarmsCount > 0,
        },
        {
          to: `/${organizationId}/oncall`,
          text: "ON CALL",
          active: pathPieces[1] === "oncall",
        },
        {
          to: `/${organizationId}/users`,
          text: "USERS",
          active: pathPieces[1] === "users",
        },
        {
          to: `/${organizationId}/activity`,
          text: "ACTIVITY",
          active: pathPieces[1] === "activity",
        },
      ]);
    }
  }, [organization, isAdminArea, pathPieces]);

  const settings = [
    {
      to: "user/settings",
      text: "My Settings",
      onClick: () => {
        handleCloseUserMenu();
        dispatch(updateShowMySettingsModal(true));
      },
      icon: <Person sx={{ color: colors.grayDark }} />,
    },
  ];

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleSupportClick = () => {
    handleCloseNavMenu();
    dispatch(updateShowSupportModal(true));
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignout = () => {
    clearUserRedux();
    signOut();
  };

  return (
    <AppBarCustom
      sx={{ borderBottom: "1px solid #0000001f" }}
      elevation={0}
      color="secondary"
      position="fixed"
    >
      <SupportBar isAdminArea={isAdminArea} isAdminUser={isAdminUser} />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Desktop Logo */}
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <LogoContainer>
              <Link to={"/"}>
                <img src={Logo} alt="Boreas Logo" />
              </Link>
            </LogoContainer>
          </Box>
          {/* Mobile Drawer Menu  */}
          {/* <Drawer
            anchor={"left"}
            open={false}
            onClose={() => {}}
            onOpen={() => {}}
          >
            Menu Links
          </Drawer> */}
          {/* Mobile Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              transitionDuration={0}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {navLinks.map((page) => (
                <MenuItem
                  component={Link}
                  to={page.to}
                  key={page.to}
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign="center">{page.text}</Typography>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem
                component={Link}
                target="_blank"
                to="/knowledge-base"
                key={"kb-link"}
                onClick={handleCloseNavMenu}
              >
                <Typography textAlign="center">Knowledge Base</Typography>
              </MenuItem>
              <MenuItem
                component="a"
                target="_blank"
                key={"support"}
                onClick={handleSupportClick}
              >
                <Typography textAlign="center">Support</Typography>
              </MenuItem>
            </Menu>
          </Box>
          {/* Mobile Logo */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <LogoContainer>
              <Link to={"/"}>
                <img src={Logo} alt="Boreas Logo" />
              </Link>
            </LogoContainer>
          </Box>
          {/* Desktop Menu */}
          <Box sx={{ flexGrow: 2, display: { xs: "none", md: "flex" } }}>
            {navLinks.map((page) => (
              <DesktopNavLink
                to={page.to}
                key={page.to}
                onClick={handleCloseNavMenu}
                className={page.active && "active"}
                $isAdminArea={isAdminArea}
                $hasActiveAlarm={page.hasActiveAlarm}
              >
                {page.text}
              </DesktopNavLink>
            ))}
          </Box>
          {/* Desktop/Mobile Profile Settings */}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Settings Menu">
              <SettingsMenuContainer onClick={handleOpenUserMenu}>
                <IconButton sx={{ p: 0 }}>
                  <Avatar sx={{ height: 35, width: 35 }} />
                </IconButton>
                <Box
                  sx={{
                    paddingLeft: "8px",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <UserLabelContainer>
                    {user.firstName} {user.lastName} <ArrowDropDown />
                  </UserLabelContainer>
                  <OrgLabelContainer>{organizationName}</OrgLabelContainer>
                </Box>
              </SettingsMenuContainer>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              transitionDuration={0}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  // component={Link}
                  to={setting.to}
                  key={setting.to}
                  onClick={setting.onClick}
                >
                  <SettingMenuItemContent>
                    {setting.icon}
                    <Typography textAlign="center">{setting.text}</Typography>
                  </SettingMenuItemContent>
                </MenuItem>
              ))}
              <MenuItem onClick={handleSignout}>
                <SettingMenuItemContent>
                  <Logout sx={{ color: colors.grayDark }} />
                  <Typography textAlign="center">Sign Out</Typography>
                </SettingMenuItemContent>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBarCustom>
  );
};

export default NavBar;
