import styled from "styled-components";
import colors from "../../theme/colors";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ChartPlaceholderContainer = styled.div`
  border-top: 1px solid ${colors.grayLight};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 60px;
  font-size: 18px;
  color: ${colors.black};
`;
const ChartPlaceholderHeader = styled.div``;
const ChartPlaceholderBody = styled.div`
  color: ${colors.blue};
`;

const NoChartDataPlaceholder = ({ header, body, buttonText, buttonPath }) => {
  return (
    <ChartPlaceholderContainer>
      <ChartPlaceholderHeader>{header}</ChartPlaceholderHeader>
      <ChartPlaceholderBody>{body}</ChartPlaceholderBody>
      <Button component={Link} variant="contained" to={buttonPath}>
        {buttonText}
      </Button>
    </ChartPlaceholderContainer>
  );
};

const TablePlaceholderContainer = styled.div`
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 60px;
  font-size: 18px;
  color: ${colors.black};
`;
const TablePlaceholderHeader = styled.div``;
const TablePlaceholderBody = styled.div`
  color: ${colors.blue};
`;

const NoTableDataPlaceholder = ({
  header,
  body,
  buttonText,
  buttonPath,
  openInNewWindow = false,
  buttonOnClick,
}) => {
  return (
    <TablePlaceholderContainer>
      <TablePlaceholderHeader>{header}</TablePlaceholderHeader>
      <TablePlaceholderBody>{body}</TablePlaceholderBody>
      {buttonOnClick ? (
        <Button component={Link} variant="contained" onClick={buttonOnClick}>
          {buttonText}
        </Button>
      ) : (
        <Button
          component={Link}
          variant="contained"
          to={buttonPath}
          target={`${openInNewWindow ? "_blank" : "_self"}`}
        >
          {buttonText}
        </Button>
      )}
    </TablePlaceholderContainer>
  );
};

export { NoChartDataPlaceholder, NoTableDataPlaceholder };
