// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";

// Conatiner Component Imports
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";
import DetailsWidgetComponent from "../../components/Widgets/detailsWidget";
import { FormatAddress } from "../../components/Forms/shared/address";
import { FormatPhoneNumber } from "../../components/PhoneNumberInput";
import { updateOrganization } from "../../redux-store/organizationSlice";
import AdminOrganizationForm from "../../components/Forms/adminOrganizationForm";
import ActivityWidget from "../../components/Widgets/activityWidget";
import styled from "styled-components";
import MetricBoxesComponent from "../../components/Page/metricBoxes";
import TrendLineComponent from "../../components/HighCharts/trendline";
import TanksStatusTableWidget from "../../components/Widgets/tanksStatusTableWidget";
import TankHealthSummaryWidget from "../../components/Widgets/tankHealthSummaryWidget";
import { NoChartDataPlaceholder } from "../../components/NoDataPlaceholder";
import { useParams } from "react-router-dom";

const ColumnWrapper = styled.div`
  display: flex;
  @media (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const LeftColumn = styled.div`
  width: 100%;
  min-width: 0px;
`;
const RightColumn = styled.div`
  width: 100%;
  max-width: 500px;
  min-width: 0px;
  @media (max-width: 1080px) {
    max-width: 100%;
  }
`;
const PaddingWrapper = styled.div`
  padding: 0 15px 10px;
  @media (max-width: 1080px) {
    padding: 0 0px 10px;
  }
`;

const ActivityWidgetWrapper = styled.div``;

const OrganizationDashboardContainer = () => {
  const { organizationId } = useParams();

  const userProfile = useSelector((state) => state.user);
  const ux = useSelector((state) => state.ux);
  const organization = useSelector((state) => state.organization);
  const [organizationLoaded, setOrganizationLoaded] = useState(false);

  const [openEditOrganizationModal, setOpenEditOrganizationModal] =
    useState(false);
  const [savingOrganization, setSavingOrganization] = useState(false);
  const [savingOrganizationError, setSavingOrganizationError] = useState();

  const [metrics, setMetrics] = useState();
  const [alarmTrendsData, setAlarmTrendsData] = useState();
  const [alarmTrendsDataLoaded, setAlarmTrendsDataLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId) {
      dataLayer.GetDashboardMetrics();
      dataLayer.GetAlarmTrendsChartData();
    }
  }, [organizationId]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
      ])
    );

    setOrganizationLoaded(!!organization?.id);
  }, [organization]);

  const dataLayer = {
    UpdateOrganization: async (updateOrganizationData) => {
      try {
        const updateOrg = await API.Organization.OrganizationUpdate({
          updateOrganizationData,
        });
        dispatch(updateOrganization(updateOrg));
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    GetDashboardMetrics: async () => {
      try {
        const _metrics = await API.Metrics.MetricsDashboardGet({
          resource: "organization",
          resourceId: organizationId,
        });
        setMetrics(_metrics);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    GetAlarmTrendsChartData: async () => {
      try {
        const _chartData = await API.Charts.AlarmTrendsByLocationGet({
          organizationId: organizationId,
        });
        setAlarmTrendsData(_chartData);
        setAlarmTrendsDataLoaded(true);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
  };

  const handlers = {
    openEditOrganizationModal: async () => {
      setOpenEditOrganizationModal(true);
    },
    closeEditOrganizationModal: async () => {
      setOpenEditOrganizationModal(false);
    },
    saveEditOrganization: async (data) => {
      setSavingOrganizationError("");
      setSavingOrganization(true);
      try {
        await dataLayer.UpdateOrganization({
          ...data,
          organizationId,
        });

        //Create Success Notification
        handlers.closeEditOrganizationModal();
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Organization updated successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingOrganizationError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingOrganization(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <ColumnWrapper>
        <LeftColumn>
          <MetricBoxesComponent
            showHeader={false}
            metrics={[
              {
                title: "Active Alarms",
                value: metrics?.activeAlarmsCount,
                to: "/alarms",
                redText: metrics?.activeAlarmsCount > 0,
              },
              {
                title: "Locations",
                value: metrics?.locationsCount,
                to: "/locations",
              },
              { title: "Tanks", value: metrics?.tanksCount, to: "/tanks" },
              { title: "Users", value: metrics?.usersCount, to: "/users" },
            ]}
          />
          <PaddingWrapper>
            <TrendLineComponent
              title="Alarm Trends by Location"
              range="Last 7 Days"
              loaded={alarmTrendsDataLoaded}
              seriesData={alarmTrendsData?.seriesData}
              metricLabel="alarms"
              keyTitle="Locations Key"
              convertLabelToLocalTime={false}
              noDataPlaceholder={
                <NoChartDataPlaceholder
                  header={"THERE ARE NO LOCATIONS IN THIS ORGANIZATION"}
                  body={"To get started, add a location"}
                  buttonText={"Add Location"}
                  buttonPath={`/${organizationId}/locations`}
                />
              }
            />
          </PaddingWrapper>
          <PaddingWrapper>
            <TanksStatusTableWidget />
          </PaddingWrapper>
        </LeftColumn>
        <RightColumn>
          <TankHealthSummaryWidget
            title="Organization Tank Health Summary"
            total={metrics?.tanksCount}
            unhealthy={metrics?.tanksInAlarmStateCount}
            ready={!!metrics}
          />
          <DetailsWidgetComponent
            loading={!organizationLoaded}
            showEdit={ux.isAdminUser}
            onEdit={handlers.openEditOrganizationModal}
            title={organization.name}
            subtitle="Organization Details"
            details={[
              FormatAddress({ ...organization }),
              FormatPhoneNumber(organization.phone),
            ]}
          />
          <ActivityWidgetWrapper>
            <ActivityWidget
              header="Organization Activity Log"
              organizationId={organizationId}
              ready={true}
            />
          </ActivityWidgetWrapper>
        </RightColumn>
      </ColumnWrapper>

      <ModalComponent
        headerText={"Manage Organization"}
        open={openEditOrganizationModal}
      >
        <AdminOrganizationForm
          organization={organization}
          handleSave={handlers.saveEditOrganization}
          handleCancel={handlers.closeEditOrganizationModal}
          loading={savingOrganization}
          error={savingOrganizationError}
          isEdit={true}
        />
      </ModalComponent>
    </>
  );
};

export default OrganizationDashboardContainer;
