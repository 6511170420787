import { Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import styled from "styled-components";
import colors from "../../theme/colors";

const FormContainer = styled.div`
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormSubmitError = styled.div`
  font-size: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  color: ${colors.redError};
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;
const HeaderNote = styled.div`
  font-size: 10px;
  font-style: italic;
  margin-bottom: 10px;
`;
const OkForm = ({ handleOk = () => {}, error, children }) => {
  const handlers = {
    save: () => {
      handleOk();
    },
  };

  return (
    <>
      <FormContainer>{children}</FormContainer>

      <ActionButtonsContainer>
        <Button onClick={handlers.save} variant="contained">
          OK
        </Button>
      </ActionButtonsContainer>
    </>
  );
};

export default OkForm;
