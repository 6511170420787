import { Tooltip, Card } from "@mui/material";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { InfoIcon } from "../../theme/icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../theme/colors";
import dayjs, { Dayjs } from "dayjs";

const CardWrapper = styled(Card)`
  margin: 0 15px 0px;
  @media (max-width: 1080px) {
    margin: 0;
  }
`;

const WidgetHeader = styled.div`
  font-size: 14px;
  padding: 20px 20px 5px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const LegendContainer = styled.div`
  padding: 0 0 15px 32px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${colors.greenFill};
  .green-dot {
    background: ${colors.greenFill};
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const PickersDayContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const FillDay = styled.div`
  position: absolute;
  background: ${colors.greenFill};
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin: 0 0 0 2px;
`;

const TankFillCalendarComponent = ({ tankFillEvents }) => {
  console.log("render calendar");

  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);

  const isFillDay = (props) => {
    if (!tankFillEvents.length) {
      return;
    }
    let selectedEvent;
    if (!props.outsideCurrentMonth) {
      const day = props.day.date();
      const month = props.day.month();
      const year = props.day.year();

      tankFillEvents.forEach((e) => {
        const eDate = new Date(e.exactFillTimeStamp);
        const eDay = eDate.getDate();
        const eMonth = eDate.getMonth();
        const eYear = eDate.getFullYear();
        if (day === eDay && month === eMonth && year === eYear) {
          selectedEvent = e;
        }
      });
    }
    return selectedEvent;
  };

  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <PickersDayContainer>
        {isFillDay(props) ? (
          <Tooltip
            enterTouchDelay={10}
            title={`Fill Amount: ${
              Math.round(isFillDay(props).eventFillAmount * 100) / 100
            } kg`}
          >
            <FillDay />
            <PickersDay
              {...other}
              outsideCurrentMonth={outsideCurrentMonth}
              day={day}
              sx={{ position: "aboslute" }}
            />
          </Tooltip>
        ) : (
          <PickersDay
            {...other}
            outsideCurrentMonth={outsideCurrentMonth}
            day={day}
            sx={{ position: "aboslute" }}
          />
        )}
      </PickersDayContainer>
    );
  }
  return (
    <CardWrapper>
      <WidgetHeader>
        Tank Fill Calendar
        <Tooltip
          enterTouchDelay={10}
          title="Fills are marked when a tank's weight increases at least 1kg. Use the Tank Weight trendline in the Tank Health Trends Chart to identify exactly when a fill took place."
        >
          <IconWrapper>
            <InfoIcon
              sx={{
                color: colors.grayDark,
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
          </IconWrapper>
        </Tooltip>
      </WidgetHeader>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          views={["day"]}
          readOnly={true}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays,
            },
          }}
        />
      </LocalizationProvider>
      <LegendContainer>
        <div className="green-dot"></div> Fill Date
      </LegendContainer>
    </CardWrapper>
  );
};

export default memo(TankFillCalendarComponent);
