const FixedTwoDecimalPlaces = (value) => {
  let _value = value;
  const _nums = _value.split(".");
  if (_nums[1]?.length > 2) {
    _value = `${_nums[0]}.${_nums[1][0]}${_nums[1][1]}`;
  }
  return _value;
};

export { FixedTwoDecimalPlaces };
