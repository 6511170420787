import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  TableSortLabel,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../theme/colors";
import { SearchIcon } from "../../theme/icons";

const EmptyTablePlaceHolder = styled.div`
  font-size: 30px;
  padding: 30px 20px;
  color: ${colors.grayLight};
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
`;

const ItemLink = styled(Link)`
  color: ${colors.blue};
  white-space: "nowrap";
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ItemOnClickLink = styled.div`
  color: ${colors.blue};
  white-space: "nowrap";
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const SearchBoxContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 8px 0;
`;

const TableComponent = ({
  columns,
  rows = [],
  placeholder = "Empty Table",
  loading = true,
  searchEnabled = false,
}) => {
  const tableId = new Date().getTime();

  const [sortDirection, setSortDirection] = useState(
    columns.find((col) => col.sortDefault).sortDefault
  );
  const [activeSortColumn, setActiveSortColumn] = useState(
    columns.find((col) => col.sortDefault)
  );
  const [sortedRows, setSortedRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [displayRows, setDisplayRows] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const [searchInput, setSearchInput] = useState("");

  const SortRows = () => {
    if (rows.length) {
      const { sortProperty, sortPropertyType } = activeSortColumn;
      // console.log("sorting", sortProperty, sortPropertyType);
      // console.log("unsorted rows", rows);
      let unsortedRows = rows;
      if (sortProperty && sortPropertyType) {
        switch (sortPropertyType) {
          case "number":
            //Number Sort
            if (sortDirection === "asc") {
              unsortedRows.sort(
                (a, b) =>
                  parseFloat(a[sortProperty]) - parseFloat(b[sortProperty])
              );
            } else {
              unsortedRows.sort(
                (a, b) =>
                  parseFloat(b[sortProperty]) - parseFloat(a[sortProperty])
              );
            }
            // console.log(unsortedRows);
            setSortedRows([...unsortedRows]);
            break;
          case "string":
            //String Sort
            unsortedRows.sort((a, b) => {
              if (
                a[sortProperty].toUpperCase() < b[sortProperty].toUpperCase()
              ) {
                return sortDirection === "asc" ? -1 : 1;
              } else if (
                a[sortProperty].toUpperCase() > b[sortProperty].toUpperCase()
              ) {
                return sortDirection === "asc" ? 1 : -1;
              }
              return 0;
            });
            // console.log(unsortedRows);
            setSortedRows([...unsortedRows]);
            break;
          case "boolean":
            //Boolean Sort
            if (sortDirection === "asc") {
              unsortedRows.sort((a, b) => b[sortProperty] - a[sortProperty]);
            } else {
              unsortedRows.sort((a, b) => a[sortProperty] - b[sortProperty]);
            }
            setSortedRows([...unsortedRows]);
            break;
          default:
        }
      }
    }
  };

  useEffect(() => {
    if (searchInput.length) {
      setDisplayRows(filteredRows);
    } else {
      setDisplayRows(sortedRows);
    }
  }, [sortedRows, filteredRows, searchInput]);

  useEffect(() => {
    // console.log("inital-rows");
    SortRows();
  }, [rows]);

  useEffect(() => {
    SortRows();
  }, [sortDirection, activeSortColumn]);

  useEffect(() => {
    //filter results based on search input value
    if (searchEnabled) {
      setFilteredRows(
        sortedRows.filter((r) => {
          if (r.name.toLowerCase().includes(searchInput.toLowerCase())) {
            return true;
          } else if (
            r.particleDeviceId.toLowerCase().includes(searchInput.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  }, [searchInput, sortedRows]);

  const handlers = {
    sort: async (column) => {
      // console.log(`sort-by-column-${column.propertyName}`);
      let direction = "asc";
      if (column.propertyName === activeSortColumn.propertyName) {
        direction = sortDirection === "asc" ? "desc" : "asc";
      }
      // console.log(`sort-direction-${direction}`);
      setSortDirection(direction);
      setActiveSortColumn(column);
      setPage(0);
    },
    onPageChange: async (e, newPage) => {
      setPage(newPage);
    },
    onRowsPerPageChange: async ({ target }) => {
      setRowsPerPage(target.value);
      setPage(0);
    },
    searchInputOnChange: async ({ target }) => {
      setSearchInput(target.value);
    },
  };

  return (
    <Paper sx={{ padding: "20px", width: "100%" }}>
      {searchEnabled && (
        <SearchBoxContainer>
          <TextField
            sx={{ maxWidth: "400px", width: "100%" }}
            id={`devices_table_searchbox`}
            label="Search Devices"
            variant="outlined"
            value={searchInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon></SearchIcon>
                </InputAdornment>
              ),
            }}
            onChange={handlers.searchInputOnChange}
            // helperText={device.error.message}
            // error={device.error.hasError}
          />
        </SearchBoxContainer>
      )}

      <TableContainer
        sx={{
          maxHeight: 440,
          maxWidth: "100%",
          width: "100%",
        }}
        component={Paper}
      >
        <Table stickyHeader sx={{ minWidth: 400 }} aria-label="table">
          <TableHead sx={{ background: colors.grayLightest }}>
            <TableRow>
              {columns.map((column, index) => {
                return (
                  <TableCell
                    key={`${tableId}:header:${index}`}
                    onClick={() => handlers.sort(column)}
                  >
                    <TableSortLabel
                      sx={{ whiteSpace: "nowrap" }}
                      active={
                        activeSortColumn.propertyName === column.propertyName
                      }
                      direction={sortDirection}
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow
                  key={`${tableId}:row:${rowIndex}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column, cellIndex) => {
                    if (column.onClick && column.navigate) {
                      return (
                        <TableCell
                          key={`${tableId}:row:${rowIndex}:cell:${cellIndex}`}
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          <ItemLink
                            onClick={() => column.onClick(row)}
                            to={column.navigate(row)}
                          >
                            {row[column.propertyName]}
                          </ItemLink>
                        </TableCell>
                      );
                    } else if (column.onClick) {
                      return (
                        <TableCell
                          key={`${tableId}:row:${rowIndex}:cell:${cellIndex}`}
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          <ItemOnClickLink onClick={() => column.onClick(row)}>
                            {row[column.propertyName]}
                          </ItemOnClickLink>
                        </TableCell>
                      );
                    } else if (column.navigate) {
                      return (
                        <TableCell
                          key={`${tableId}:row:${rowIndex}:cell:${cellIndex}`}
                          align="left"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          <ItemLink to={column.navigate(row)}>
                            {row[column.propertyName]}
                          </ItemLink>
                        </TableCell>
                      );
                    } else if (column.render) {
                      return (
                        <TableCell
                          key={`${tableId}:row:${rowIndex}:cell:${cellIndex}`}
                          align="left"
                          sx={{
                            color: colors.grayDarkText,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {column.render(row)}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={`${tableId}:row:${rowIndex}:cell:${cellIndex}`}
                          align="left"
                          sx={{
                            color: colors.grayDarkText,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row[column.propertyName]}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      {!loading && !sortedRows.length && (
        <EmptyTablePlaceHolder>{placeholder}</EmptyTablePlaceHolder>
      )}
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 500]}
        component="div"
        count={displayRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlers.onPageChange}
        onRowsPerPageChange={handlers.onRowsPerPageChange}
      />
    </Paper>
  );
};

export default TableComponent;
