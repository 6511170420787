import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import TableHeader from "../Page/tableHeader";
import { Card, CircularProgress } from "@mui/material";
import styled from "styled-components";

const Range = styled.div`
  font-size: 12px;
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

const TrendLineComponent = ({
  title,
  range,
  loaded,
  noDataPlaceholder,
  metricLabel = "",
  keyTitle = "",
  seriesData = [],
  convertLabelToLocalTime = true,
}) => {
  const options = {
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      gridLineWidth: 1,
      type: "datetime",
      dateTimeLabelFormats: {
        // don't display the year
        month: "%e. %b",
        year: "%b",
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    legend: {
      align: "left",
      title: {
        enabled: true,
        text: keyTitle,
      },
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: false,
      headerFormat: `<b>{point.y} ${metricLabel}</b><br />`,
      pointFormatter: function () {
        if (convertLabelToLocalTime) {
          return new Date(this.x).toLocaleString();
        } else {
          //format UTC date mm/dd/yyyy
          const d = new Date(this.x).toISOString().split("T")[0].split("-");
          return `${d[1]}/${d[2]}/${d[0]}`;
        }
      },
    },
    plotOptions: {
      series: {
        // animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        marker: {
          radius: 3,
          states: {
            hover: {
              radius: 4,
            },
          },
        },
        fillOpacity: 0.25,
      },
    },
    series: seriesData,
  };

  if (loaded && !seriesData.length) {
    return (
      <Card>
        <TableHeader>
          <h2>{title}</h2>
          <Range>{range}</Range>
        </TableHeader>
        {noDataPlaceholder}
      </Card>
    );
  } else if (loaded) {
    return (
      <Card>
        <TableHeader>
          <h2>{title}</h2>
          <Range>{range}</Range>
        </TableHeader>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Card>
    );
  } else {
    return (
      <Card>
        <TableHeader>
          <h2>{title}</h2>
          <Range>{range}</Range>
        </TableHeader>
        <CenterContainer>
          <CircularProgress />
        </CenterContainer>
      </Card>
    );
  }
};

export default TrendLineComponent;
