import colors from "../../theme/colors";
import MySettingsModal from "../Users/mySettingsModal";
import NavBar from "../Navigation/navbar";
import styled from "styled-components";
import Footer from "../Navigation/footer";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider, Container, Alert, AlertTitle } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import {
  WarningBanner,
  WarningBannerLink,
} from "../../components/BannerWarning";
import ModalComponent from "../Modal";
import OkForm from "../Forms/okForm";
import { updateShowSupportModal } from "../../redux-store/uxSlice";
import AcceptTermsModal from "../TermsConditionsPrivacyPolicy/acceptTermsModal";

const theme = createTheme({
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial"].join(","),
  },
  palette: {
    primary: {
      light: `${colors.blueLight}`,
      main: `${colors.blue}`,
      dark: `${colors.blueDark}`,
      contrastText: `${colors.white}`,
    },
    secondary: {
      light: `${colors.orange}`,
      main: `${colors.white}`,
      dark: `${colors.black}`,
      contrastText: `${colors.black}`,
    },
    black: {
      light: `${colors.black}`,
      main: `${colors.black}`,
      dark: `${colors.black}`,
      contrastText: `${colors.white}`,
    },
  },
});

const NotificationsContainer = styled.div`
  z-index: 1101;
  position: fixed;
  top: 20px;
  right: 20px;
  margin-left: 20px;
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  @media print {
    display: none !important;
  }
`;

const ContentSpacer = styled.div`
  height: 112px;
  @media print {
    display: none !important;
  }
`;

const ContentContainer = styled(Container)`
  height: 100%;
  min-height: calc(100vh - 175px);
  @media (max-width: 900px) {
    min-height: calc(100vh - 285px);
  }
`;

const RenderOrganizationWarning = ({ organizationWarningBanner }) => {
  const organization = useSelector((state) => state.organization);
  const NoUsersWarning = (
    <WarningBanner>
      <p>There are no Users in this Organization.</p>
      <WarningBannerLink to={`${organization?.id}/users`}>
        Add a User
      </WarningBannerLink>
      <p>to get started.</p>
    </WarningBanner>
  );

  const LocationWarning = (
    <WarningBanner>
      <p>There are no Locations in this Organization.</p>
      <WarningBannerLink to={`${organization?.id}/locations`}>
        Add a Location
      </WarningBannerLink>
      <p>to get started.</p>
    </WarningBanner>
  );

  const TanksWarning = (
    <WarningBanner>
      <p>There are no Tanks in this Organization.</p>
      <WarningBannerLink to={`${organization?.id}/tanks`}>
        Add a Tank
      </WarningBannerLink>
      <p>to get started.</p>
    </WarningBanner>
  );

  const OnCallWarning = (
    <WarningBanner>
      <p>On Call has not been set for all Locations in this Organization.</p>
      <WarningBannerLink to={`${organization?.id}/oncall`}>
        Manage On Call
      </WarningBannerLink>
      <p>to get started.</p>
    </WarningBanner>
  );

  switch (organizationWarningBanner.warningType) {
    case "no-users":
      return NoUsersWarning;
    case "no-locations":
      return LocationWarning;
    case "no-tanks":
      return TanksWarning;
    case "no-oncall":
      return OnCallWarning;
    default:
  }
};

const LayoutContainer = ({ children }) => {
  const notifications = useSelector((state) => state.notifications);
  const { isAdminArea, organizationWarningBanner, showSupportModal } =
    useSelector((state) => state.ux);
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <ContentSpacer />
      {!isAdminArea && (
        <RenderOrganizationWarning
          organizationWarningBanner={organizationWarningBanner}
        />
      )}
      <ContentContainer maxWidth="xl">{children}</ContentContainer>
      <Footer />
      <NotificationsContainer>
        {notifications.map((note) => {
          return (
            <Alert
              key={note.id}
              onClose={() => {
                dispatch(removeNotification(note.id));
              }}
              severity={note.severity}
              sx={{
                "& .MuiAlert-icon": {
                  color: "white",
                },
                width: "100%",
                color: "white",
                backgroundColor:
                  note.severity === "success"
                    ? colors.greenSuccess
                    : colors.redError,
              }}
            >
              <AlertTitle>{note.title}</AlertTitle>
              {note.message}
            </Alert>
          );
        })}
      </NotificationsContainer>
      <MySettingsModal />
      <AcceptTermsModal />
      <ModalComponent
        headerText="Contact Customer Support"
        open={showSupportModal}
      >
        <OkForm
          handleOk={() => {
            dispatch(updateShowSupportModal(false));
          }}
        >
          Please email{" "}
          <a href="mailto:support@boreasmonitoring.com">
            support@boreasmonitoring.com
          </a>{" "}
          for all support inquiries and questions. If you are experiencing
          issues with the Boreas Portal, please describe your issue in detail
          and provide screenshots if applicable. We will be quick to assist you.
        </OkForm>
      </ModalComponent>
    </ThemeProvider>
  );
};

export default LayoutContainer;
