import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux-store";
import "typeface-roboto";
import "./theme/css/main.css"

import "./amplify-configure";

import App from "./App";
import { Authenticator } from "@aws-amplify/ui-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Authenticator.Provider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Authenticator.Provider>
);
