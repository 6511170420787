import { get, post, put } from "aws-amplify/api";

const AlarmsGet = async ({ organizationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "alarms",
      options: {
        queryParams: { organizationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const AlarmsGetTankAlarms = async ({ tankId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "alarms/tank",
      options: {
        queryParams: { tankId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const AlarmGet = async ({ alarmId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "alarm",
      options: {
        queryParams: { alarmId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const AlarmGetActivityLog = async ({ alarmId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "alarm/activity",
      options: {
        queryParams: { alarmId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const AlarmPostComment = async ({ alarmId, comment }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "alarm/comment",
      options: {
        body: { alarmId, comment },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const AlarmPostCreateTestAlarm = async ({ tankId }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "alarm/test",
      options: {
        body: { tankId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const AlarmUpdateResolutionStatus = async ({ alarmId, status }) => {
  try {
    const { body } = await put({
      apiName: "boreas",
      path: "alarm/resolution-status",
      options: {
        body: { alarmId, status },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const Methods = {
  AlarmsGet,
  AlarmGet,
  AlarmsGetTankAlarms,
  AlarmGetActivityLog,
  AlarmPostComment,
  AlarmUpdateResolutionStatus,
  AlarmPostCreateTestAlarm,
};

export default Methods;
