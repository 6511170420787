import { get } from "aws-amplify/api";

const AlarmTrendsByLocationGet = async ({ organizationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "charts/alarm-trends-by-location",
      options: {
        queryParams: { organizationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankWeightTrendsForLocationGet = async ({ locationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "charts/tank-weight-trends-for-location",
      options: {
        queryParams: { locationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankWeightTrendGet = async ({ tankId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "charts/tank-weight-trend",
      options: {
        queryParams: { tankId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const Methods = {
  AlarmTrendsByLocationGet,
  TankWeightTrendsForLocationGet,
  TankWeightTrendGet,
};

export default Methods;
