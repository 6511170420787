import { Card, LinearProgress, Skeleton } from "@mui/material";
import colors from "../../theme/colors";
import styled from "styled-components";

const CustomCardWrapper = styled(Card)`
  padding: 20px;
  margin: 0 15px 20px;
  @media (max-width: 1080px) {
    margin: 0 0px 20px;
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WidgetHeader = styled.div`
  color: ${colors.gray};
  font-size: 12px;
`;

const SummaryHeader = styled.div`
  color: ${(props) => (props.$red ? colors.redError : colors.greenSuccess)};
  font-size: 20px;
`;

const SubText = styled.div`
  font-size: 12px;
`;

const BorderLinearProgress = styled(LinearProgress)`
  height: 10px !important;
  border-radius: 5px;
`;

const TankHealthSummaryWidget = ({
  title = "Health Summary",
  total = 0,
  unhealthy = 0,
  ready = false,
  type = "Organization"
}) => {
  const progress = total - unhealthy;
  const summaryText = unhealthy
    ? `${unhealthy} ${unhealthy > 1 ? "Tanks" : "Tank"} In Alarm State`
    : "All Tanks Are Healthy";
  const subText = `${progress} of ${total} tanks are healthy`;
  if (ready) {
    if (total === 0) {
      return (
        <CustomCardWrapper>
          <WidgetHeader>{title}</WidgetHeader>
          <SummaryHeader style={{ color: colors.gray }}>
            No Tanks in {type}
          </SummaryHeader>
          <BorderLinearProgress
            sx={{
              backgroundColor: colors.gray,
              "& .MuiLinearProgress-bar": {
                backgroundColor: colors.grayLight,
                borderRadius: "5px",
              },
            }}
            variant="determinate"
            value={50}
          />
          <SubText>There are no tanks in this {type.toLowerCase()} yet</SubText>
        </CustomCardWrapper>
      );
    } else {
      return (
        <CustomCardWrapper>
          <WidgetHeader>{title}</WidgetHeader>
          <SummaryHeader $red={unhealthy > 0}>{summaryText}</SummaryHeader>
          <BorderLinearProgress
            sx={{
              backgroundColor: colors.redError,
              "& .MuiLinearProgress-bar": {
                backgroundColor: colors.greenSuccess,
                borderRadius: "5px",
              },
            }}
            variant="determinate"
            value={(progress / total) * 100}
          />
          <SubText>{subText}</SubText>
        </CustomCardWrapper>
      );
    }
  } else {
    return (
      <CustomCardWrapper>
        <WidgetHeader>{title}</WidgetHeader>
        <SummaryHeader>
          <Skeleton height={30} />
        </SummaryHeader>
        <BorderLinearProgress variant="indeterminate" />
        <Skeleton height={15} />
      </CustomCardWrapper>
    );
  }
};

export default TankHealthSummaryWidget;
