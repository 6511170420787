// Boiler Plate Imports
import { useEffect, useState } from "react";
import colors from "../../theme/colors";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import { useParams } from "react-router-dom";
import Logo_Dots from "../../theme/images/Boreas_Logo_Dots_Square.png";
import Logo from "../../theme/images/Boreas_Logo_Color_Horizontal.png";

// Conatiner Component Imports
import { Button, Card, CircularProgress } from "@mui/material";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";

import DeviceConnectivityTestModal from "./deviceConnectivityTestModal";
import { formatDateTimeStamp } from "../../utils/date";

const NoPrintWrapper = styled.div`
  padding: 0 0 8px;
  @media print {
    display: none !important;
  }
`;

const PrintButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
`;

const CustomCard = styled(Card)`
  @media print {
    border: none;
    box-shadow: none !important;
  }
`;

const PaddingWrapper = styled.div`
  padding: 0 15px 10px;
  @media (max-width: 1080px) {
    padding: 0 0px 10px;
  }
  @media print {
    padding: 0 !important;
  }
`;

const NotesBody = styled.div`
  width: auto;
  height: 200px;
  border: 1px solid black;
  margin: 10px;
  border-radius: 4px;
`;

const HeaderContainer = styled.div`
  margin-bottom: 20px;
`;

const CertDetailsContainer = styled.div`
  padding: 20px;
  @media print {
    padding: 0;
  }
`;

const CertTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  @media print {
    font-size: 23px;
  }
`;

const TitleName = styled.span`
  color: ${colors.orange};
  white-space: nowrap;
`;

const CompanyPhone = styled.div`
  ${"" /* color: ${colors.orange}; */}
`;

const CompanyName = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin: 8px 0;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: top;
`;

const CompanyInfoContainer = styled.div`
  font-size: 16px;
`;

const CertSummaryContainer = styled.div`
  border: 1px solid ${colors.grayLight};
  border-radius: 4px;
  padding: 5px;
`;

const SectionContainer = styled.div`
  padding: 10px 0;
`;

const SectionBody = styled.div`
  font-size: 16px;
  ${"" /* padding: 0 20px; */}
`;

const BoldLabel = styled.span`
  font-weight: 500;
`;

const SectionBodyFlex = styled(SectionBody)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SectionBodyPadding = styled(SectionBody)`
  padding: 0 20px;
`;

const SectionHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid ${colors.grayLight};
  margin: 0 0 6px 0;
`;

const CertSectionHeader = styled(SectionHeader)`
  display: inline-block;
`;

const OrangeText = styled.span`
  font-weight: bold;
  color: ${colors.orange};
`;

const OrangeTextLarge = styled(OrangeText)`
  font-size: 22px;
`;

const GreenText = styled.span`
  font-weight: bold;
  color: ${colors.green};
`;

const GreenTextLarge = styled(GreenText)`
  font-size: 20px;
`;

const UList = styled.ul`
  padding: 0 20px;
  margin: 5px;
`;

const PenLines = styled.div`
  border-bottom: 1px solid ${colors.black};
  margin: 20px 0 0 0;
`;

const NotesContainer = styled.div`
  margin-top: 20px;
`;

const QCResultsImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 20px;
`;

const AdminDeviceQCContainer = () => {
  const { deviceId } = useParams();
  const [deviceQC, setDeviceQC] = useState({});
  const [loadingDeviceQC, setLoadingDeviceQC] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dataLayer.GetDeviceQualityCertificate();
    dispatch(
      setBreadCrumbs([
        {
          title: "Admin Devices",
          link: "/admin/devices",
        },
        {
          title: `...`,
          link: `/admin/devices/`,
        },
      ])
    );
  }, []);

  useEffect(() => {
    if (deviceQC?.device?.name) {
      dispatch(
        setBreadCrumbs([
          {
            title: "Admin Devices",
            link: "/admin/devices",
          },
          {
            title: `${deviceQC?.device?.name}`,
            link: `/admin/devices/${deviceId}`,
          },
          {
            title: `Quality Certificate`,
            link: `/admin/devices/${deviceId}/qc`,
          },
        ])
      );
    }
  }, [deviceQC]);

  const dataLayer = {
    GetDeviceQualityCertificate: async () => {
      const _deviceQC = await API.Device.DeviceGetQualityCertificate({
        deviceId,
      });
      setDeviceQC(_deviceQC);
      setLoadingDeviceQC(false);
    },
  };

  const handlers = {
    printButtonOnClick: () => {
      window.print();
    },
  };

  return (
    <>
      <BreadCrumbs />
      <PaddingWrapper>
        <PrintButtonContainer>
          <NoPrintWrapper>
            <div>
              <Button onClick={handlers.printButtonOnClick} variant="contained">
                Print Certificate
              </Button>
            </div>
          </NoPrintWrapper>
        </PrintButtonContainer>

        <CustomCard>
          {!loadingDeviceQC ? (
            deviceQC?.qcIsValid ? (
              <CertDetailsContainer>
                <HeaderContainer>
                  <CompanyInfoContainer>
                    <CertTitle>
                      Quality Certificate - CryoScout Module{" "}
                      <TitleName>({deviceQC?.device?.name})</TitleName>
                    </CertTitle>
                    <AddressContainer>
                      <div>
                        <CompanyName>
                          Boreas Monitoring Solutions, Inc
                        </CompanyName>
                        <div>7232 Wrightsville Ave, Unit D</div>
                        <div>Wilmington, NC 28403</div>
                        <div>boreasmonitoring.com</div>
                        <div>
                          <a href="mailto:support@boreasmonitoring.com">
                            support@boreasmonitoring.com
                          </a>
                        </div>
                        <CompanyPhone>844-926-7327</CompanyPhone>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <img
                          alt="boreas logo"
                          style={{ maxHeight: "50px", padding: "0 10px 0 0" }}
                          src={Logo}
                        />
                      </div>
                    </AddressContainer>
                  </CompanyInfoContainer>
                </HeaderContainer>
                <CertSummaryContainer>
                  <SectionBodyFlex>
                    <div>
                      <CertSectionHeader>
                        Quality Certificate Result |{" "}
                        {deviceQC?.passed ? (
                          <GreenText>PASS</GreenText>
                        ) : (
                          <OrangeText>FAIL</OrangeText>
                        )}
                      </CertSectionHeader>
                      <SectionBodyPadding>
                        <div>
                          <BoldLabel>Date and Time Evaluated:</BoldLabel>{" "}
                          {formatDateTimeStamp(
                            deviceQC?.latestDeviceCalibration.finishDate
                          )}
                        </div>
                        <div>
                          {" "}
                          <BoldLabel>Device Name:</BoldLabel>{" "}
                          {deviceQC?.device?.name}
                        </div>
                        <div>
                          {" "}
                          <BoldLabel>Reviewed By:</BoldLabel>{" "}
                          {deviceQC?.operatorName}
                        </div>
                        <div>
                          <BoldLabel>Review Date:</BoldLabel>{" "}
                          {formatDateTimeStamp(new Date().getTime())}
                        </div>
                      </SectionBodyPadding>
                    </div>
                    <QCResultsImageWrapper>
                      <img
                        alt="boreas logo"
                        style={{ maxHeight: "95px", display: "block" }}
                        src={Logo_Dots}
                      />

                      {deviceQC?.passed ? (
                        <GreenTextLarge>QC PASSED</GreenTextLarge>
                      ) : (
                        <OrangeTextLarge>QC FAILED</OrangeTextLarge>
                      )}
                    </QCResultsImageWrapper>
                  </SectionBodyFlex>
                </CertSummaryContainer>
                <SectionContainer>
                  <SectionHeader>Device Calibration Details</SectionHeader>
                  <SectionBodyPadding>
                    <div>
                      <BoldLabel>Calibration Date:</BoldLabel>{" "}
                      {formatDateTimeStamp(
                        deviceQC?.latestDeviceCalibration.finishDate
                      )}
                    </div>
                    <div>
                      <BoldLabel>Firmware Version:</BoldLabel> {" v"}
                      {deviceQC?.latestDeviceCalibration.fwVersion}
                    </div>
                    <div>
                      {" "}
                      <BoldLabel>Tank Module Serial:</BoldLabel>{" "}
                      {deviceQC?.device?.name}
                    </div>
                    <div>
                      <BoldLabel>Weighing Platform Serial:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceCalibration.weighingPlatformSerial}
                    </div>
                    <div>
                      <BoldLabel>Calibrate WP (0kg):</BoldLabel>{" "}
                      {
                        deviceQC?.latestDeviceCalibration
                          ?.calibrationWeightPlatform0kgValue
                      }
                    </div>
                    <div>
                      <BoldLabel>Calibrate WP (60kg):</BoldLabel>{" "}
                      {
                        deviceQC?.latestDeviceCalibration
                          ?.calibrationWeightPlatform60kgValue
                      }
                    </div>
                    <div>
                      <BoldLabel>Device ID (Particle Device):</BoldLabel>{" "}
                      {deviceQC.device.particleDeviceId}
                    </div>
                  </SectionBodyPadding>
                </SectionContainer>
                <SectionContainer>
                  <SectionHeader>
                    Calibration Test Results |{" "}
                    {deviceQC?.latestDeviceCalibration?.passed ? (
                      <GreenText>PASS</GreenText>
                    ) : (
                      <OrangeText>FAIL</OrangeText>
                    )}
                  </SectionHeader>
                  <SectionBodyPadding>
                    <div>
                      <BoldLabel>Operator Name:</BoldLabel>{" "}
                      {`${deviceQC?.calibrationUser?.firstName} ${deviceQC?.calibrationUser?.lastName} `}
                    </div>
                    <div>
                      <BoldLabel>Fit and Finish:</BoldLabel> No loose parts,
                      rattles, or gaps
                    </div>
                    <div>
                      <BoldLabel>Thermistor Reading:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceCalibration
                        ?.verifyThermistorPassed ? (
                        <GreenText>PASS</GreenText>
                      ) : (
                        <OrangeText>FAIL</OrangeText>
                      )}
                    </div>
                    <UList>
                      <li>
                        <BoldLabel>Temperature Testing Precision:</BoldLabel>{" "}
                        +/-{" "}
                        {
                          deviceQC?.latestDeviceCalibration
                            ?.tempThresholdPlusMinus
                        }
                        C
                      </li>
                      <li>
                        <BoldLabel>Thermistor Reading:</BoldLabel>{" "}
                        {
                          deviceQC?.latestDeviceCalibration
                            ?.verifyThermistorValue
                        }
                        C
                      </li>
                      <li>
                        <BoldLabel>Room Temperature:</BoldLabel>{" "}
                        {
                          deviceQC?.latestDeviceCalibration
                            ?.verifyThermometerRoomTemp
                        }
                        C
                      </li>
                    </UList>
                    <div>
                      <BoldLabel>Verify Weighing Platform:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceCalibration?.verify05kgPassed &&
                      deviceQC?.latestDeviceCalibration?.verify30kgPassed &&
                      deviceQC?.latestDeviceCalibration?.verify60kgPassed ? (
                        <GreenText>PASS</GreenText>
                      ) : (
                        <OrangeText>FAIL</OrangeText>
                      )}
                    </div>
                    <UList>
                      <li>
                        <BoldLabel>Weight Testing Precision:</BoldLabel> +/-{" "}
                        {
                          deviceQC?.latestDeviceCalibration
                            ?.weightThresholdPlusMinus
                        }
                        kg
                      </li>
                      <li>
                        <BoldLabel>Verify WP (0.5kg):</BoldLabel>{" "}
                        {deviceQC?.latestDeviceCalibration?.verify05kgPassed ? (
                          <GreenText>PASS</GreenText>
                        ) : (
                          <OrangeText>FAIL</OrangeText>
                        )}{" "}
                        {deviceQC?.latestDeviceCalibration?.verify05kgValue}kg
                      </li>
                      <li>
                        <BoldLabel>Verify WP (30kg):</BoldLabel>{" "}
                        {deviceQC?.latestDeviceCalibration?.verify30kgPassed ? (
                          <GreenText>PASS</GreenText>
                        ) : (
                          <OrangeText>FAIL</OrangeText>
                        )}{" "}
                        {deviceQC?.latestDeviceCalibration?.verify30kgValue}kg
                      </li>
                      <li>
                        <BoldLabel>Verify WP (60kg):</BoldLabel>{" "}
                        {deviceQC?.latestDeviceCalibration?.verify60kgPassed ? (
                          <GreenText>PASS</GreenText>
                        ) : (
                          <OrangeText>FAIL</OrangeText>
                        )}{" "}
                        {deviceQC?.latestDeviceCalibration?.verify60kgValue}kg
                      </li>
                    </UList>
                    <div>
                      <BoldLabel>Battery Charging:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceCalibration?.verifyChargePassed ? (
                        <GreenText>PASS</GreenText>
                      ) : (
                        <OrangeText>FAIL</OrangeText>
                      )}
                    </div>
                    <div>
                      <BoldLabel>Drift Test:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceCalibration
                        ?.verifyDriftTestPassed ? (
                        <GreenText>PASS</GreenText>
                      ) : (
                        <OrangeText>FAIL</OrangeText>
                      )}
                    </div>
                    <div>
                      <BoldLabel>Low Weight Alarm:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceCalibration
                        ?.verifyLowWeightAlarmPassed ? (
                        <GreenText>PASS</GreenText>
                      ) : (
                        <OrangeText>FAIL</OrangeText>
                      )}
                    </div>
                  </SectionBodyPadding>
                </SectionContainer>
                <SectionContainer>
                  <SectionHeader>
                    Connectivity Test Results |{" "}
                    {deviceQC?.latestDeviceConnectivityTest?.passed ? (
                      <GreenText>PASS</GreenText>
                    ) : (
                      <OrangeText>FAIL</OrangeText>
                    )}
                  </SectionHeader>
                  <SectionBodyPadding>
                    <div>
                      <BoldLabel>Operator Name:</BoldLabel>{" "}
                      {`${deviceQC?.connectivityTestUser?.firstName} ${deviceQC?.connectivityTestUser?.lastName} `}
                    </div>
                    <div>
                      <BoldLabel>Connectivity Test Date:</BoldLabel>{" "}
                      {formatDateTimeStamp(
                        deviceQC?.latestDeviceConnectivityTest.finishDate
                      )}
                    </div>
                    <div>
                      <BoldLabel>Battery Held Charge:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceConnectivityTest
                        ?.verifyBatteryHeldChargePassed ? (
                        <GreenText>PASS</GreenText>
                      ) : (
                        <OrangeText>FAIL</OrangeText>
                      )}
                    </div>
                    <div>
                      <BoldLabel>Tank Module Events Received:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceConnectivityTest
                        ?.verifyTankDataEventsPassed ? (
                        <GreenText>PASS</GreenText>
                      ) : (
                        <OrangeText>FAIL</OrangeText>
                      )}
                    </div>
                  </SectionBodyPadding>
                </SectionContainer>
                <SectionContainer>
                  <SectionHeader>Certified Calibration Tools</SectionHeader>
                  <SectionBodyPadding>
                    <div>
                      <BoldLabel>Calibrated Thermometer Serial:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceCalibration?.thermometerSerial}
                    </div>
                    <div>
                      <BoldLabel>Calibrated Weights Serial:</BoldLabel>{" "}
                      {deviceQC?.latestDeviceCalibration?.weightSerial}
                    </div>
                  </SectionBodyPadding>
                </SectionContainer>
                <SectionContainer>
                  <SectionHeader>Reviewed by and Notes</SectionHeader>
                  <SectionBodyPadding>
                    <PenLines>
                      <BoldLabel>Reviewing Organization:</BoldLabel>
                    </PenLines>
                    <PenLines>
                      <BoldLabel>Reviewer Name:</BoldLabel>
                    </PenLines>
                    <PenLines>
                      <BoldLabel>Reviewer Title:</BoldLabel>
                    </PenLines>
                    <PenLines>
                      <BoldLabel>Reviewed Date:</BoldLabel>
                    </PenLines>
                    <PenLines>
                      <BoldLabel>Reviewer Signature:</BoldLabel>
                    </PenLines>
                  </SectionBodyPadding>
                </SectionContainer>
                <NotesContainer>
                  <BoldLabel>Notes</BoldLabel>
                  <NotesBody></NotesBody>
                </NotesContainer>
              </CertDetailsContainer>
            ) : (
              "A valid Device Quality Certificate is required to have completed both Calibration and Connectivity Test"
            )
          ) : (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
        </CustomCard>
      </PaddingWrapper>
      <DeviceConnectivityTestModal />
    </>
  );
};

export default AdminDeviceQCContainer;
