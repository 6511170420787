// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";

// Conatiner Component Imports
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";
import DetailsWidgetComponent from "../../components/Widgets/detailsWidget";
import { useParams } from "react-router-dom";
import { FormatPhoneNumber } from "../../components/PhoneNumberInput";
import { FormatAddress } from "../../components/Forms/shared/address";
import OrganizationLocationForm from "../../components/Forms/organizationLocationForm";

import ActivityWidget from "../../components/Widgets/activityWidget";
import MetricBoxesComponent from "../../components/Page/metricBoxes";
import TrendLineComponent from "../../components/HighCharts/trendline";
import TanksStatusTableWidget from "../../components/Widgets/tanksStatusTableWidget";
import TankHealthSummaryWidget from "../../components/Widgets/tankHealthSummaryWidget";
import { NoChartDataPlaceholder } from "../../components/NoDataPlaceholder";
import colors from "../../theme/colors";

const LocationHeader = styled.div`
  font-size: 20px;
  color: ${colors.blue};
`;

const ColumnWrapper = styled.div`
  display: flex;
  @media (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const LeftColumn = styled.div`
  width: 100%;
  min-width: 0px;
`;
const RightColumn = styled.div`
  width: 100%;
  max-width: 500px;
  min-width: 0px;
  @media (max-width: 1080px) {
    max-width: 100%;
  }
`;
const PaddingWrapper = styled.div`
  padding: 0 15px 10px;
  @media (max-width: 1080px) {
    padding: 0 0px 10px;
  }
`;
const ActivityWidgetWrapper = styled.div``;

const OrganizationLocationContainer = () => {
  let { organizationId, locationId } = useParams();

  const userProfile = useSelector((state) => state.user);
  const ux = useSelector((state) => state.ux);
  const organization = useSelector((state) => state.organization);
  const [location, setLocation] = useState({});
  const [locationLoaded, setLocationLoaded] = useState(false);
  const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
  const [savingLocation, setSavingLocation] = useState(false);
  const [savingLocationError, setSavingLocationError] = useState("");
  const [tankTrendsDataLoaded, setTankTrendsDataLoaded] = useState(false);

  const [metrics, setMetrics] = useState();
  const [metricsLoaded, setMetricsLoaded] = useState();

  const [chartData, setChartData] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId) {
      dataLayer.GetLocation();
    }
  }, [organizationId]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "Locations",
          link: "/locations",
        },
        {
          title: "...",
          link: "/locations/",
        },
      ])
    );
  }, [organization]);

  useEffect(() => {
    if (locationId) {
      dataLayer.GetDashboardMetrics();
      dataLayer.GetTankWeightTrendsChartData();
    }
  }, [locationId]);

  useEffect(() => {
    if (locationLoaded && location && organization.name) {
      dispatch(
        setBreadCrumbs([
          {
            title: organization.name,
            link: "/organization",
          },
          {
            title: "Locations",
            link: "/locations",
          },
          {
            title: location.name,
            link: `/locations/${location.id}`,
          },
        ])
      );
    }
  }, [location, locationLoaded, organization]);

  const dataLayer = {
    GetLocation: async () => {
      const _location = await API.Location.LocationGet({ locationId });
      setLocation(_location);
      setLocationLoaded(true);
    },
    UpdateLocation: async (updateLocationData) => {
      try {
        const _updateLocationRes = await API.Location.LocationUpdate({
          updateLocationData,
        });
        setLocation(_updateLocationRes);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    GetDashboardMetrics: async () => {
      try {
        const _metrics = await API.Metrics.MetricsDashboardGet({
          resource: "location",
          resourceId: locationId,
        });
        setMetrics(_metrics);
        setMetricsLoaded(true);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    GetTankWeightTrendsChartData: async () => {
      try {
        const _chartData = await API.Charts.TankWeightTrendsForLocationGet({
          locationId,
        });
        setChartData(_chartData);
        setTankTrendsDataLoaded(true);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
  };

  const handlers = {
    openEditLocationModal: async () => {
      setOpenEditLocationModal(true);
    },
    closeEditLocationModal: async () => {
      setOpenEditLocationModal(false);
    },
    saveEditLocation: async (data) => {
      setSavingLocationError("");
      setSavingLocation(true);
      try {
        await dataLayer.UpdateLocation({
          ...data,
          locationId: location.id,
        });

        //Create Success Notification
        handlers.closeEditLocationModal();
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Location updated successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingLocationError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingLocation(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <PaddingWrapper>
        <LocationHeader>{location?.name}</LocationHeader>
      </PaddingWrapper>

      <ColumnWrapper>
        <LeftColumn>
          <MetricBoxesComponent
            showHeader={false}
            metrics={[
              {
                title: "Active Alarms",
                value: metrics?.activeAlarmsCount,
                to: "/alarms",
                redText: metrics?.activeAlarmsCount > 0,
              },
              {
                title: "Resolved Alarms",
                value: metrics?.resolvedAlarmsCount,
                to: "/alarms",
              },
              { title: "Tanks", value: metrics?.tanksCount, to: "/tanks" },
              {
                title: "On Call",
                value: metricsLoaded
                  ? metrics?.onCallUser
                    ? metrics?.onCallUser?.firstName
                    : "N/A"
                  : "",
                to: `/oncall/${locationId}`,
              },
            ]}
          />
          <PaddingWrapper>
            <TrendLineComponent
              title="Tank Weight Trends at this Location"
              range="Last 30 Days"
              seriesData={chartData?.seriesData}
              loaded={tankTrendsDataLoaded}
              metricLabel="kg"
              keyTitle="Tanks Key"
              noDataPlaceholder={
                <NoChartDataPlaceholder
                  header={"THERE ARE NO TANKS IN THIS LOCATION"}
                  body={"Add a tank to this location"}
                  buttonText={"Add Tank"}
                  buttonPath={`/${organizationId}/tanks`}
                />
              }
            />
          </PaddingWrapper>
          <PaddingWrapper>
            <TanksStatusTableWidget locationId={locationId} />
          </PaddingWrapper>
        </LeftColumn>
        <RightColumn>
          <TankHealthSummaryWidget
            title="Location Tank Health Summary"
            total={metrics?.tanksCount}
            unhealthy={metrics?.tanksInAlarmStateCount}
            ready={!!metrics}
            type={"Location"}
          />
          <DetailsWidgetComponent
            loading={!locationLoaded}
            showEdit={userProfile?.role && userProfile.role !== "TECH"}
            onEdit={handlers.openEditLocationModal}
            title={location.name}
            subtitle="Location Details"
            details={[
              FormatAddress({ ...location }),
              FormatPhoneNumber(location.phone),
            ]}
          />
          <ActivityWidgetWrapper>
            <ActivityWidget
              header="Location Activity Log"
              resource="location"
              resourceId={locationId}
              organizationId={organizationId}
              ready={true}
            />
          </ActivityWidgetWrapper>
        </RightColumn>
      </ColumnWrapper>

      <ModalComponent
        headerText={"Manage Location"}
        open={openEditLocationModal}
      >
        <OrganizationLocationForm
          location={location}
          handleSave={handlers.saveEditLocation}
          handleCancel={handlers.closeEditLocationModal}
          loading={savingLocation}
          error={savingLocationError}
          isEdit={true}
        />
      </ModalComponent>
    </>
  );
};

export default OrganizationLocationContainer;
