// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import { update as updateUserProfile } from "../../redux-store/userSlice";
import API from "../../api";
import colors from "../../theme/colors";
import { FullPageFormWrapper } from "../../components/Forms/shared";
import { useParams } from "react-router-dom";

// Conatiner Component Imports
import { CircularProgress } from "@mui/material";
import Table from "../../components/Table";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";
import TableHeader from "../../components/Page/tableHeader";

// Page Component Imports
import TankForm from "../../components/Forms/organizationTankForm";
import { FormatPhoneNumber } from "../../components/PhoneNumberInput";
import { useNavigate } from "react-router-dom";
import ConfirmForm from "../../components/Forms/confirmForm";

const OrganizationEditTankContainer = () => {
  const { organizationId, tankId } = useParams();

  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const ux = useSelector((state) => state.ux);

  const [tank, setTank] = useState();

  const [savingTank, setSavingTank] = useState(false);
  const [savingTankError, setSavingTankError] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [locations, setLocations] = useState([]);
  const [attributes, setAttributes] = useState();
  const [ready, setReady] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      locations.length > 0 &&
      attributes &&
      attributes.contents.length > 0 &&
      tank &&
      tank.id
    ) {
      setReady(true);
    }
  }, [locations, attributes, tank]);

  useEffect(() => {
    if (organizationId) {
      if (!locations.length) {
        dataLayer.GetLocations();
      }

      if (!attributes) {
        dataLayer.GetAttributes();
      }

      if (!tank) {
        dataLayer.GetTank();
      }
    }
  }, [organizationId]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "Tanks",
          link: "/tanks",
        },
        {
          title: tank?.name || "...",
          link: `/tanks/${tankId}`,
        },
        {
          title: "Edit",
          link: `/tanks/${tankId}/edit`,
        },
      ])
    );
  }, [organization, tank]);

  const dataLayer = {
    GetLocations: async () => {
      const _locations = await API.Location.LocationsGet({ organizationId });
      setLocations(_locations);
    },
    GetAttributes: async () => {
      const _attributes = await API.Tank.TankGetAttributes();
      setAttributes(_attributes);
    },
    GetTank: async () => {
      const _tank = await API.Tank.TankGet({
        tankId,
      });
      setTank(_tank);
    },
  };

  const handlers = {
    confirmModalSubmit: () => {
      navigate(`/${organizationId}/tanks/${tankId}`);
    },
    confirmModalCancel: () => {
      setShowConfirmModal(false);
    },
    cancel: () => {
      setShowConfirmModal(true);
    },
    saveTank: async (data) => {
      setSavingTankError("");
      setSavingTank(true);
      try {
        await API.Tank.TankUpdate({
          editTankData: { ...data, tankId },
        });
        //Create Success Notification
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Tank updated successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
        handlers.confirmModalSubmit();
      } catch (err) {
        let errorMessage = err;
        switch (err) {
          case "scaleid-in-use":
            errorMessage =
              "ScaleId is in use on another Tank. Deactivate the other tank or connect this Tank to an new ScaleId";
            break;
          default:
            break;
        }

        setSavingTankError(errorMessage);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error Saving Tank",
            message: errorMessage,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingTank(false);
    },
    deactivateTankClick: async () => {
      //Show deactivate confirm modal
      setShowDeactivateModal(true);
    },
    deactivateTankConfirmClick: async () => {
      setSavingTankError("");
      setSavingTank(true);
      try {
        await API.Tank.TankDelete({
          deleteTankId: tank.id,
        });
        dataLayer.GetTank();
        //Create Success Notification
        setShowDeactivateModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Tank deactivated successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingTankError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingTank(false);
    },
    deactivateTankCancelClick: async () => {
      //Hide deactive confirm modal
      setShowDeactivateModal(false);
    },
    reactivateTankClick: async () => {
      //Show reactivate confirm modal
      setShowReactivateModal(true);
    },
    reactivateTankConfirmClick: async () => {
      setSavingTankError("");
      setSavingTank(true);
      try {
        await API.Tank.TankDelete({
          deleteTankId: tank.id,
          reactivate: 'true',
        });
        dataLayer.GetTank();
        //Create Success Notification
        setShowReactivateModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Tank reactivated successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        let errorMessage = err;
        switch (err) {
          case "scaleid-in-use":
            errorMessage =
              "ScaleId is in use on another Tank. Deactivate the other tank or connect this Tank to an new ScaleId";
            break;
          default:
            break;
        }
        setSavingTankError(errorMessage);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: errorMessage,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingTank(false);
    },
    reactivateTankCancelClick: async () => {
      // hide deactive confirm modal
      setShowReactivateModal(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <FullPageFormWrapper>
        <h2>
          Manage Tank <span style={{ color: colors.gray }}>/ {tank?.name}</span>
        </h2>
        {ready ? (
          <TankForm
            tank={tank}
            handleSave={handlers.saveTank}
            handleCancel={handlers.cancel}
            loading={savingTank}
            error={savingTankError}
            locations={locations}
            contents={attributes.contents}
            makeAndModel={attributes.makeAndModel}
            standardAlarmValues={attributes.standardAlarmValues}
            types={attributes.types}
            isEdit={true}
            showDeactivate={ux.isAdminUser}
            handleDeactivateTank={handlers.deactivateTankClick}
            handleReactivateTank={handlers.reactivateTankClick}
          />
        ) : (
          <CircularProgress />
        )}
      </FullPageFormWrapper>
      {ready && (
        <>
          <ModalComponent
            open={showConfirmModal}
            handleClose={handlers.confirmModalCancel}
            headerText={"Cancel Add New Tank"}
          >
            <ConfirmForm
              handleSave={handlers.confirmModalSubmit}
              handleCancel={handlers.confirmModalCancel}
              loading={false}
            >
              <div>
                <b>Are you sure you want to Cancel Managing Tank?</b>
                <p>You will lose all progress.</p>
              </div>
            </ConfirmForm>
          </ModalComponent>
          <ModalComponent
            headerText={`Reactivate ${tank.name}`}
            open={showReactivateModal}
          >
            <ConfirmForm
              handleSave={handlers.reactivateTankConfirmClick}
              handleCancel={handlers.reactivateTankCancelClick}
              loading={savingTank}
              error={savingTankError}
            >
              <div>
                <b>Are you sure you want to Reactivate {tank.name}?</b>
                <p>This will enable tank data and alarms.</p>
              </div>
            </ConfirmForm>
          </ModalComponent>
          <ModalComponent
            headerText={`Deactivate ${tank.name}`}
            open={showDeactivateModal}
          >
            <ConfirmForm
              handleSave={handlers.deactivateTankConfirmClick}
              handleCancel={handlers.deactivateTankCancelClick}
              loading={savingTank}
              error={savingTankError}
            >
              <div>
                <b>Are you sure you want to Deactivate {tank.name}?</b>
                <p>This will disable tank data and alarms.</p>
              </div>
            </ConfirmForm>
          </ModalComponent>
        </>
      )}
    </>
  );
};

export default OrganizationEditTankContainer;
