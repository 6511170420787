import { get, post, put, del } from "aws-amplify/api";

const LocationsGet = async ({ organizationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "locations",
      options: {
        queryParams: { organizationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const LocationGet = async ({ locationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "location",
      options: {
        queryParams: { locationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const LocationCreate = async ({ createLocationData }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "location",
      options: {
        body: { ...createLocationData },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const LocationUpdate = async ({ updateLocationData }) => {
  try {
    const { body } = await put({
      apiName: "boreas",
      path: "location",
      options: {
        body: { ...updateLocationData },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const Methods = {
  LocationGet,
  LocationsGet,
  LocationCreate,
  LocationUpdate,
};

export default Methods;
