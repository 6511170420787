import { Route, Routes } from "react-router-dom";

import AdminUserContainer from "../../containers/AdminUsers";
import AdminOrganizations from "../../containers/AdminOrganizations";
import AdminDevices from "../../containers/AdminDevices";
import AdminDevicesAdd from "../../containers/AdminDevices/addDevice";
import AdminDeviceDashboard from "../../containers/AdminDevices/deviceDashboard";
import AdminDeviceCalibration from "../../containers/AdminDevices/calibrateDevice";
import AdminDeviceQC from "../../containers/AdminDevices/deviceQC.js";

import OrganizationUsers from "../../containers/OrganizationUsers";
import OrganizationLocations from "../../containers/OrganizationLocations";
import OrganizationLocation from "../../containers/OrganizationLocation";

import OrganizationTanks from "../../containers/OrganizationTanks";
import OrganizationTankAdd from "../../containers/OrganizationTank/addTank";
import OrganizationTankEdit from "../../containers/OrganizationTank/editTank";
import OrganizationTank from "../../containers/OrganizationTank/";

import OrganizationAlarms from "../../containers/OrganizationAlarms";
import OrganizationAlarm from "../../containers/OrganizationAlarm";

import OrganizationDashboard from "../../containers/OrganizationDashboard";
import OrganizationOnCall from "../../containers/OrganizationOnCall";
import OrganizationOnCallManageLocation from "../../containers/OrganizationOnCall/manageOnCallLocation.js";

import OrganizatioActivity from "../../containers/OrganizationActivity/index.js";

const NotFound = () => {
  return <div>Page Not Found</div>;
};

const Routing = () => {
  return (
    <Routes>
      {/* Blank Route to avoid showing not found while routing after login  */}
      <Route path="/" element={<></>} />
      <Route path="login" element={<></>} />

      {/* Admin Routes  */}
      <Route path="admin" element={<AdminOrganizations />} />
      <Route path="admin/organizations" element={<AdminOrganizations />} />
      <Route path="admin/users" element={<AdminUserContainer />} />
      <Route path="admin/devices" element={<AdminDevices />} />
      <Route path="admin/devices/add" element={<AdminDevicesAdd />} />
      <Route
        path="admin/devices/:deviceId"
        element={<AdminDeviceDashboard />}
      />
      <Route
        path="admin/devices/:deviceId/calibrate"
        element={<AdminDeviceCalibration />}
      />
      <Route path="admin/devices/:deviceId/qc" element={<AdminDeviceQC />} />

      {/* Customer Routes  */}
      <Route
        path="/:organizationId/organization"
        element={<OrganizationDashboard />}
      />

      <Route path="/:organizationId/users" element={<OrganizationUsers />} />
      <Route
        path="/:organizationId/locations"
        element={<OrganizationLocations />}
      />
      <Route
        path="/:organizationId/locations/:locationId"
        element={<OrganizationLocation />}
      />

      <Route path="/:organizationId/tanks" element={<OrganizationTanks />} />
      <Route
        path="/:organizationId/tanks/add"
        element={<OrganizationTankAdd />}
      />
      <Route
        path="/:organizationId/tanks/:tankId"
        element={<OrganizationTank />}
      />
      <Route
        path="/:organizationId/tanks/:tankId/edit"
        element={<OrganizationTankEdit />}
      />

      <Route path="/:organizationId/alarms" element={<OrganizationAlarms />} />
      <Route
        path="/:organizationId/alarms/:alarmId"
        element={<OrganizationAlarm />}
      />

      <Route path="/:organizationId/oncall" element={<OrganizationOnCall />} />
      <Route
        path="/:organizationId/oncall/:locationId"
        element={<OrganizationOnCallManageLocation />}
      />

      <Route
        path="/:organizationId/activity"
        element={<OrganizatioActivity />}
      />

      {/* Error Routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routing;
