// Boiler Plate Imports
import styled from "styled-components";
import colors from "../../theme/colors";
import { useEffect, useState, memo } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import API from "../../api";

// Conatiner Component Imports
import { Button, Card } from "@mui/material";
import Table from "../../components/Table";
import TableHeader from "../../components/Page/tableHeader";

const NoPrintWrapper = styled.div`
  @media print {
    display: none !important;
  }
`;

const PrintOnlyWrapper = styled.div`
  display: none;
  @media print {
    display: block !important;
  }
`;

const StatusTag = styled.div`
  border-radius: 15px;
  text-align: center;
  padding: 2px;
  width: 87px;
  font-weight: bold;
  background: ${colors.grayLight};
  color: ${colors.grayDark};
  border: 1px solid ${colors.gray};
  &.alarm {
    background: ${colors.redLightError};
    color: ${colors.redError};
    border: 1px solid ${colors.redError};
  }
  &.progress {
    background: ${colors.yellowLight};
    color: ${colors.yellow};
    border: 1px solid ${colors.yellow};
  }
  &.resolved {
    background: ${colors.greenLightSuccess};
    color: ${colors.greenSuccess};
    border: 1px solid ${colors.greenSuccess};
  }
`;
const TankAlarmHistoryWidget = ({ tankId }) => {
  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const { organizationId } = useParams();

  const [alarmTableRows, setAlarmTableRows] = useState([]);
  const [loadingAlarms, setLoadingAlarms] = useState(true);
  const [getData, setGetData] = useState(true);

  const alarmTableColumns = [
    {
      title: "Alarm Type",
      propertyName: "alarmInfoDisplayName",
      sortProperty: "alarmInfoDisplayName",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/alarms/${row.id}`;
      },
    },
    {
      title: "Alarm Status",
      propertyName: "active",
      sortProperty: "active",
      sortPropertyType: "string",
      sortDefault: "desc",
      render: (row) => {
        const statusString = row.active === "true" ? "Active" : "Resolved";
        return (
          <StatusTag className={row.active === "true" ? "alarm" : "resolved"}>
            {statusString}
          </StatusTag>
        );
      },
    },
    {
      title: "Resolution Status",
      propertyName: "resolutionStatus",
      sortProperty: "resolutionStatus",
      sortPropertyType: "string",
      render: (row) => {
        let statusString = "Not Started";
        let className = "alarm";
        if (row.resolutionStatus === "resolved") {
          statusString = "Resolved";
          className = "resolved";
        } else if (row.resolutionStatus === "progress") {
          statusString = "In Progress";
          className = "progress";
        }
        return <StatusTag className={className}>{statusString}</StatusTag>;
      },
    },
    {
      title: "Tank Name",
      propertyName: "tankName",
      sortProperty: "tankName",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/tanks/${row.tankId}`;
      },
    },
    {
      title: "Tank Location",
      propertyName: "locationName",
      sortProperty: "locationName",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/locations/${row.locationId}`;
      },
    },
    {
      title: "Alarm Created",
      propertyName: "created",
      sortProperty: "created",
      sortPropertyType: "number",
      render: (row) => new Date(row.created).toLocaleString(),
    },
    {
      title: "Alarm Resolved",
      propertyName: "resolved",
      sortProperty: "resolvedDate",
      sortPropertyType: "number",
      render: (row) => {
        if (row.resolvedDate) {
          return new Date(row.resolvedDate).toLocaleString();
        } else {
          return "-";
        }
      },
    },
  ];

  const printAlarmTableColumns = [
    {
      title: "Alarm Type",
      propertyName: "alarmInfoDisplayName",
      sortProperty: "alarmInfoDisplayName",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/alarms/${row.id}`;
      },
    },
    {
      title: "Alarm Created",
      propertyName: "created",
      sortProperty: "created",
      sortPropertyType: "number",
      sortDefault: "desc",
      render: (row) => new Date(row.created).toLocaleString(),
    },
    {
      title: "Alarm Resolved",
      propertyName: "resolved",
      sortProperty: "resolvedDate",
      sortPropertyType: "number",
      render: (row) => {
        if (row.resolvedDate) {
          return new Date(row.resolvedDate).toLocaleString();
        } else {
          return "-";
        }
      },
    },
  ];

  //Polling Setup
  const [dataInterval, setDataInterval] = useState();
  let refreshIntervalSeconds = 60;
  const [pollingStarted, setPollingStarted] = useState(false);
  const [nextRefreshInSeconds, setNextRefreshInSeconds] = useState(
    refreshIntervalSeconds
  );

  useEffect(() => {
    if (organizationId) {
      if (!pollingStarted) {
        FetchData();
        PollData();
        setPollingStarted(true);
      }
    }
  }, [organizationId]);

  useEffect(() => {
    return () => {
      clearInterval(dataInterval);
    };
  }, []);

  useEffect(() => {
    if (nextRefreshInSeconds === 0) {
      FetchData();
      setNextRefreshInSeconds(refreshIntervalSeconds);
    }
  }, [nextRefreshInSeconds]);

  const PollData = async () => {
    clearInterval(dataInterval);
    const d = setInterval(() => {
      setNextRefreshInSeconds((s) => s - 1);
    }, 1000);
    setDataInterval(d);
  };

  const FetchData = async () => {
    await dataLayer.GetTankAlarms();
  };

  const dataLayer = {
    GetTankAlarms: async () => {
      setLoadingAlarms(true);
      const alarms = await API.Alarm.AlarmsGetTankAlarms({ tankId });
      const alarmsRows = alarms.map((alarm) => {
        return {
          ...alarm,
          alarmInfoDisplayName: alarm.alarmInfo.displayName,
          tankName: alarm.tank.name, //flatten for the table and sorting
          locationName: alarm.tank.location.name,
        };
      });
      alarmsRows.sort((a, b) => {
        return b.created - a.created;
      });
      setAlarmTableRows(alarmsRows);
      setLoadingAlarms(false);
    },
  };

  const handlers = {};

  return (
    <Card>
      <TableHeader>
        <h2>Tank Alarm History</h2>
        <NoPrintWrapper>
          <Button
            component={Link}
            to={`/${organizationId}/alarms`}
            variant="outlined"
            color="primary"
            size="small"
          >
            VIEW ALARMS
          </Button>
        </NoPrintWrapper>
      </TableHeader>
      <NoPrintWrapper>
        <Table
          columns={alarmTableColumns}
          rows={alarmTableRows}
          loading={loadingAlarms}
          placeholder="There are no alarms yet"
        />
      </NoPrintWrapper>
      <PrintOnlyWrapper>
        <Table
          columns={printAlarmTableColumns}
          rows={alarmTableRows}
          loading={loadingAlarms}
          placeholder="There are no alarms yet"
        />
      </PrintOnlyWrapper>
    </Card>
  );
};

export default memo(TankAlarmHistoryWidget);
