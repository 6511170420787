import { get, put } from "aws-amplify/api";

const OnCallGetOrganizationOncall = async ({ organizationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "oncall/organization",
      options: {
        queryParams: { organizationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const OnCallGetLocationOnCall = async ({ locationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "oncall/location",
      options: {
        queryParams: { locationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const OnCallUpdateLocationOnCall = async ({ updateOnCallData }) => {
  try {
    const { body } = await put({
      apiName: "boreas",
      path: "oncall/location",
      options: {
        body: updateOnCallData,
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const Methods = {
  OnCallUpdateLocationOnCall,
  OnCallGetLocationOnCall,
  OnCallGetOrganizationOncall,
};

export default Methods;
