// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import { update as updateUserProfile } from "../../redux-store/userSlice";
import API from "../../api";
import colors from "../../theme/colors";

// Conatiner Component Imports
import { Button, Card } from "@mui/material";
import Table from "../../components/Table";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";
import ConfirmForm from "../../components/Forms/confirmForm";
import TableHeader from "../../components/Page/tableHeader";

// Page Component Imports
import AdminUserForm from "../../components/Forms/adminUserForm";
import { FormatPhoneNumber } from "../../components/PhoneNumberInput";

const ModalEditHeaderUserName = styled.span`
  color: ${colors.gray};
  margin-left: 5px;
`;

const PaddingWrapper = styled.div`
  padding: 0 15px 10px;
  @media (max-width: 1080px) {
    padding: 0 0px 10px;
  }
`;
const AdminUserContainer = () => {
  const userProfile = useSelector((state) => state.user);

  const [savingUser, setSavingUser] = useState(false);
  const [savingUserError, setSavingUserError] = useState(false);
  const [userTableRows, setUserTableRows] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openReactivateModal, setOpenReactivateModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [currentUserEdit, setCurrentUserEdit] = useState({});
  const dispatch = useDispatch();

  const userTableColumns = [
    {
      title: "Name",
      propertyName: "name",
      sortProperty: "name",
      sortPropertyType: "string",
      onClick: (row) => {
        //Open User Edit Modal
        handlers.editUserClick(row.id);
      },
    },
    {
      title: "Email Address",
      propertyName: "email",
      sortProperty: "email",
      sortPropertyType: "string",
    },
    {
      title: "Phone Number",
      propertyName: "phoneString",
      sortProperty: "phone",
      sortPropertyType: "number",
    },
    {
      title: "Status",
      propertyName: "status",
      sortProperty: "status",
      sortPropertyType: "string",
    },
    {
      title: "Role",
      propertyName: "role",
      sortProperty: "role",
      sortPropertyType: "string",
    },
    {
      title: "Date Created",
      propertyName: "dateCreateString",
      sortProperty: "created",
      sortPropertyType: "number",
      sortDefault: "desc",
    },
  ];

  useEffect(() => {
    dataLayer.GetAdminUsers();
  }, []);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: "Admin Users",
          link: "/admin/users",
        },
      ])
    );
  }, []);

  useEffect(() => {
    if (openEditUserModal) {
      const user = userTableRows.find((u) => u.id === currentUserEdit.id);
      setCurrentUserEdit(user);
    }
  }, [userTableRows]);

  const dataLayer = {
    GetAdminUsers: async () => {
      setLoadingUsers(true);
      const users = await API.User.UserGetAdmins();
      const userRows = users.map((user) => {
        return {
          ...user,
          name: `${user.firstName} ${user.lastName}`,
          status: (() => {
            if (user.deleted) {
              return "Deactivated";
            } else if (user.invitePending) {
              return "Invite Pending";
            } else {
              return "Active";
            }
          })(),
          dateCreateString: new Date(user.created).toLocaleString(),
          phoneString: FormatPhoneNumber(user.phone),
        };
      });
      userRows.sort((a, b) => {
        return b.created - a.created;
      });
      setUserTableRows(userRows);
      setLoadingUsers(false);
    },
    GetUserProfile: async () => {
      const userProfile = await API.User.UserGet();
      dispatch(updateUserProfile(userProfile));
    },
  };

  const handlers = {
    addUserClick: () => {
      setSavingUserError("");
      setOpenModal(true);
    },
    editUserClick: (userId) => {
      const user = userTableRows.find((u) => u.id === userId);
      setCurrentUserEdit(user);
      setOpenEditUserModal(true);
    },
    closeUserModal: () => {
      if (!savingUser) {
        setOpenModal(false);
      }
    },
    saveUser: async (data) => {
      setSavingUserError("");
      setSavingUser(true);
      try {
        await API.User.UserCreate({
          createUserData: { ...data, type: "EMPLOYEE", role: "ADMIN" },
        });
        //Reload Users
        dataLayer.GetAdminUsers();
        //Create Success Notification
        setOpenModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "User created successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingUserError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingUser(false);
    },
    saveEditUser: async (data) => {
      setSavingUserError("");
      setSavingUser(true);
      try {
        await API.User.UserUpdate({
          editUserData: { ...currentUserEdit, ...data },
        });
        //Did I just update me?
        if (userProfile.id === currentUserEdit.id) {
          await dataLayer.GetUserProfile();
        }

        //Reload Users
        await dataLayer.GetAdminUsers();

        //Create Success Notification
        setOpenEditUserModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "User updated successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingUserError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingUser(false);
    },
    closeEditUserModal: () => {
      setOpenEditUserModal(false);
    },
    confirmDeactivateUserModal: () => {
      setOpenDeactivateModal(true);
    },
    deactivateUser: async () => {
      setSavingUserError("");
      setSavingUser(true);
      try {
        await API.User.UserDelete({
          deleteUserId: currentUserEdit.id,
        });
        //Reload Users
        dataLayer.GetAdminUsers();
        //Create Success Notification
        setOpenDeactivateModal(false);
        setOpenEditUserModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "User deactivated successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingUserError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingUser(false);
    },
    confirmReactivateUserModal: () => {
      setOpenReactivateModal(true);
    },
    reactivateUser: async () => {
      setSavingUserError("");
      setSavingUser(true);
      try {
        await API.User.UserDelete({
          deleteUserId: currentUserEdit.id,
          reactivate: 'true',
        });
        //Reload Users
        dataLayer.GetAdminUsers();
        setOpenReactivateModal(false);
        setOpenEditUserModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "User reactivated successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingUserError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingUser(false);
    },
    resendUserInviteEmail: async () => {
      setSavingUserError("");
      setSavingUser(true);
      try {
        await API.User.ResendUserInvite({
          resendUserId: currentUserEdit.id,
        });
        //Reload Users
        dataLayer.GetAdminUsers();
        setOpenReactivateModal(false);
        setOpenEditUserModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "User invite resent. Check email.",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingUserError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingUser(false);
    },
    closeDeactivateModal: () => {
      setOpenDeactivateModal(false);
    },
    closeReactivateModal: () => {
      setOpenReactivateModal(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <PaddingWrapper>
        <Card>
          <TableHeader>
            <h2>Boreas Admin Users</h2>
            <Button
              onClick={handlers.addUserClick}
              variant="contained"
              color="primary"
              size="large"
            >
              ADD USER
            </Button>
          </TableHeader>
          <Table
            columns={userTableColumns}
            rows={userTableRows}
            loading={loadingUsers}
            placeholder="There are no users created yet"
          />
        </Card>
      </PaddingWrapper>
      <ModalComponent
        headerText={`Reactivate ${currentUserEdit.firstName} ${currentUserEdit.lastName}`}
        open={openReactivateModal}
      >
        <ConfirmForm
          handleSave={handlers.reactivateUser}
          handleCancel={handlers.closeReactivateModal}
          loading={savingUser}
          error={savingUserError}
        >
          <div>
            <b>
              Are you sure you want to Reactivate {currentUserEdit.firstName}{" "}
              {currentUserEdit.lastName}?
            </b>
            <p>
              This will enable this user's access to Boreas. They will be able
              to sign in. You can revoke access by deactivating this user.
            </p>
          </div>
        </ConfirmForm>
      </ModalComponent>
      <ModalComponent
        headerText={`Deactivate ${currentUserEdit.firstName} ${currentUserEdit.lastName}`}
        open={openDeactivateModal}
      >
        <ConfirmForm
          handleSave={handlers.deactivateUser}
          handleCancel={handlers.closeDeactivateModal}
          loading={savingUser}
          error={savingUserError}
        >
          <div>
            <b>
              Are you sure you want to Deactivate {currentUserEdit.firstName}{" "}
              {currentUserEdit.lastName}?
            </b>
            <p>
              This will disable this user's access to Boreas. They will be
              unable to sign in. The user will not be deleted. You can restore
              access by reactivating this user.
            </p>
          </div>
        </ConfirmForm>
      </ModalComponent>
      <ModalComponent headerText="Add Boreas Admin User" open={openModal}>
        <AdminUserForm
          handleSave={handlers.saveUser}
          handleCancel={handlers.closeUserModal}
          loading={savingUser}
          error={savingUserError}
        />
      </ModalComponent>
      <ModalComponent
        headerText={
          <>
            Manage Admin User
            <ModalEditHeaderUserName>
              / {currentUserEdit.name}
            </ModalEditHeaderUserName>
          </>
        }
        open={openEditUserModal}
      >
        <AdminUserForm
          user={currentUserEdit}
          handleSave={handlers.saveEditUser}
          handleCancel={handlers.closeEditUserModal}
          loading={savingUser}
          error={savingUserError}
          isEdit={true}
          showDeactivate={currentUserEdit.id !== userProfile.id}
          handleDeactivateUser={handlers.confirmDeactivateUserModal}
          handleReactivateUser={handlers.confirmReactivateUserModal}
          handleResendInvite={handlers.resendUserInviteEmail}
        />
      </ModalComponent>
    </>
  );
};

export default AdminUserContainer;
