import User from "./user";
import Organization from "./organization";
import Location from "./location";
import Tank from "./tank";
import Alarm from "./alarm";
import Activity from "./activity";
import OnCall from "./oncall";
import Metrics from "./metrics";
import Charts from "./charts";
import Device from "./device";

const Endpoints = {
  Activity,
  User,
  Tank,
  Location,
  Organization,
  Alarm,
  OnCall,
  Metrics,
  Charts,
  Device,
};

export default Endpoints;
