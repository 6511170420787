import { fetchAuthSession } from "aws-amplify/auth";
import { Amplify } from "aws-amplify";

console.log("REACT_APP_ENV", process.env.REACT_APP_ENV);
console.log("REACT_APP_API_ENDPOINT", process.env.REACT_APP_API_ENDPOINT);
console.log("REACT_APP_COGNITO_REGION", process.env.REACT_APP_COGNITO_REGION);
console.log(
  "REACT_APP_COGNITO_USER_POOL_ID",
  process.env.REACT_APP_COGNITO_USER_POOL_ID
);
console.log(
  "REACT_APP_COGNITO_APP_CLIENT_ID",
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID
);

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      },
    },
    API: {
      REST: {
        boreas: {
          endpoint: `${process.env.REACT_APP_API_ENDPOINT}/`,
          region: process.env.REACT_APP_COGNITO_REGION,
        },
      },
    },
  },
  {
    API: {
      REST: {
        headers: async () => {
          const authToken = (
            await fetchAuthSession()
          ).tokens?.idToken?.toString();
          return {
            Authorization: authToken,
          };
        },
      },
    },
  }
);
