import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const breadCrumbsSlice = createSlice({
  name: "breadCrumbs",
  initialState,
  reducers: {
    update: (state, action) => {
      return action.payload;
    },
    clear: (state) => {
      state = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { update, clear } = breadCrumbsSlice.actions;

export default breadCrumbsSlice.reducer;
