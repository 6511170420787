import { get, post, put, del } from "aws-amplify/api";

const UserGet = async () => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "user",
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const UserGetAdmins = async () => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "user/admins",
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const UserGetOrganizationUsers = async ({ organizationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "user/organization",
      options: {
        queryParams: { organizationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const UserCreate = async ({ createUserData }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "user",
      options: {
        body: { ...createUserData },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    switch (errorMessage) {
      case "UsernameExistsException":
        throw "User already exists under that email address.";
      default:
        throw errorMessage;
    }
  }
};

const UserUpdate = async ({ editUserData }) => {
  try {
    const { body } = await put({
      apiName: "boreas",
      path: "user",
      options: {
        body: { ...editUserData },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const UserDelete = async ({ deleteUserId, reactivate = "false" }) => {
  try {
    const { body } = await del({
      apiName: "boreas",
      path: "user",
      options: {
        queryParams: { deleteUserId, reactivate },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const UserAcceptTCPP = async () => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "user/accept-tc-pp",
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const ResendUserInvite = async ({ resendUserId }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "user/resend-invite",
      options: {
        body: { resendUserId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const Methods = {
  UserGet,
  UserDelete,
  UserGetAdmins,
  UserCreate,
  UserUpdate,
  UserGetOrganizationUsers,
  UserAcceptTCPP,
  ResendUserInvite,
};

export default Methods;
