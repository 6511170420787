// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import colors from "../../theme/colors";

// Conatiner Component Imports
import { Card } from "@mui/material";
import Table from "../../components/Table";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import TableHeader from "../../components/Page/tableHeader";
import TableSubHeader from "../../components/Page/tableSubHeader";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const OrganizationOnCallContainer = () => {
  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);

  const [oncallTableRows, setOncallTableRows] = useState([]);
  const [loadingOnCall, setLoadingOnCall] = useState(true);
  const dispatch = useDispatch();

  const { organizationId } = useParams();

  const oncallTableColumns = [
    {
      title: "Location",
      propertyName: "locationName",
      sortProperty: "locationName",
      sortPropertyType: "string",
      sortDefault: "asc",
      navigate: (row) => {
        return `/${organizationId}/oncall/${row.locationId}`;
      },
    },
    {
      title: "On Call",
      propertyName: "currentOnCallUserName",
      sortProperty: "currentOnCallUserName",
      sortPropertyType: "string",
      render: (row) => {
        if (row.currentOnCallUserName === "not set") {
          return (
            <div style={{ color: colors.orange }}>
              {row.currentOnCallUserName}
            </div>
          );
        } else {
          return row.currentOnCallUserName;
        }
      },
    },
    {
      title: "Backup 1",
      propertyName: "backupOneUserName",
      sortProperty: "backupOneUserName",
      sortPropertyType: "string",
      render: (row) => {
        if (row.backupOneUserName === "not set") {
          return (
            <div style={{ color: colors.orange }}>{row.backupOneUserName}</div>
          );
        } else {
          return row.backupOneUserName;
        }
      },
    },
    {
      title: "Backup 2",
      propertyName: "backupTwoUserName",
      sortProperty: "backupTwoUserName",
      sortPropertyType: "string",
      render: (row) => {
        if (row.backupTwoUserName === "not set") {
          return (
            <div style={{ color: colors.orange }}>{row.backupTwoUserName}</div>
          );
        } else {
          return row.backupTwoUserName;
        }
      },
    },
    {
      title: "Last Updated",
      propertyName: "modifiedString",
      sortProperty: "modified",
      sortPropertyType: "number",
    },
  ];

  useEffect(() => {
    if (organizationId) {
      dataLayer.GetOrganizationOnCall();
    }
  }, [organizationId]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "On Call",
          link: "/oncall",
        },
      ])
    );
  }, [organization]);

  const dataLayer = {
    GetOrganizationOnCall: async () => {
      setLoadingOnCall(true);
      const _oncall = await API.OnCall.OnCallGetOrganizationOncall({
        organizationId,
      });
      const rows = _oncall.map((item) => {
        return {
          locationName: item.location.name,
          locationId: item.location.id,
          currentOnCallUserId: item.oncall.currentOnCallUserId,
          currentOnCallUserName: item.oncall.currentOnCallUser?.firstName
            ? `${item.oncall.currentOnCallUser?.firstName} ${item.oncall.currentOnCallUser?.lastName}`
            : "not set",
          backupOneUserId: item.oncall.backupOneUserId,
          backupOneUserName: item.oncall.backupOneUser?.firstName
            ? `${item.oncall.backupOneUser?.firstName} ${item.oncall.backupOneUser?.lastName}`
            : "not set",
          backupTwoUserId: item.oncall.backupTwoUserId,
          backupTwoUserName: item.oncall.backupTwoUser?.firstName
            ? `${item.oncall.backupTwoUser?.firstName} ${item.oncall.backupTwoUser?.lastName}`
            : "not set",
          modified: item.oncall?.modified ? item.oncall.modified : 0,
          modifiedString: item.oncall?.modified
            ? new Date(item.oncall.modified).toLocaleString()
            : "",
        };
      });
      rows.sort((a, b) => {
        return b.modified - a.modified;
      });
      setOncallTableRows(rows);
      setLoadingOnCall(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <Card>
        <TableHeader>
          <div>
            <h2>On Call</h2>
            <TableSubHeader>
              Boreas On-Call allows you to manage who will receive notifications
              in the event of an alarm. View on-call details for your
              organization below. Click on a location name to manage the on-call
              settings for that location.
            </TableSubHeader>
          </div>
        </TableHeader>
        <Table
          columns={oncallTableColumns}
          rows={oncallTableRows}
          loading={loadingOnCall}
          placeholder="No On Call Results"
        />
      </Card>
    </>
  );
};

export default OrganizationOnCallContainer;
