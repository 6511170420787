import { TextField, Button, Card } from "@mui/material";
import FormFieldErrorClass from "./models/formFieldError";
import ErrorIcon from "@mui/icons-material/Error";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormContainer,
  FormSubmitError,
  ActionButtonsContainer,
  HeaderNote,
} from "./shared";
import styled from "styled-components";
import colors from "../../theme/colors";

const OnCallContainer = styled(Card)`
  padding: 20px;
`;

const ActionButtonsContainerWithSpace = styled(ActionButtonsContainer)`
  margin: 35px 0 60px;
`;

const UserSelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 10px 0;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const BlueHeaderH2 = styled.h2`
  color: ${colors.blue};
  margin: 0;
  padding: 0;
`;

const BlackHeaderH2 = styled.h2`
  margin: 0;
  padding: 0;
`;

const BlueFontBold = styled.div`
  font-weight: bold;
  color: ${colors.blue};
`;

const SubText = styled.div`
  font-size: 14px;
  color: ${colors.grayDark};
`;

const LocationOnCallForm = ({
  handleCancel = () => {},
  handleSave = () => {},
  loading = false,
  error = "",
  locationOnCall = {},
  users = [],
  ready = false,
}) => {
  const navigate = useNavigate();

  const [save, setSave] = useState(false);

  const [currentOnCallUserId, setCurrentOnCallUserId] = useState("");
  const [backupOneUserId, setBackupOneUserId] = useState("");
  const [backupTwoUserId, setBackupTwoUserId] = useState("");

  const [currentOnCallUserIdError, setCurrentOnCallUserIdError] = useState(
    new FormFieldErrorClass()
  );
  const [backupOneUserIdError, setBackupOneUserIdError] = useState(
    new FormFieldErrorClass()
  );
  const [backupTwoUserIdError, setBackupTwoUserIdError] = useState(
    new FormFieldErrorClass()
  );

  useEffect(() => {
    if (locationOnCall && users.length) {
      if (locationOnCall.currentOnCallUserId) {
        setCurrentOnCallUserId(locationOnCall.currentOnCallUserId);
      } else {
        setCurrentOnCallUserId(users[0].id);
      }
      if (locationOnCall.backupOneUserId) {
        setBackupOneUserId(locationOnCall.backupOneUserId);
      }
      if (locationOnCall.backupTwoUserId) {
        setBackupTwoUserId(locationOnCall.backupTwoUserId);
      }
    }
  }, [locationOnCall, users]);

  const formFieldErrors = [
    currentOnCallUserIdError,
    backupOneUserIdError,
    backupTwoUserIdError,
  ];

  const validate = {
    currentOnCallUserId: (value) => {
      setCurrentOnCallUserIdError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setCurrentOnCallUserIdError({
          hasError: true,
          message: "Current On Call Required",
        });
        return;
      }
    },
    backupOneUserId: (value) => {
      setCurrentOnCallUserIdError({
        hasError: false,
        message: "",
      });
      // if (!value) {
      //   setCurrentOnCallUserIdError({
      //     hasError: true,
      //     message: "Current On Call Required",
      //   });
      //   return;
      // }
    },
    backupTwoUserId: (value) => {
      setCurrentOnCallUserIdError({
        hasError: false,
        message: "",
      });
      // if (!value) {
      //   setCurrentOnCallUserIdError({
      //     hasError: true,
      //     message: "Current On Call Required",
      //   });
      //   return;
      // }
    },
  };

  const handlers = {
    currentOnCallUserIdChange: ({ target }) => {
      const { value } = target;
      validate.currentOnCallUserId(value);
      setCurrentOnCallUserId(value);
    },
    backupOneUserIdChange: ({ target }) => {
      const { value } = target;
      validate.backupOneUserId(value);
      setBackupOneUserId(value);
    },
    backupTwoUserIdChange: ({ target }) => {
      const { value } = target;
      validate.backupTwoUserId(value);
      setBackupTwoUserId(value);
    },

    save: () => {
      //validate all
      validate.currentOnCallUserId(currentOnCallUserId);
      validate.backupOneUserId(backupOneUserId);
      validate.backupTwoUserId(backupTwoUserId);
      setSave(true);
    },
    cancel: () => {
      handleCancel();
    },
  };

  useEffect(() => {
    if (save) {
      setSave(false);
      if (formFieldErrors.filter((e) => e.hasError).length) {
        return;
      }

      //send data
      handleSave({
        currentOnCallUserId,
        backupOneUserId,
        backupTwoUserId,
      });
    }
  }, [save, formFieldErrors]);

  if (!ready) {
    return;
  }

  if (ready && !users.length) {
    return (
      <div>
        <h3>Please create Users before setting up On Call</h3>
        <Button
          onClick={() => {
            navigate("/users");
          }}
          variant="outlined"
        >
          Manage Users
        </Button>
      </div>
    );
  }

  return (
    <>
      <FormContainer>
        {/* <HeaderNote>All fields required</HeaderNote> */}
        <OnCallContainer>
          <UserSelectContainer>
            <div>
              <BlueHeaderH2>Currently On Call:</BlueHeaderH2>
              <SubText>
                This User is first in line to receive notifications for all
                alarms that occur for this Location
              </SubText>
            </div>

            <TextField
              style={{ width: "300px" }}
              id="current-oncall"
              select
              variant="outlined"
              label="Select a User"
              value={currentOnCallUserId}
              helperText={currentOnCallUserIdError.message}
              error={currentOnCallUserIdError.hasError}
              SelectProps={{
                native: true,
              }}
              onChange={handlers.currentOnCallUserIdChange}
            >
              {users
                .filter(
                  (u) => u.id !== backupOneUserId && u.id !== backupTwoUserId
                )
                .map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.firstName} {item.lastName}
                    </option>
                  );
                })}
            </TextField>
          </UserSelectContainer>
        </OnCallContainer>

        <OnCallContainer>
          <div>
            <BlackHeaderH2>Backup On Call:</BlackHeaderH2>
            <SubText>
              These Users will be next in line to receive notifications in the
              event an Alarm Resolution Status threshold is exceeded
            </SubText>
          </div>

          <UserSelectContainer>
            <div>
              <b>BACK UP 1 IS NOTIFIED WHEN</b>
              <ul>
                <li>
                  <BlueFontBold>
                    Alarm Resolution Status is 'Pending' for over 5 minutes
                  </BlueFontBold>
                </li>
                <li>
                  <BlueFontBold>
                    Alarm Resolution Status is 'In Progress' for over 45 minutes
                  </BlueFontBold>
                </li>
              </ul>

              {/* <SubText>This Person will be Notified of the Alarm</SubText> */}
            </div>
            <TextField
              style={{ width: "300px" }}
              id="backup-one-oncall"
              select
              variant="outlined"
              label="Select a User"
              value={backupOneUserId}
              helperText={backupOneUserIdError.message}
              error={backupOneUserIdError.hasError}
              SelectProps={{
                native: true,
              }}
              onChange={handlers.backupOneUserIdChange}
            >
              <option value={null}></option>
              {users
                .filter(
                  (u) =>
                    u.id !== currentOnCallUserId && u.id !== backupTwoUserId
                )
                .map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.firstName} {item.lastName}
                    </option>
                  );
                })}
            </TextField>
          </UserSelectContainer>

          <UserSelectContainer>
            <div>
              <b>BACK UP 2 IS NOTIFIED WHEN</b>
              <ul>
                <li>
                  <BlueFontBold>
                    Alarm Resolution State is 'Pending' for over 10 minutes
                  </BlueFontBold>
                </li>
                <li>
                  <BlueFontBold>
                    Alarm Resolution State is 'In Progress' for over 90 minutes
                  </BlueFontBold>
                </li>
              </ul>
            </div>
            <TextField
              style={{ width: "300px" }}
              id="backup-two-oncall"
              select
              variant="outlined"
              label="Select a User"
              value={backupTwoUserId}
              helperText={backupTwoUserIdError.message}
              error={backupTwoUserIdError.hasError}
              SelectProps={{
                native: true,
              }}
              onChange={handlers.backupTwoUserIdChange}
            >
              <option value={null}></option>
              {users
                .filter(
                  (u) =>
                    u.id !== currentOnCallUserId && u.id !== backupOneUserId
                )
                .map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.firstName} {item.lastName}
                    </option>
                  );
                })}
            </TextField>
          </UserSelectContainer>
        </OnCallContainer>
      </FormContainer>

      {error && (
        <FormSubmitError>
          <ErrorIcon fontSize="small" />
          {error}
        </FormSubmitError>
      )}

      <ActionButtonsContainerWithSpace>
        <Button onClick={handlers.cancel}>Cancel</Button>
        {loading ? (
          <Button disabled variant="contained">
            Saving&#8230;
          </Button>
        ) : (
          <Button onClick={handlers.save} variant="contained">
            Save
          </Button>
        )}
      </ActionButtonsContainerWithSpace>
    </>
  );
};

export default LocationOnCallForm;
