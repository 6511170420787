// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import colors from "../../theme/colors";
import { FullPageFormWrapper } from "../../components/Forms/shared";
import { CircularProgress } from "@mui/material";

// Conatiner Component Imports
import BreadCrumbs from "../../components/Navigation/breadcrumbs";

// Page Component Imports
import ManageLocationOnCallForm from "../../components/Forms/organizationLocationOnCallForm";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import { updateReloadOrganization } from "../../redux-store/uxSlice";

const OrganizationOnCallManageLocationContainer = () => {
  const { locationId, organizationId } = useParams();
  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);

  const [users, setUsers] = useState([]);
  const [locationOnCall, setLocationOnCall] = useState({});
  const [loadingOnCall, setLoadingOnCall] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [ready, setReady] = useState(false);
  const [savingOnCall, setSavingOnCall] = useState(false);
  const [savingOnCallError, setSavingOnCallError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (organizationId) {
      dataLayer.GetOnCall();
      dataLayer.GetOrganizationUsers({ organizationId });
    }
  }, [organizationId]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "On Call",
          link: "/oncall",
        },
        {
          title: locationOnCall?.location?.name || "",
          link: `/oncall/${locationId}`,
        },
      ])
    );
  }, [organization, locationOnCall]);

  useEffect(() => {
    if (!loadingOnCall && !loadingUsers) {
      setReady(true);
    }
  }, [loadingOnCall, loadingUsers]);

  const dataLayer = {
    GetOnCall: async () => {
      setLoadingOnCall(true);
      const _data = await API.OnCall.OnCallGetLocationOnCall({
        locationId,
      });
      setLocationOnCall(_data);
      setLoadingOnCall(false);
    },
    GetOrganizationUsers: async ({ organizationId }) => {
      setLoadingUsers(true);
      const _users = await API.User.UserGetOrganizationUsers({
        organizationId,
      });
      setUsers(_users);
      setLoadingUsers(false);
    },
  };

  const handlers = {
    cancel: () => {
      navigate(`/${organizationId}/oncall`);
    },
    saveOnCall: async (data) => {
      setSavingOnCallError("");
      setSavingOnCall(true);
      try {
        await API.OnCall.OnCallUpdateLocationOnCall({
          updateOnCallData: { ...data, locationId },
        });
        //Reload toplevel banners
        dispatch(updateReloadOrganization(true));
        //Create Success Notification
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "On Call saved successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
        navigate(`/${organizationId}/oncall`);
      } catch (err) {
        setSavingOnCallError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error Saving On Call",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingOnCall(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <FullPageFormWrapper>
        <h2>Manage On Call for {locationOnCall?.location?.name}</h2>
        <p>
          Manage who is on call to receive and handle alarm notifications below
        </p>
        {ready ? (
          <ManageLocationOnCallForm
            handleSave={handlers.saveOnCall}
            handleCancel={handlers.cancel}
            loading={savingOnCall}
            error={savingOnCallError}
            locationOnCall={locationOnCall?.oncall}
            users={users}
            ready={ready}
          />
        ) : (
          <CircularProgress />
        )}
      </FullPageFormWrapper>
    </>
  );
};

export default OrganizationOnCallManageLocationContainer;
