import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    add: (state, action) => {
      return [action.payload, ...state];
    },
    remove: (state, action) => {
      return state.filter((note) => {
        return note.id !== action.payload;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { add, remove } = notificationsSlice.actions;

export default notificationsSlice.reducer;
