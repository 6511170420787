const colors = {
  // siteBackground: "#F9FAFC", //this is being set in the index.html

  white: "#FFFFFF",
  black: "#000000",

  siteBackground: "#F9FAFC",
  blue: "#1565C0",
  blueSlate: "#293241",
  blueDark: "#3D5980",
  blueLight: "#98C1D9",
  blueMedLight: "#2caffd",

  orange: "#EE6C4D",
  redError: "#D32F2F",
  redLightError: "#FFD3D7",
  green: "#4CAF50",
  greenSuccess: "#2E7D32",
  greenLightSuccess: "#C0D8C1",
  greenFill: "#A6D7A8",


  yellow: "#ff9f12",
  yellowLight: "#fff7ea",

  gray: "#BBBBBB",
  grayDark: "#757575",
  grayLight: "#D8D8D8",
  grayLightest: "#FAFAFA",

  grayDarkText: "#4D4D4D",

  purpleLight: "#F1EDFF",
  purple: "#615ec9",

};

export default colors;
