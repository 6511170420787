import styled from "styled-components";
// import { ChevronRight } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import colors from "../../theme/colors";
import { useSelector } from "react-redux";

import SvgIcon from "@mui/material/SvgIcon";

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

function ChevronRight(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    </SvgIcon>
  );
}

const ContentArea = styled.div`
  padding: 35px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  @media (max-width: 700px) {
    padding: 15px 0px;
  }
  @media print {
    display: none !important;
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: ${colors.grayDark};
  margin: 0 6px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 700px) {
    margin: 0 4px;
  }
`;
const LinkItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BreadCrumbs = () => {
  const crumbs = useSelector((state) => state.breadCrumbs);
  const organization = useSelector((state) => state.organization);
  const { isAdminArea } = useSelector((state) => state.ux);
  const { organizationId } = useParams();
  return (
    <ContentArea>
      {crumbs.map((item, index) => {
        return (
          <LinkItemContainer key={"crumb:" + item.title}>
            {index !== 0 && (
              <ChevronRight sx={{ color: colors.grayDark, fontSize: 16 }} />
            )}
            {isAdminArea ? (
              <LinkItem to={item.link}>
                {index === 0 && (
                  <HomeIcon sx={{ color: colors.grayDark, fontSize: 16 }} />
                )}
                {item.title}
              </LinkItem>
            ) : (
              <LinkItem to={`/${organizationId}${item.link}`}>
                {index === 0 && (
                  <HomeIcon sx={{ color: colors.grayDark, fontSize: 16 }} />
                )}
                {item.title}
              </LinkItem>
            )}
          </LinkItemContainer>
        );
      })}
    </ContentArea>
  );
};

export default BreadCrumbs;
