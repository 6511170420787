import { get, post, put, del } from "aws-amplify/api";

const TanksGet = async ({ organizationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "tanks",
      options: {
        queryParams: { organizationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankGet = async ({ tankId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "tank",
      options: {
        queryParams: { tankId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankGetNotes = async ({ tankId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "tank/notes",
      options: {
        queryParams: { tankId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankCreateNote = async ({ tankId, note }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "tank/notes",
      options: {
        body: { tankId, note },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankGetAttributes = async () => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "tank/attributes",
      options: {
        queryParams: {},
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankGetFillEvents = async ({ tankId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "tank/fill-events",
      options: {
        queryParams: { tankId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankCreate = async ({ createTankData }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "tank",
      options: {
        body: { ...createTankData },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankUpdate = async ({ editTankData }) => {
  try {
    const { body } = await put({
      apiName: "boreas",
      path: "tank",
      options: {
        body: { ...editTankData },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const TankDelete = async ({ deleteTankId, reactivate = 'false' }) => {
  try {
    const { body } = await del({
      apiName: "boreas",
      path: "tank",
      options: {
        queryParams: { deleteTankId, reactivate },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const Methods = {
  TanksGet,
  TankGet,
  TankCreate,
  TankUpdate,
  TankGetAttributes,
  TankDelete,
  TankGetNotes,
  TankCreateNote,
  TankGetFillEvents,
};

export default Methods;
