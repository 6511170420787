import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showMySettingsModal: false,
  isAdminArea: false,
  isAdminUser: false,
  organizationWarningBanner: {
    show: true,
    warningType: null,
  },
  reloadOrganization: false,
  showSupportModal: false,
  deviceConnectivityTestModal: {
    show: false,
    device: {
      name: "",
      id: null
    },
  },
};

export const uxSlice = createSlice({
  name: "ux",
  initialState,
  reducers: {
    updateShowMySettingsModal: (state, action) => {
      state.showMySettingsModal = action.payload;
    },
    updateShowSupportModal: (state, action) => {
      state.showSupportModal = action.payload;
    },
    updateIsAdminArea: (state, action) => {
      state.isAdminArea = action.payload;
    },
    updateOrganizationWarningBanner: (state, action) => {
      state.organizationWarningBanner = { ...action.payload };
    },
    updateReloadOrganization: (state, action) => {
      state.reloadOrganization = action.payload;
    },
    updateIsAdminUser: (state, action) => {
      state.isAdminUser = action.payload;
    },
    updateDeviceConnectivityTestModal: (state, action) => {
      state.deviceConnectivityTestModal = { ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateShowMySettingsModal,
  updateIsAdminArea,
  updateOrganizationWarningBanner,
  updateReloadOrganization,
  updateIsAdminUser,
  updateShowSupportModal,
  updateDeviceConnectivityTestModal,
} = uxSlice.actions;

export default uxSlice.reducer;
