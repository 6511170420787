import { TextField, Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useState, useEffect } from "react";
import styled from "styled-components";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import PhoneNumberInput from "../PhoneNumberInput";
import colors from "../../theme/colors";

const FormContainer = styled.div`
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormSubmitError = styled.div`
  font-size: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  color: ${colors.redError};
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;
const HeaderNote = styled.div`
  font-size: 10px;
  font-style: italic;
  margin-bottom: 10px;
`;
const ConfirmForm = ({
  handleCancel = () => {},
  handleSave = () => {},
  cancelButtonText = "NO",
  saveButtonText = "YES",
  savingButtonText = "Saving",
  error,
  loading,
  children,
}) => {
  const handlers = {
    save: () => {
      handleSave();
    },
    cancel: () => {
      handleCancel();
    },
  };

  return (
    <>
      <FormContainer>{children}</FormContainer>

      {error && (
        <FormSubmitError>
          <ErrorIcon fontSize="small" />
          {error}
        </FormSubmitError>
      )}

      <ActionButtonsContainer>
        <Button onClick={handlers.cancel}>{cancelButtonText}</Button>
        {loading ? (
          <Button disabled variant="contained">
            <>{savingButtonText}&#8230;</>
          </Button>
        ) : (
          <Button onClick={handlers.save} variant="contained">
            {saveButtonText}
          </Button>
        )}
      </ActionButtonsContainer>
    </>
  );
};

export default ConfirmForm;
