import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    updateOrganization: (state, action) => {
      return { ...action.payload };
    },
    clearOrganization: (state) => {
      return {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateOrganization, clearOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;