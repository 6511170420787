import { Card, LinearProgress, Skeleton, Button } from "@mui/material";
import colors from "../../theme/colors";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CustomCardWrapper = styled(Card)`
  padding: 20px;
  margin: 0 15px 20px;
  @media (max-width: 1080px) {
    margin: 0 0px 20px;
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WidgetHeader = styled.div`
  color: ${colors.gray};
  font-size: 12px;
`;

const SummaryHeader = styled.div`
  color: ${(props) => (props.$red ? colors.redError : colors.greenSuccess)};
  font-size: 20px;
`;

const SubText = styled.div`
  font-size: 14px;
`;

const BorderLinearProgress = styled(LinearProgress)`
  height: 10px !important;
  border-radius: 5px;
`;

const DeviceQCStatusWidget = ({
  title = "Device Quality Control Status",
  qcIsValid = false,
  latestCalibration = {},
  latestConnectivityTest = {},
  startCalibrationOnClick = () => {},
  startConnectivityTestOnClick = () => {},
  ready = false,
  failedQC,
}) => {
  if (ready) {
    if (!qcIsValid) {
      return (
        <CustomCardWrapper>
          <WidgetHeader>{title}</WidgetHeader>
          <SummaryHeader style={{ color: colors.gray }}>
            This device has not been QC'd
          </SummaryHeader>
          <BorderLinearProgress
            sx={{
              backgroundColor: colors.gray,
              "& .MuiLinearProgress-bar": {
                backgroundColor: colors.grayLight,
                borderRadius: "5px",
              },
            }}
            variant="determinate"
            value={50}
          />
          {!latestCalibration?.id ? (
            <SubText>
              To complete the QC process,
              <Button
                size="small"
                variant="text"
                onClick={startCalibrationOnClick}
              >
                Start Calibration
              </Button>
            </SubText>
          ) : (
            !latestConnectivityTest?.id && (
              <SubText>
                To complete the QC process,
                <Button
                  size="small"
                  variant="text"
                  onClick={startConnectivityTestOnClick}
                >
                  Start Connectivity Test
                </Button>
              </SubText>
            )
          )}
        </CustomCardWrapper>
      );
    } else {
      return (
        <CustomCardWrapper>
          <WidgetHeader>{title}</WidgetHeader>
          <SummaryHeader $red={failedQC}>
            {failedQC
              ? "This device has failed QC"
              : "This device has passed QC"}
          </SummaryHeader>
          <BorderLinearProgress
            sx={{
              backgroundColor: colors.redError,
              "& .MuiLinearProgress-bar": {
                backgroundColor: colors.greenSuccess,
                borderRadius: "5px",
              },
            }}
            variant="determinate"
            value={failedQC ? 0 : 100}
          />
          <SubText>
            <Link to="qc" target="_blank">View Quality Certificate</Link>
          </SubText>
        </CustomCardWrapper>
      );
    }
  } else {
    return (
      <CustomCardWrapper>
        <WidgetHeader>{title}</WidgetHeader>
        <SummaryHeader>
          <Skeleton height={30} />
        </SummaryHeader>
        <BorderLinearProgress variant="indeterminate" />
        <Skeleton height={15} />
      </CustomCardWrapper>
    );
  }
};

export default DeviceQCStatusWidget;
