import { get, post, del, put } from "aws-amplify/api";

const DeviceGet = async ({ deviceId, pullMetrics = false }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "device",
      options: {
        queryParams: { deviceId, pullMetrics },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceGetAll = async () => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "device/all",
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceCreate = async ({ body: reqBody }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "device",
      options: {
        body: reqBody,
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceDelete = async ({ deviceId }) => {
  try {
    const { body } = await del({
      apiName: "boreas",
      path: "device",
      options: {
        queryParams: { deviceId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const GetFirmwareList = async () => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "device/firmware/list",
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceCalibrationStart = async ({ body: reqBody }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "device/calibration",
      options: {
        body: reqBody,
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceCalibrationGetStepComplete = async ({
  deviceCalibrationId,
  stepId,
  verifyThermometerRoomTemp,
}) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "device/calibration/step-complete",
      options: {
        queryParams: {
          deviceCalibrationId,
          stepId,
          verifyThermometerRoomTemp,
        },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceCalibrationUpdate = async ({ deviceCalibrationId, action }) => {
  try {
    const { body } = await put({
      apiName: "boreas",
      path: "device/calibration",
      options: {
        body: { deviceCalibrationId, action },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceConnectivityTestStart = async ({ deviceId }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "device/connectivity-test",
      options: {
        body: { deviceId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceGetQualityCertificate = async ({ deviceId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "device/quality-certificate",
      options: {
        queryParams: {
          deviceId,
        },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceGetConnectivityTests = async ({ deviceId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "device/connectivity-tests",
      options: {
        queryParams: {
          deviceId,
        },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const DeviceGetCalibrations = async ({ deviceId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "device/calibrations",
      options: {
        queryParams: {
          deviceId,
        },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const Methods = {
  DeviceGet,
  DeviceGetAll,
  DeviceCreate,
  DeviceDelete,
  DeviceCalibrationStart,
  DeviceCalibrationGetStepComplete,
  GetFirmwareList,
  DeviceCalibrationUpdate,
  DeviceConnectivityTestStart,
  DeviceGetQualityCertificate,
  DeviceGetConnectivityTests,
  DeviceGetCalibrations,
};

export default Methods;
