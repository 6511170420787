// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import colors from "../../theme/colors";

// Conatiner Component Imports
import { Button, Card } from "@mui/material";
import Table from "../../components/Table";
import TableHeader from "../../components/Page/tableHeader";

// Page Component Imports
import { Link, useParams } from "react-router-dom";
import SparklineComponent from "../../components/HighCharts/sparkline";

const ActiveAlarmsTagNone = styled.div`
  background: ${colors.grayLight};
  color: ${colors.grayDark};
  border: 1px solid ${colors.gray};
  border-radius: 15px;
  text-align: center;
  padding: 2px;
  width: 60px;
`;

const ActiveAlarmsTagSome = styled.div`
  background: ${colors.redLightError};
  color: ${colors.redError};
  border: 1px solid ${colors.redError};
  border-radius: 15px;
  text-align: center;
  padding: 2px;
  width: 60px;
  font-weight: bold;
`;

const StatusTag = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  &:before {
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background: ${(props) => props.$statusColor};
  }
`;

const OrganizationTanksContainer = ({ locationId }) => {
  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);

  const [tankTableRows, setTankTableRows] = useState([]);
  const [loadingTanks, setLoadingTanks] = useState(true);
  const [getData, setGetData] = useState(true);

  const dispatch = useDispatch();

  const { organizationId } = useParams();

  const tankTableColumns = [
    {
      title: "Tank Name",
      propertyName: "name",
      sortProperty: "name",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/tanks/${row.id}`;
      },
    },
    {
      title: "Weight Trend 24h",
      propertyName: "weightTrend",
      sortProperty: "currentWeightKgSort",
      sortPropertyType: "number",
      render: (row) => {
        return (
          <SparklineComponent seriesData={row.weightTrend24hrSeriesData} />
        );
      },
    },
    {
      title: "Alarms",
      propertyName: "activeAlarmsCount",
      sortProperty: "activeAlarmsCount",
      sortPropertyType: "number",
      sortDefault: "desc",
      render: (row) => {
        if (row.activeAlarmsCount === 0) {
          return <ActiveAlarmsTagNone>none</ActiveAlarmsTagNone>;
        } else {
          return (
            <ActiveAlarmsTagSome>{row.activeAlarmsCount}</ActiveAlarmsTagSome>
          );
        }
      },
    },
    {
      title: "Current Weight (kg)",
      propertyName: "currentWeightKg",
      sortProperty: "currentWeightKgSort",
      sortPropertyType: "number",
    },
    {
      title: "Full Weight (kg)",
      propertyName: "fullWeightKG",
      sortProperty: "fullWeightKG",
      sortPropertyType: "number",
    },
    {
      title: "Low Weight (kg)",
      propertyName: "alarmLowLevelWeightKg",
      sortProperty: "alarmLowLevelWeightKg",
      sortPropertyType: "number",
    },
    {
      title: "Battery %",
      propertyName: "batteryPercentageDisplay",
      sortProperty: "batteryPercentage",
      sortPropertyType: "number",
    },
    {
      title: "Location",
      propertyName: "locationName",
      sortProperty: "locationName",
      sortPropertyType: "string",
      navigate: (row) => {
        return `/${organizationId}/locations/${row.locationId}`;
      },
    },
    {
      title: "Type",
      propertyName: "typeDisplayName",
      sortProperty: "typeDisplayName",
      sortPropertyType: "string",
    },
    {
      title: "Contents",
      propertyName: "contentsDisplayName",
      sortProperty: "contentsDisplayName",
      sortPropertyType: "string",
    },
    {
      title: "Status",
      propertyName: "status",
      sortProperty: "status",
      sortPropertyType: "string",
      render: (row) => {
        let color = colors.greenSuccess;
        if (row.status === "Maintenance") {
          color = colors.orange;
        } else if (row.status === "Deactivated") {
          color = colors.redError;
        }
        return <StatusTag $statusColor={color}>{row.status}</StatusTag>;
      },
    },
    {
      title: "Install Date",
      propertyName: "installDateString",
      sortProperty: "installDate",
      sortPropertyType: "number",
    },
    {
      title: "Tank ID",
      propertyName: "id",
      sortProperty: "id",
      sortPropertyType: "string",
    },
    {
      title: "Scale ID",
      propertyName: "scaleId",
      sortProperty: "scaleId",
      sortPropertyType: "string",
    },
  ];

  useEffect(() => {
    if (organizationId && userProfile.id && getData) {
      setGetData(false);
      dataLayer.GetOrganizationTanks({ organizationId });
    }
  }, [organization, userProfile]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "Tanks",
          link: "/tanks",
        },
      ])
    );
  }, [organization]);

  const dataLayer = {
    GetOrganizationTanks: async ({ organizationId }) => {
      setLoadingTanks(true);
      let tanks = await API.Tank.TanksGet({ organizationId });
      if (locationId) {
        tanks = tanks.filter((t) => t.locationId === locationId);
      }
      tanks.sort(
        (a, b) =>
          b.weightTrend24hrSeriesData.length -
          a.weightTrend24hrSeriesData.length
      );
      const tankRows = tanks.map((tank) => {
        return {
          ...tank,
          status: (() => {
            if (tank.deleted) {
              return "Deactivated";
            } else if (tank.serviceStatus === "active") {
              return "Active";
            } else if (tank.serviceStatus === "maintenance") {
              return "Maintenance";
            }
          })(),
          locationName: tank.location.name,
          activeAlarmsCount: tank.activeAlarmsCount,
          dateCreateString: new Date(tank.created).toLocaleString(),
          installDateString: new Date(tank.installDate).toLocaleDateString(),
          currentWeightKg: (() => {
            if (tank.weightTrend24hrSeriesData.length) {
              return tank.weightTrend24hrSeriesData[0][1];
            }
          })(),
          currentWeightKgSort: (() => {
            if (tank.weightTrend24hrSeriesData.length) {
              return tank.weightTrend24hrSeriesData[0][1];
            } else {
              return 0;
            }
          })(),
          batteryPercentageDisplay: tank.latestScaleData?.BatteryPercentage
            ? `${tank.latestScaleData?.BatteryPercentage}%`
            : "",
          batteryPercentage: tank.latestScaleData?.BatteryPercentage || "0",
        };
      });
      setTankTableRows(tankRows);
      setLoadingTanks(false);
    },
  };

  return (
    <Card>
      <TableHeader>
        <h2>Tanks</h2>
        <Button
          component={Link}
          to={`/${organization?.id}/tanks`}
          variant="outlined"
          color="primary"
          size="small"
        >
          VIEW TANKS
        </Button>
      </TableHeader>
      <Table
        columns={tankTableColumns}
        rows={tankTableRows}
        loading={loadingTanks}
        placeholder="There are no tanks created yet"
      />
    </Card>
  );
};

export default OrganizationTanksContainer;
