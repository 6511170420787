import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import breadCrumbsReducer from "./breadCrumbsSlice";
import notificationsReducer from "./notificationsSlice";
import uxReducer from "./uxSlice";
import organizationReducer from "./organizationSlice";
import logger from "redux-logger";

export default configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  reducer: {
    organization: organizationReducer,
    user: userReducer,
    breadCrumbs: breadCrumbsReducer,
    notifications: notificationsReducer,
    ux: uxReducer,
  },
});
