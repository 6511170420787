import styled from "styled-components";
export default styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  h2 {
    font-weight: 500;
    font-size: 20px;
  }
`;
