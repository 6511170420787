import { Button, Card, TextField } from "@mui/material";
import styled from "styled-components";
import colors from "../../theme/colors";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import { Tooltip } from "@mui/material";
import { dateDiffToString, formatDateTimeStamp } from "../../utils/date";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { updateReloadOrganization } from "../../redux-store/uxSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import FormFieldErrorClass from "../../components/Forms/models/formFieldError";
import { ArrowRightIcon } from "../../theme/icons";

// Conatiner Component Imports
import ModalComponent from "../../components/Modal";
import ConfirmForm from "../../components/Forms/confirmForm";
import {
  InfoIcon,
  AddCommentIcon,
  CheckCircleIcon,
  HourglassTopIcon,
  AlarmIcon,
} from "../../theme/icons";

const ActionBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${colors.blue};
  padding: 10px 15px;
  border-radius: 8px;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
`;

const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ColumnsContainer = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    gap: 0;
  }
`;

const Column = styled.div`
  padding: 20px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Widget = styled(Card)`
  width: 100%;
  padding: 20px;
`;

const WidgetHeader = styled.div`
  font-size: 17px;
  font-weight: bold;
  border-bottom: 1px solid ${colors.gray};
  padding: 0 0 6px 0;
  margin: 0 0 6px 0;
`;

const WidgetSubHeader = styled.div`
  font-size: 12px;
  color: ${colors.gray};
`;

const WidgetHeaderContainer = styled.div`
  width: 100%;
`;

const WidgetBody = styled.div`
  font-size: 12px;
  color: ${colors.gray};
`;

const ActivityListWrapper = styled.div`
  overflow: auto;
  max-height: ${(props) => (props.$fullsize ? "100%" : "400px")};
`;

const WidgetValue = styled.div`
  font-size: 22px;
  font-weight: bold;
  &.color-red {
    color: ${colors.redError};
  }
  &.color-green {
    color: ${colors.greenSuccess};
  }
  a {
    text-decoration: none;
    color: ${colors.blue};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const AlarmDetailKey = styled.div`
  font-size: 12px;
`;

const AlarmDetailValue = styled.div`
  color: ${colors.black};
  font-size: 16px;
  margin-bottom: 12px;
`;

const ResolutionStatusContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 10px;
`;

const ResolutionStatusItem = styled.div`
  color: ${colors.gray};
  font-size: 16px;
  white-space: nowrap;
  &.current {
    font-weight: bold;
    color: ${colors.redError};
    &.resolved {
      color: ${colors.greenSuccess};
    }
  }
`;

const ResolutionStatusHeader = styled.div`
  color: ${colors.grayDark};
  font-size: 16px;
  font-weight: bold;
`;

const ResolutionStatusHeaderContainer = styled(FlexCenterContainer)`
  border-right: 1px solid ${colors.gray};
  padding: 0 8px 0 0;
  @media (max-width: 900px) {
    border: none;
  }
`;
const ResolvedDateTime = styled.div`
  color: ${colors.greenSuccess};
  font-weight: 500;
  font-size: 14px;
`;

const ActvityItemContainer = styled.div`
  display: flex;
  gap: 6px;
  font-size: 14px;
  border-bottom: 1px solid ${colors.grayLight};
  padding: 12px 0;
`;

const ActivityDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ActivityUsername = styled.div`
  color: ${colors.grayDark};
  font-size: 12px;
`;

const ActivityAction = styled.div`
  color: ${colors.grayDarkText};
  font-weight: bold;
`;

const ActivityBody = styled.div`
  color: ${colors.black};
`;

const ActivityDateTime = styled.div`
  color: ${colors.black};
  font-size: 12px;
`;

const ActivityIcon = styled.div`
  color: ${colors.gray};
  svg {
    font-size: 22px;
  }
`;

const ResStatusToolTip = (
  <div>
    The alarm resolution status displays what state the alarm is in currently,
    and is used to show at what stage the alarm is at in being resolved.
    <br />
    <br /> Not Started: The alarm has not yet been attended to and needs
    attention
    <br />
    <br />
    In Progress: Someone has started to work on resolving the alarm
    <br />
    <br />
    Resolved: The alarm has been marked 'resolved' by a user within the
    organization, and notifications will cease.
  </div>
);

const RenderActivityIcon = ({ icon }) => {
  switch (icon) {
    case "alarm":
      return <AlarmIcon sx={{ color: colors.orange }} />;
    case "info":
      return <InfoIcon />;
    case "comment":
      return <AddCommentIcon sx={{ color: colors.blueLight }} />;
    case "complete":
      return <CheckCircleIcon sx={{ color: colors.greenSuccess }} />;
    case "progress":
      return <HourglassTopIcon sx={{ color: colors.gray }} />;
    default:
      return <InfoIcon />;
  }
};

const OrganizationAlarmsContainer = () => {
  const { alarmId, organizationId } = useParams();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);

  const [loading, setLoading] = useState(true);
  const [alarm, setAlarm] = useState({});
  const [alarmInfoList, setAlarmInfoList] = useState([]);
  const [alarmType, setAlarmType] = useState({});
  const [activity, setActivity] = useState([]);

  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(new FormFieldErrorClass());
  const [savingComment, setSavingComment] = useState(false);
  const [savingCommentError, setSavingCommentError] = useState("");

  const [savingStatus, setSavingStatus] = useState(false);
  const [openConfirmStatusModal, setOpenConfirmStatusModal] = useState(false);
  const [savingStatusError, setSavingStatusError] = useState("");

  const [nextResolutionStatus, setNextResolutionStatus] = useState({});

  const [resolutionStatusList, setResolutionStatusList] = useState([
    {
      name: "Not Started",
      id: "pending",
      current: true,
      confirmText: "This alarm has yet to be acted on by an employee.",
    },
    {
      name: "In Progress",
      id: "progress",
      current: false,
      confirmText:
        "By placing this Alarm In Progress you are indicating you have began work to resolve the alarm.",
    },
    {
      name: "Resolved",
      id: "resolved",
      current: false,
      confirmText:
        "By marking this Alarm as Resolved you are indicating you have resolved the issue.",
    },
  ]);

  useEffect(() => {
    let curIndex = resolutionStatusList.findIndex((r) => r.current);
    let nextIndex =
      curIndex < resolutionStatusList.length - 1 ? curIndex + 1 : curIndex;
    setNextResolutionStatus(resolutionStatusList[nextIndex]);
  }, [resolutionStatusList]);

  useEffect(() => {
    if (alarm?.id) {
      const _resolutionStatusList = resolutionStatusList.map((i) => {
        i.current = i.id === alarm.resolutionStatus;
        return i;
      });
      setResolutionStatusList(_resolutionStatusList);
    }
  }, [alarm]);

  const dataLayer = {
    GetAlarm: async () => {
      setLoading(true);
      const { alarm: _alarm, alarmInfoList: _alarmInfoList } =
        await API.Alarm.AlarmGet({ alarmId });

      const endDate = _alarm.resolvedDate || new Date().getTime();

      _alarm.timeActive = dateDiffToString(endDate, _alarm.created);

      setAlarm(_alarm);
      setAlarmType(
        _alarmInfoList.find((a) => a.alarmType === _alarm.alarmType)
      );
      setAlarmInfoList(_alarmInfoList);
      setLoading(false);
    },
    GetAlarmActivity: async () => {
      const _activity = await API.Activity.ActivityGet({
        resource: "alarm",
        resourceId: alarmId,
      });
      _activity.forEach((item) => {
        item.display = {};

        //Set Display Info
        switch (item.action) {
          case "alarm_create":
            item.display = {
              icon: "alarm",
              action: "Alarm Created",
              userName: item.userName,
              body: "Alarm Triggered",
            };
            break;
          case "alarm_comment_create":
            item.display = {
              icon: "comment",
              action: "Alarm Comment",
              userName: item.userName,
              body: item.data.comment,
            };
            break;
          case "alarm_resolution_status_update":
            item.display = {
              icon: "progress",
              action: "Resolution Status Updated",
              body: "Marked Alarm as In Progress",
              userName: item.userName,
            };
            switch (item.data.resolutionStatus) {
              case "progress":
                item.display.body = "Marked Alarm as In Progress";
                item.display.icon = "progress";
                break;
              case "resolved":
                item.display.body = "Marked Alarm as Resolved";
                item.display.icon = "complete";
                break;
              default:
                break;
            }
            break;
          default:
            item.display = {
              icon: "alarm",
              action: item.action,
              userName: item.userName,
              body: JSON.stringify(item.data),
            };
            break;
        }
      });
      setActivity(_activity);
    },
    PostAlarmActivityComment: async () => {
      setSavingCommentError("");
      setSavingComment(true);
      try {
        const _comment = await API.Alarm.AlarmPostComment({
          alarmId,
          comment,
        });
        setComment("");
        //Reload Data
        dataLayer.GetAlarmActivity();
        //Create Success Notification
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Comment Added",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingCommentError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingComment(false);
    },
    UpdateAlarmResolutionStatus: async () => {
      setSavingStatusError("");
      setSavingStatus(true);
      try {
        const _status = await API.Alarm.AlarmUpdateResolutionStatus({
          alarmId,
          status: nextResolutionStatus.id,
        });

        //Reload Data
        dataLayer.GetAlarm();
        dataLayer.GetAlarmActivity();
        dispatch(updateReloadOrganization(true));

        //Create Success Notification
        setOpenConfirmStatusModal(false);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Alarm Status successfully updated",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      } catch (err) {
        setSavingStatusError(err);
        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error",
            message: err,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingStatus(false);
    },
  };

  useEffect(() => {
    dataLayer.GetAlarm();
    dataLayer.GetAlarmActivity();
  }, []);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "Alarms",
          link: "/alarms",
        },
        {
          title: "Alarm Details",
          link: `/alarms/${alarmId}`,
        },
      ])
    );
  }, [organization]);

  const validate = {
    comment: async (value) => {
      setCommentError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setCommentError({
          hasError: true,
          message: "Comment Text Required",
        });
        return;
      }
      if (value.length > 1000) {
        setCommentError({
          hasError: true,
          message: "Comment Max 1000 Charaters",
        });
        return;
      }
    },
  };

  const handlers = {
    commentChange: async (e) => {
      const { value } = e.target;
      validate.comment(value);
      setComment(value);
    },
    commentSubmit: async () => {
      validate.comment(comment);
      if (commentError.hasError) {
        return;
      }
      const _res = await dataLayer.PostAlarmActivityComment();
    },
    resolutionStatusButtonClick: async () => {
      setOpenConfirmStatusModal(true);
    },
    confirmResolutionStatusUpdate: async () => {
      const _res = await dataLayer.UpdateAlarmResolutionStatus();
    },
    cancelResolutionStatusUpdate: async () => {
      setOpenConfirmStatusModal(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <ActionBarContainer>
        <ResolutionStatusHeaderContainer>
          <Tooltip enterTouchDelay={10} title={ResStatusToolTip}>
            <FlexCenterContainer style={{ cursor: "pointer" }}>
              <ResolutionStatusHeader>Resolution Status</ResolutionStatusHeader>
              <InfoIcon
                style={{
                  color: colors.grayDark,
                  fontSize: "14px",
                  margin: "0 0 0 4px",
                }}
              />
            </FlexCenterContainer>
          </Tooltip>
        </ResolutionStatusHeaderContainer>
        {!loading && (
          <>
            <ResolutionStatusContainer>
              {resolutionStatusList.map((state, index) => {
                return (
                  <FlexCenterContainer key={`res${state.id}`}>
                    <ResolutionStatusItem
                      className={`${state.id} ${
                        state.current ? "current" : "not-current"
                      }`}
                    >
                      {state.name}
                    </ResolutionStatusItem>
                    {index < resolutionStatusList.length - 1 && (
                      <ArrowRightIcon
                        style={{
                          color: colors.gray,
                          margin: "0 0 0 4px",
                        }}
                      />
                    )}
                  </FlexCenterContainer>
                );
              })}
            </ResolutionStatusContainer>
            {alarm.resolvedDate ? (
              <ResolvedDateTime>
                ALARM RESOLVED AT{" "}
                {new Date(alarm.resolvedDate).toLocaleString()}
              </ResolvedDateTime>
            ) : (
              <Button
                onClick={handlers.resolutionStatusButtonClick}
                color="error"
                variant="contained"
              >
                Mark {nextResolutionStatus.name}
              </Button>
            )}
          </>
        )}
      </ActionBarContainer>
      <ColumnsContainer>
        <Column>
          <Widget>
            <WidgetSubHeader>ALARM STATUS</WidgetSubHeader>
            {!loading && (
              <WidgetValue
                className={
                  alarm.active === "true" ? "color-red" : "color-green"
                }
              >
                {alarm.active === "true" ? "ACTIVE" : "RESOLVED"}
              </WidgetValue>
            )}
            <div>
              {alarm.active === "true"
                ? "This alarm is currently active. To update the resolution status, use the button above to mark as IN PROGRESS or RESOLVED."
                : "The alarm has been RESOLVED. Please check alarm comments and activity log for further details."}
            </div>
          </Widget>
          <Widget>
            <WidgetSubHeader>TIME ACTIVE</WidgetSubHeader>
            <WidgetValue>{alarm.timeActive}</WidgetValue>
          </Widget>
          <Widget>
            <WidgetSubHeader>TANK</WidgetSubHeader>
            <WidgetValue>
              <Link to={`/${organizationId}/tanks/${alarm?.tankId}`}>
                {alarm?.tank?.name}
              </Link>
            </WidgetValue>
          </Widget>
          <Widget>
            <WidgetSubHeader>LOCATION</WidgetSubHeader>
            <WidgetValue>
              <Link to={`/${organizationId}/locations/${alarm?.locationId}`}>
                {alarm?.location?.name}
              </Link>
            </WidgetValue>
          </Widget>
        </Column>
        <Column>
          <Widget>
            <WidgetHeaderContainer>
              <WidgetHeader>Alarm Details</WidgetHeader>
              <WidgetBody>
                <AlarmDetailKey>TYPE OF ALARM</AlarmDetailKey>
                <AlarmDetailValue>{alarmType.displayName}</AlarmDetailValue>
                <AlarmDetailKey>TIME OF ALARM</AlarmDetailKey>
                <AlarmDetailValue>
                  {alarm.created && new Date(alarm.created).toLocaleString()}
                </AlarmDetailValue>
                <AlarmDetailKey>TIME ACTIVE</AlarmDetailKey>
                <AlarmDetailValue>{alarm.timeActive}</AlarmDetailValue>
                <AlarmDetailKey>ALARM DESCRIPTION</AlarmDetailKey>
                <AlarmDetailValue>{alarmType.description}</AlarmDetailValue>
              </WidgetBody>
            </WidgetHeaderContainer>
            <WidgetBody></WidgetBody>
          </Widget>
        </Column>
        <Column>
          <Widget>
            <WidgetHeaderContainer>
              <WidgetHeader>Activity Log</WidgetHeader>
              <WidgetBody>
                <ActivityListWrapper>
                  {activity.map((item) => {
                    return (
                      <ActvityItemContainer key={item.id}>
                        <ActivityIcon>
                          <RenderActivityIcon icon={item.display.icon} />
                        </ActivityIcon>
                        <ActivityDetailsContainer>
                          <ActivityAction>{item.display.action}</ActivityAction>
                          <ActivityUsername>
                            {item.display.userName}
                          </ActivityUsername>
                          <ActivityBody>{item.display.body}</ActivityBody>
                          <ActivityDateTime>
                            {formatDateTimeStamp(item.created)}
                          </ActivityDateTime>
                        </ActivityDetailsContainer>
                      </ActvityItemContainer>
                    );
                  })}
                </ActivityListWrapper>

                <TextField
                  sx={{ width: "100%", margin: "10px 0px" }}
                  id="alarm-comment"
                  label="Add Comment"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={comment}
                  onChange={handlers.commentChange}
                  helperText={commentError.message}
                  error={commentError.hasError}
                />
                {savingComment ? (
                  <Button disabled variant="contained">
                    Saving&#8230;
                  </Button>
                ) : (
                  <Button onClick={handlers.commentSubmit} variant="contained">
                    Add Comment
                  </Button>
                )}
                <div>{savingCommentError}</div>
              </WidgetBody>
            </WidgetHeaderContainer>
          </Widget>
        </Column>
      </ColumnsContainer>

      <ModalComponent
        headerText={`Confirm Mark ${nextResolutionStatus.name}`}
        open={openConfirmStatusModal}
      >
        <ConfirmForm
          handleSave={handlers.confirmResolutionStatusUpdate}
          handleCancel={handlers.cancelResolutionStatusUpdate}
          loading={savingStatus}
          error={savingStatusError}
        >
          <div>
            <b>
              Are you sure you want to place this Alarm{" "}
              {nextResolutionStatus.name}?
            </b>
            <p>{nextResolutionStatus.confirmText}</p>
          </div>
        </ConfirmForm>
      </ModalComponent>
    </>
  );
};

export default OrganizationAlarmsContainer;
