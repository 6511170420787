// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import colors from "../../theme/colors";
import { FullPageFormWrapper } from "../../components/Forms/shared";

// Conatiner Component Imports
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";

// Page Component Imports
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CircularProgress,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";

import { InfoIcon } from "../../theme/icons";
import styled from "styled-components";

import TankModuleButtonLayoutImage from "./images/boreas_tank_module_button_layout.png";
import WeightPlatform from "./images/boreas_weight_platform.jpeg";
import FormFieldErrorClass from "../../components/Forms/models/formFieldError.js";
import { updateDeviceConnectivityTestModal } from "../../redux-store/uxSlice.js";
import DeviceConnectivityTestModal from "./deviceConnectivityTestModal.js";

const CalibrationWizardWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;

const CalibrationWizardContainer = styled(Card)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: ${colors.white};
  min-height: 400px;
  max-width: 1080px;
  min-width: 1080px;
  height: 600px;
`;

const CalibrationWizardTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CalibrationWizardTitle = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  padding: 10px 25px;
  border-bottom: 1px solid ${colors.grayLight};
`;

const CalibrationWizardBody = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CalibrationWizardImage = styled.img`
  max-height: 150px;
`;

const CalibrationWizardImageWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const StepProgressContainer = styled.div`
  position: relative;
  width: 75px;
  justify-self: end;
`;

const StepProgressInnerLabel = styled.div`
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
`;

const CalibrationWizardHeader = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
`;

const CalibrationWizardBodyDirections = styled.div`
  margin: 10px 0;
  font-weight: bold;
  max-width: 600px;
  text-align: center;
`;

const CalibrationWizardWarningContainer = styled.div`
  display: flex;
  flexwrap: wrap;
  align-items: center;
  gap: 4px;
  border: 1px solid ${colors.yellow};
  background: ${colors.yellowLight};
  border-radius: 4px;
  padding: 8px;
`;

const CalibrationWizardRestartContainer = styled.div`
  font-size: 12px;
  color: ${colors.blue};
`;

const CalibrationWizardStepComponent = ({
  active,
  number,
  totalSteps,
  title,
  showLoading,
  autoCompleteEnabled,
  buttonHandler,
  buttonText,
  buttonDisabled,
  children,
  data,
}) => {
  let progressValue = ((number - 1) / totalSteps) * 100;
  if (progressValue === 0) {
    progressValue = 5;
  }
  if (active) {
    return (
      <CalibrationWizardContainer>
        <CalibrationWizardHeader>
          <div></div>
          <CalibrationWizardTitleWrapper>
            <CalibrationWizardTitle>
              {number && <span>{number}.</span>}
              {title}
            </CalibrationWizardTitle>
          </CalibrationWizardTitleWrapper>
          <StepProgressContainer>
            <CircularProgress
              size={75}
              thickness={2}
              variant="determinate"
              value={100}
              sx={{ color: colors.grayLight }}
            />
            <CircularProgress
              size={75}
              thickness={2}
              variant="determinate"
              value={progressValue}
              sx={{ color: colors.green, position: "absolute", left: 0 }}
            />
            <StepProgressInnerLabel>
              {number - 1}/{totalSteps} steps
              <br />
              completed
            </StepProgressInnerLabel>
          </StepProgressContainer>
        </CalibrationWizardHeader>
        <CalibrationWizardBody>
          <div>{children}</div>
          {data && <div>{data}</div>}
          {autoCompleteEnabled && (
            <div
              style={{
                fontSize: "12px",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                alignItems: "center",
                margin: "20px 0",
              }}
            >
              <LinearProgress sx={{ width: "100%" }} />
              <div>
                Waiting for response. This step will autocomplete when done.
              </div>
            </div>
          )}
          {buttonHandler && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "30px",
              }}
            >
              {showLoading ? (
                <Button disabled variant="contained">
                  <div>
                    {buttonText}
                    <LinearProgress sx={{ width: "100%" }} />
                  </div>
                </Button>
              ) : (
                <Button
                  disabled={buttonDisabled}
                  variant="contained"
                  onClick={buttonHandler}
                >
                  {buttonText}
                </Button>
              )}
            </div>
          )}
        </CalibrationWizardBody>
      </CalibrationWizardContainer>
    );
  } else return null;
};

const CalibrationWizardComplete = ({
  number,
  totalSteps,
  buttonHandler,
  buttonText,
}) => {
  let progressValue = (number / totalSteps) * 100;
  if (progressValue === 0) {
    progressValue = 5;
  }
  return (
    <CalibrationWizardContainer>
      <CalibrationWizardHeader>
        <div></div>
        <CalibrationWizardTitleWrapper>
          <CalibrationWizardTitle>Success</CalibrationWizardTitle>
        </CalibrationWizardTitleWrapper>
        <StepProgressContainer>
          <CircularProgress
            size={75}
            thickness={2}
            variant="determinate"
            value={100}
            sx={{ color: colors.grayLight }}
          />
          <CircularProgress
            size={75}
            thickness={2}
            variant="determinate"
            value={progressValue}
            sx={{ color: colors.green, position: "absolute", left: 0 }}
          />
          <StepProgressInnerLabel>
            {number}/{totalSteps} steps
            <br />
            completed
          </StepProgressInnerLabel>
        </StepProgressContainer>
      </CalibrationWizardHeader>
      <CalibrationWizardBody>
        <div style={{ textAlign: "center" }}>
          <div>
            <b>
              Your device has been successfully calibrated. <br />
              You may exit this page or continue to run the device connectivity
              test below.
            </b>
          </div>
        </div>
        {buttonHandler && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px",
            }}
          >
            <Button variant="contained" onClick={buttonHandler}>
              {buttonText}
            </Button>
          </div>
        )}
      </CalibrationWizardBody>
    </CalibrationWizardContainer>
  );
};

const CalibrateDevice = () => {
  const { deviceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [device, setDevice] = useState({});
  const [wizardLoading, setWizardLoading] = useState(false);
  const [deviceCalibration, setDeviceCalibration] = useState({});
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [startCalibration, setStartCalibration] = useState(false);

  const [weightSerial, setWeightSerial] = useState("WS-002 (#3783780)");
  const [weightSerialsList, setWeightSerialsList] = useState([
    "WS-001 (#3624828)",
    "WS-002 (#3783780)"
  ]);

  const [thermometerSerial, setThermometerSerial] = useState("TS-002 (#3077)");
  const [thermometerSerialsList, setThermometerSerialsList] = useState([
    "TS-001 (#2997)",
    "TS-002 (#3077)",
  ]);
  const [weighingPlatformSerial, setWeighingPlatformSerial] = useState("");
  const [weighingPlatformSerialError, setWeighingPlatformSerialError] =
    useState(new FormFieldErrorClass());

  const [roomTemp, setRoomTemp] = useState("");
  const [roomTempError, setRoomTempError] = useState(new FormFieldErrorClass());

  const [calibrationComplete, setCalibrationComplete] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [showRestartModal, setShowRestartModal] = useState(false);

  //Polling Setup
  const [dataInterval, setDataInterval] = useState();
  let refreshIntervalSeconds = 2;
  const [pollingStarted, setPollingStarted] = useState(false);
  const [nextRefreshInSeconds, setNextRefreshInSeconds] = useState(
    refreshIntervalSeconds
  );

  useEffect(() => {
    dataLayer.GetDevice();
    dispatch(
      setBreadCrumbs([
        {
          title: "Admin Devices",
          link: "/admin/devices",
        },
        {
          title: "...",
          link: "",

        },
        {
          title: "Calibrate",
          link: "",
        },
      ])
    );

    function closeStop(e) {
      e.preventDefault();
    }
    //Stop user from closing tab/window without confirming (this will not stop clicking into new pages in the site)
    window.addEventListener("beforeunload", closeStop);
    return (e) => {
      window.removeEventListener("beforeunload", closeStop);
    };
  }, []);

  useEffect(()=>{
    dispatch(
      setBreadCrumbs([
        {
          title: "Admin Devices",
          link: "/admin/devices",
        },
        {
          title: device?.name,
          link: `/admin/devices/${device.id}`,
        },
        {
          title: "Calibrate",
          link: "",
        },
      ])
    );
  },[device])

  useEffect(() => {
    if (!pollingStarted) {
      PollData();
      setPollingStarted(true);
    }
    //Clear time on unmount
    return () => {
      setPollingStarted(false);
      clearInterval(dataInterval);
    };
  }, []);

  useEffect(() => {
    async function _fetchData(seconds) {
      if (seconds === 0) {
        await FetchData();
        setNextRefreshInSeconds(refreshIntervalSeconds);
      }
    }

    _fetchData(nextRefreshInSeconds);
  }, [nextRefreshInSeconds]);

  useEffect(() => {
    setFormValid(
      !weighingPlatformSerialError.hasError && weighingPlatformSerial
    );
  }, [weighingPlatformSerialError, weighingPlatformSerial]);

  useEffect(() => {
    if (startCalibration) {
      setStartCalibration(false);
      handlers.startCalibration();
    }
  }, [startCalibration]);

  const PollData = async () => {
    clearInterval(dataInterval);
    const d = setInterval(() => {
      setNextRefreshInSeconds((s) => s - 1);
    }, 1000);
    setDataInterval(d);
  };

  const FetchData = async () => {
    //get active step
    const index = calibrationSteps.findIndex((i) => i.active);
    const activeStep = calibrationSteps[index];
    console.log(`activeStep: ${activeStep.id}`);

    if (activeStep?.autoCompleteEnabled) {
      //Send API Request
      const res = await dataLayer.DeviceCalibrationGetStepComplete({
        stepId: activeStep.id,
        deviceCalibrationId: deviceCalibration.id,
      });
      handlers.setStepData({ data: res?.data || "" });
      if (res?.isComplete) {
        handlers.next();
      }
    }
    return;
  };
  //END Polling

  const dataLayer = {
    GetDevice: async () => {
      const _device = await API.Device.DeviceGet({ deviceId });
      setDevice(_device);
    },
    CalibrationStart: async () => {
      setWizardLoading(true);
      const body = {
        deviceId: deviceId,
        weightSerial,
        thermometerSerial,
        weighingPlatformSerial: `WP-${weighingPlatformSerial}`,
      };
      const _deviceCalibration = await API.Device.DeviceCalibrationStart({
        body,
      });
      setWizardLoading(false);
      setDeviceCalibration(_deviceCalibration);
    },
    DeviceCalibrationGetStepComplete: async ({
      deviceCalibrationId,
      stepId,
    }) => {
      try {
        const _stepCompleteRes =
          await API.Device.DeviceCalibrationGetStepComplete({
            deviceCalibrationId,
            stepId,
            verifyThermometerRoomTemp: roomTemp,
          });
        console.log("stepCompleteRes", _stepCompleteRes);
        return _stepCompleteRes;
      } catch (err) {
        console.log("api-error", err);
        return;
      }
    },
    DeviceCalibrationFailed: async () => {
      setLoading(true);
      await API.Device.DeviceCalibrationUpdate({
        deviceCalibrationId: deviceCalibration.id,
        action: "failed",
      });
      setLoading(false);
    },
  };

  const handlers = {
    weighingPlatformSerialChange: async ({ target }) => {
      const { value } = target;
      validate.weighingPlatformSerial(value);
      setWeighingPlatformSerial(value);
    },
    weightSetSerialChange: async ({ target }) => {
      const { value } = target;
      setWeightSerial(value);
    },
    thermometerSerialChange: async ({ target }) => {
      const { value } = target;
      setThermometerSerial(value);
    },
    roomTempChange: ({ target }) => {
      const { value } = target;
      validate.roomTemp(value);
      setRoomTemp(value);
    },
    //Restart Functions
    openRestartCalibrationModal: async () => {
      setShowRestartModal(true);
    },
    closeRestartCalibrationModal: async () => {
      setShowRestartModal(false);
    },
    saveRestartCalibration: async () => {
      const _calibrationSteps = [...calibrationSteps];
      _calibrationSteps.forEach((step, index) => {
        step.active = index === 0;
        step.isComplete = false;
      });
      setCalibrationSteps(_calibrationSteps);
      setDeviceCalibration({});
      handlers.closeRestartCalibrationModal();
    },
    //Failed Functions
    openFailedCalibrationModal: async () => {
      setShowFailedModal(true);
    },
    closeFailedCalibrationModal: async () => {
      setShowFailedModal(false);
    },
    saveFailedCalibration: async () => {
      await dataLayer.DeviceCalibrationFailed();
      navigate("/admin/devices");
    },
    startDeviceConnectivityTest: async () => {
      dispatch(
        updateDeviceConnectivityTestModal({
          show: true,
          device,
        })
      );
    },
    startCalibrationClick: async () => {
      setStartCalibration(true);
    },
    startCalibration: async () => {
      await dataLayer.CalibrationStart();
      handlers.next();
    },
    next: async () => {
      const _calibrationSteps = [...calibrationSteps];
      const i = _calibrationSteps.findIndex((i) => i.active);
      if (i + 1 >= calibrationSteps.length) {
        setCalibrationComplete(true);
      }
      const nextIndex = i + 1 >= calibrationSteps.length ? 0 : i + 1;
      _calibrationSteps.forEach((step, index) => {
        step.active = index === nextIndex;
      });
      setCalibrationSteps(_calibrationSteps);
      handlers.setStepData("");
    },
    setStepData: async ({ data }) => {
      const _calibrationSteps = [...calibrationSteps];
      const i = _calibrationSteps.findIndex((i) => i.active);
      _calibrationSteps[i].data = data;
      setCalibrationSteps(_calibrationSteps);
    },
  };

  const validate = {
    weighingPlatformSerial: (value) => {
      setWeighingPlatformSerialError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setWeighingPlatformSerialError({
          hasError: true,
          message: "Weighing Platform Serial Required",
        });
      } else if (value.length < 4 || value.length > 4) {
        setWeighingPlatformSerialError({
          hasError: true,
          message: "Value must be exactly 4 digits",
        });
      }
    },
    roomTemp: (value) => {
      setRoomTempError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setRoomTempError({
          hasError: true,
          message: "Room Temp Required",
        });
      }
    },
  };

  const [calibrationSteps, setCalibrationSteps] = useState([
    {
      id: "01-cal-start",
      isComplete: false,
      active: true,
      title: "Start Device Calibration",
      buttonHandler: handlers.startCalibrationClick,
      buttonText: "START CALIBRATION",
    },
    {
      id: "02-cal-device-mode",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Place Device In Calibration Mode",
    },
    {
      id: "03-cal-tare-wp",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Tare the Weight Platform",
    },
    {
      id: "04-cal-load-60",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Configure 60kg Load",
    },
    {
      id: "05-cal-verify-mode",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Place Device In Verification Mode",
    },
    {
      id: "06-cal-verify-60",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Verify 60kg",
    },
    {
      id: "07-cal-verify-30",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Verify 30kg",
    },
    {
      id: "08-cal-verify-05",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Verify 0.5kg",
    },
    {
      id: "09-cal-drift-test",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Drift Test",
    },
    {
      id: "10-cal-therm-check",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Check Thermistor",
    },
    {
      id: "11-cal-low-weight-alarm",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Verify Low Weight Alarm",
    },
    {
      id: "12-cal-charging",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Verify Device Charges",
    },
    {
      id: "13-cal-save-exit-mode",
      active: false,
      isComplete: false,
      autoCompleteEnabled: true,
      title: "Save Calibration Setting and Exit Calibration Mode",
    },
  ]);

  const wizardActiveStepIndex = calibrationSteps.findIndex((s) => s.active);
  const wizardActiveStep = calibrationSteps[wizardActiveStepIndex];

  return (
    <>
      <BreadCrumbs />
      <FullPageFormWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>
            Device Calibration{" "}
            <span style={{ color: colors.gray }}> / {device.name} </span>
          </h2>
          {wizardActiveStepIndex !== 0 && !calibrationComplete && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Button
                onClick={handlers.openFailedCalibrationModal}
                variant="contained"
                color="error"
              >
                Mark Calibration as Failed
              </Button>
              <CalibrationWizardRestartContainer>
                Experiencing issues?{" "}
                <Link onClick={handlers.openRestartCalibrationModal}>
                  Restart Calibration
                </Link>
              </CalibrationWizardRestartContainer>
            </div>
          )}
        </div>

        {device.id ? (
          <>
            <CalibrationWizardWrapper>
              {calibrationComplete ? (
                <CalibrationWizardComplete
                  number={calibrationSteps.length}
                  totalSteps={calibrationSteps.length}
                  buttonHandler={handlers.startDeviceConnectivityTest}
                  buttonText="Start Device Connectivity Test"
                />
              ) : (
                <>
                  {wizardActiveStep.id === "01-cal-start" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      showLoading={wizardLoading}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                      buttonDisabled={!formValid}
                    >
                      <div style={{ fontSize: "14px", color: colors.gray }}>
                        Calibrating a device configures the weight platform and
                        thermistor to ensure it properly reports tank weight and
                        external temperature.
                      </div>
                      <h5 style={{ margin: "8px 0 0 0", padding: "0" }}>
                        Materials Needed:
                      </h5>
                      <ul style={{ fontSize: "12px" }}>
                        <li>
                          Fully assembled and powered on device (tank module,
                          thermistor, weight platform, battery, SIM)
                        </li>
                        <li>Device power supply</li>
                        <li>60kg, 30kg, and 0.5kg of weights</li>
                        <li>NIST certified thermometer</li>
                      </ul>
                      <h5>
                        Set Weighing Platform, Weight Set, and Thermostat:
                      </h5>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "20px",
                          margin: "20px 0",
                        }}
                      >
                        <TextField
                          focused
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                WP -
                              </InputAdornment>
                            ),
                          }}
                          id="cal-wp-serial"
                          variant="outlined"
                          label="Weighing Platform (required)"
                          value={weighingPlatformSerial}
                          error={weighingPlatformSerialError.hasError}
                          helperText={weighingPlatformSerialError.message}
                          onChange={handlers.weighingPlatformSerialChange}
                        />
                        <TextField
                          id="cal-weights-selector"
                          select
                          variant="outlined"
                          label="Weight Set Serial"
                          value={weightSerial}
                          // helperText={desiredFirmwareVersionError.message}
                          // error={desiredFirmwareVersionError.hasError}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={handlers.weightSetSerialChange}
                        >
                          {weightSerialsList.map((item) => {
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </TextField>

                        <TextField
                          id="cal-thermostat-selector"
                          select
                          variant="outlined"
                          label="Thermostat Serial"
                          value={thermometerSerial}
                          // helperText={desiredFirmwareVersionError.message}
                          // error={desiredFirmwareVersionError.hasError}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={handlers.thermometerSerialChange}
                        >
                          {thermometerSerialsList.map((item) => {
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </TextField>
                      </div>

                      <CalibrationWizardWarningContainer>
                        <InfoIcon
                          sx={{ color: colors.yellow, fontSize: "18px" }}
                        />{" "}
                        <b>Note:</b> Please do not refresh or close this window
                        until the calibration process is complete.
                      </CalibrationWizardWarningContainer>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "02-cal-device-mode" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Power device on and Press the second red button (R2)
                          on your device labeled “cal”
                        </CalibrationWizardBodyDirections>
                        <CalibrationWizardImageWrapper>
                          <CalibrationWizardImage
                            src={TankModuleButtonLayoutImage}
                            alt=""
                          />
                        </CalibrationWizardImageWrapper>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "03-cal-tare-wp" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardWarningContainer>
                          <InfoIcon
                            sx={{ color: colors.yellow, fontSize: "18px" }}
                          />{" "}
                          <b>Note:</b> Remove shipping shim and screws from
                          weight platform
                        </CalibrationWizardWarningContainer>
                        <CalibrationWizardBodyDirections>
                          Remove any weight from the weighing platform and press
                          the first black button (B1) on your device.
                        </CalibrationWizardBodyDirections>
                        <CalibrationWizardImageWrapper>
                          <CalibrationWizardImage
                            src={TankModuleButtonLayoutImage}
                            alt=""
                          />
                        </CalibrationWizardImageWrapper>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "04-cal-load-60" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Add 60kg of weight to the platform and press the
                          second black button (B2) on your device.
                        </CalibrationWizardBodyDirections>
                        <CalibrationWizardImageWrapper>
                          <CalibrationWizardImage
                            src={TankModuleButtonLayoutImage}
                            alt=""
                          />
                        </CalibrationWizardImageWrapper>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "05-cal-verify-mode" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Press the third black button (B3) on your device.
                        </CalibrationWizardBodyDirections>
                        <CalibrationWizardImageWrapper>
                          <CalibrationWizardImage
                            src={TankModuleButtonLayoutImage}
                            alt=""
                          />
                        </CalibrationWizardImageWrapper>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "06-cal-verify-60" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Place 60kg of weight on the platform. The device will
                          automatically report weight no need to press any
                          buttons.
                        </CalibrationWizardBodyDirections>
                        <CalibrationWizardImageWrapper>
                          <CalibrationWizardImage src={WeightPlatform} alt="" />
                        </CalibrationWizardImageWrapper>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "07-cal-verify-30" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Place 30kg of weight on the platform. The device will
                          automatically report weight no need to press any
                          buttons.
                        </CalibrationWizardBodyDirections>
                        <CalibrationWizardImageWrapper>
                          <CalibrationWizardImage src={WeightPlatform} alt="" />
                        </CalibrationWizardImageWrapper>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "08-cal-verify-05" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Place 0.5kg of weight on the platform. The device will
                          automatically report weight no need to press any
                          buttons.
                        </CalibrationWizardBodyDirections>
                        <CalibrationWizardImageWrapper>
                          <CalibrationWizardImage src={WeightPlatform} alt="" />
                        </CalibrationWizardImageWrapper>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "09-cal-drift-test" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Place 0.5kg of weight on the platform. The device will
                          automatically report weight no need to press any
                          buttons.
                        </CalibrationWizardBodyDirections>
                        <CalibrationWizardImageWrapper>
                          <CalibrationWizardImage src={WeightPlatform} alt="" />
                        </CalibrationWizardImageWrapper>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "10-cal-therm-check" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Using your NIST thermometer enter the room temperature
                          below.
                        </CalibrationWizardBodyDirections>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <TextField
                          id="room_temp_input"
                          variant="outlined"
                          label="Room Temperature C&deg;"
                          value={roomTemp}
                          helperText={roomTempError.message}
                          error={roomTempError.hasError}
                          onChange={handlers.roomTempChange}
                        />
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "11-cal-low-weight-alarm" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Remove all weight from platform and wait. This step
                          can take a couple minutes.
                        </CalibrationWizardBodyDirections>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "12-cal-charging" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Connect Device to Power Supply. This step may take a
                          few minutes. Please do not refresh.
                        </CalibrationWizardBodyDirections>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}

                  {wizardActiveStep.id === "13-cal-save-exit-mode" && (
                    <CalibrationWizardStepComponent
                      {...wizardActiveStep}
                      number={wizardActiveStepIndex + 1}
                      totalSteps={calibrationSteps.length}
                    >
                      <div>
                        <CalibrationWizardBodyDirections>
                          Press the second red button (R2) on your device
                          labeled “cal” to save the calibration settings and to
                          exit calibration mode.
                        </CalibrationWizardBodyDirections>
                        <CalibrationWizardImageWrapper>
                          <CalibrationWizardImage
                            src={TankModuleButtonLayoutImage}
                            alt=""
                          />
                        </CalibrationWizardImageWrapper>
                      </div>
                    </CalibrationWizardStepComponent>
                  )}
                </>
              )}
            </CalibrationWizardWrapper>
          </>
        ) : (
          <CircularProgress />
        )}
      </FullPageFormWrapper>
      <ModalComponent
        headerText="Mark Device as Failed Calibration"
        open={showFailedModal}
        handleClose={handlers.closeFailedCalibrationModal}
      >
        <div>
          Devices that will not pass one of the calibration steps after
          restarting the calibration you should mark it as failed. Devices that
          fail calibration will be flagged and reviewed for root cause.
        </div>
        <div
          style={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            margin: "20px 0 0 0",
          }}
        >
          <Button
            onClick={handlers.closeFailedCalibrationModal}
            variant="outlined"
          >
            Cancel
          </Button>

          {loading ? (
            <Button variant="contained" color="error">
              <div>
                Calibration Failed
                <LinearProgress sx={{ width: "100%" }} />
              </div>
            </Button>
          ) : (
            <Button
              onClick={handlers.saveFailedCalibration}
              variant="contained"
              color="error"
            >
              Calibration Failed
            </Button>
          )}
        </div>
      </ModalComponent>
      <ModalComponent
        headerText="Restart Device Calibration"
        open={showRestartModal}
        handleClose={handlers.closeRestartCalibrationModal}
      >
        <div>
          Restarting device calibration will discard any progress. Be sure to
          restart your device as well.
        </div>
        <div
          style={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            margin: "20px 0 0 0",
          }}
        >
          <Button
            onClick={handlers.closeRestartCalibrationModal}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handlers.saveRestartCalibration}
            variant="contained"
            color="error"
          >
            Restart Calibration
          </Button>
        </div>
      </ModalComponent>
      <DeviceConnectivityTestModal />
    </>
  );
};

export default CalibrateDevice;
