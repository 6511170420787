import { get, post, put, del } from "aws-amplify/api";

const OrganizationGet = async ({ organizationId }) => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "organization",
      options: {
        queryParams: { organizationId },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const OrganizationsGetAll = async () => {
  try {
    const { body } = await get({
      apiName: "boreas",
      path: "organizations",
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const OrganizationCreate = async ({ createOrganizationData }) => {
  try {
    const { body } = await post({
      apiName: "boreas",
      path: "organization",
      options: {
        body: createOrganizationData,
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const OrganizationUpdate = async ({ updateOrganizationData }) => {
  try {
    const { body } = await put({
      apiName: "boreas",
      path: "organization",
      options: {
        body: updateOrganizationData,
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const OrganizationDelete = async ({
  deleteOrganizationId,
  reactivate = 'false',
}) => {
  try {
    const { body } = await del({
      apiName: "boreas",
      path: "organization",
      options: {
        queryParams: { deleteOrganizationId, reactivate },
      },
    }).response;
    return body.json();
  } catch (err) {
    const errorMessage = JSON.parse(err.response.body).error;
    throw errorMessage;
  }
};

const Methods = {
  OrganizationGet,
  OrganizationsGetAll,
  OrganizationCreate,
  OrganizationUpdate,
  OrganizationDelete,
};

export default Methods;
