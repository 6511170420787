import styled from "styled-components";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import colors from "../../theme/colors";
import { useDispatch } from "react-redux";
import { updateShowSupportModal } from "../../redux-store/uxSlice";

const FooterContainer = styled.div`
  width: 100%;
  font-size: 11px;
  @media print {
    display: none !important;
  }
`;

const ContentArea = styled(Container)`
  display: flex !important;
  justify-content: space-between;
  padding: 20px 0;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
`;

const CopyRightContainer = styled.div`
  color: ${colors.grayDark};
  white-space: nowrap;
  flex: 1;
`;

const SupportLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  a {
    white-space: nowrap;
    text-decoration: none;
    color: ${colors.grayDark};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const PipeSpacer = styled.span`
  color: ${colors.grayDark};
  @media (max-width: 900px) {
    display: none;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const StyledHR = styled.hr`
  border: 0.5px solid #0000001f;
`;

const FooterComponent = () => {
  const dispatch = useDispatch();
  return (
    <FooterContainer>
      <Container maxWidth="xl">
        <StyledHR />
      </Container>
      <ContentArea maxWidth="xl">
        <CopyRightContainer>
          &copy; 2023 Boreas Cryo. All rights reserved.
        </CopyRightContainer>
        <SupportLinksContainer>
          <a target="_blank" href="/privacy-policy">
            Privacy Policy
          </a>
          <PipeSpacer>|</PipeSpacer>
          <a target="_blank" href="/terms-of-service">
            Terms of Service
          </a>
          <PipeSpacer>|</PipeSpacer>
          <a target="_blank" href="/knowledge-base">Knowledge Base</a>
          <PipeSpacer>|</PipeSpacer>
          <Link
            onClick={() => {
              dispatch(updateShowSupportModal(true));
            }}
          >
            Support
          </Link>
        </SupportLinksContainer>
        <Spacer></Spacer>
      </ContentArea>
    </FooterContainer>
  );
};

export default FooterComponent;
