// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import colors from "../../theme/colors";
import { FullPageFormWrapper } from "../../components/Forms/shared";

// Conatiner Component Imports
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";

// Page Component Imports
import AddDevicesForm from "../../components/Forms/adminDevicesForm";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmForm from "../../components/Forms/confirmForm";
import { NoChartDataPlaceholder } from "../../components/NoDataPlaceholder";
import { CircularProgress } from "@mui/material";

const AddDevice = () => {
  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const { organizationId } = useParams();

  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [firmwareList, setFirmwareList] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dataLayer.GetFirmwareList();
  }, []);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: "Admin Devices",
          link: "/admin/devices",
        },
        {
          title: "Add Device",
          link: "/admin/devices/add",
        },
      ])
    );
  }, []);

  const dataLayer = {
    GetFirmwareList: async () => {
      const _fwList = await API.Device.GetFirmwareList();
      setFirmwareList(_fwList);
    },
  };

  const handlers = {
    confirmModalSubmit: () => {
      navigate(`/admin/devices`);
    },
    confirmModalCancel: () => {
      setShowConfirmModal(false);
    },
    cancel: () => {
      setShowConfirmModal(true);
    },
    saveDevices: async ({ devices, desiredFirmwareVersion, boardType }) => {
      setSavingError("");
      setSaving(true);
      try {
        const data = {
          devices: devices.map((d) => {
            return { particleDeviceId: d.particleDeviceId };
          }),
          desiredFirmwareVersion: parseInt(desiredFirmwareVersion),
          groups: [boardType, "boreas_qc"],
          flash: true,
        };

        await API.Device.DeviceCreate({
          body: { ...data },
        });

        //Create Success Notification
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Devices created successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
        navigate(`/admin/devices`);
      } catch (err) {
        let errorMessage = err;
        switch (err) {
          case "particle-device-id-in-use-already":
            errorMessage =
              "Particle Device Id is already linked to a Device in the System";
            break;
          default:
            break;
        }

        setSavingError(errorMessage);

        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error Creating Devices",
            message: errorMessage,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSaving(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <FullPageFormWrapper>
        <h2>
          Add New Devices{" "}
          <span style={{ color: colors.gray }}> / Boreas Admin</span>
        </h2>
        {firmwareList.length ? (
          <AddDevicesForm
            handleSave={handlers.saveDevices}
            handleCancel={handlers.cancel}
            saving={saving}
            error={savingError}
            firmwareList={firmwareList}
          />
        ) : (
          <CircularProgress />
        )}
        {/* {locationsLoaded && locations.length === 0 && (
          <NoChartDataPlaceholder
            header={"THERE ARE NO LOCATIONS IN THIS ORGANIZATION"}
            body={"To add a tank, you must have at least one location created"}
            buttonText={"Add Location"}
            buttonPath={`/${organizationId}/locations`}
          />
        )} */}
      </FullPageFormWrapper>
      <ModalComponent
        open={showConfirmModal}
        handleClose={handlers.confirmModalCancel}
        headerText={"Cancel Add New Devices"}
      >
        <ConfirmForm
          handleSave={handlers.confirmModalSubmit}
          handleCancel={handlers.confirmModalCancel}
          loading={false}
        >
          <div>
            <b>Are you sure you want to Cancel Adding New Devices?</b>
            <p>You will lose all progress.</p>
          </div>
        </ConfirmForm>
      </ModalComponent>
    </>
  );
};

export default AddDevice;
