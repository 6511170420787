import { TextField, Button } from "@mui/material";
import FormFieldErrorClass from "./models/formFieldError";
import ErrorIcon from "@mui/icons-material/Error";
import { useState, useEffect } from "react";
import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";
import PhoneNumberInput from "../PhoneNumberInput";
import {
  FormContainer,
  FormSubmitError,
  ActionButtonsContainer,
  HeaderNote,
} from "./shared";
import states from "./shared/states";

const OrganizationLocationForm = ({
  handleCancel = () => {},
  handleSave = () => {},
  loading = false,
  error = "",
  location,
  isEdit = false,
}) => {
  const [save, setSave] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [nameError, setNameError] = useState(new FormFieldErrorClass());
  const [phoneError, setPhoneError] = useState(new FormFieldErrorClass());
  const [address1Error, setAddress1Error] = useState(new FormFieldErrorClass());
  const [address2Error, setAddress2Error] = useState(new FormFieldErrorClass());
  const [cityError, setCityError] = useState(new FormFieldErrorClass());
  const [stateError, setStateError] = useState(new FormFieldErrorClass());
  const [zipError, setZipError] = useState(new FormFieldErrorClass());
  const formFieldErrors = [
    nameError,
    phoneError,
    address1Error,
    address2Error,
    cityError,
    stateError,
    zipError,
  ];

  useEffect(() => {
    if (location) {
      setName(location.name);
      setPhone(location.phone);
      setAddress1(location.address1);
      setAddress2(location.address2 || "");
      setCity(location.city);
      setState(location.state);
      setZip(location.zip);
    }
  }, [location]);

  useEffect(() => {
    if (save) {
      setSave(false);
      if (formFieldErrors.filter((e) => e.hasError).length) {
        return;
      }

      //send data
      handleSave({
        name,
        phone,
        address1,
        address2,
        city,
        state,
        zip,
      });
    }
  }, [save, formFieldErrors]);

  const validate = {
    name: (value) => {
      setNameError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setNameError({
          hasError: true,
          message: "Location Name Required",
        });
        return;
      }
      if (value.length > 100) {
        setNameError({
          hasError: true,
          message: "Location Name Max 100 Characters",
        });
        return;
      }
    },
    phone: (value) => {
      setPhoneError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setPhoneError({
          hasError: true,
          message: "Phone Required",
        });
        return;
      }
      if (!isMobilePhone(value, ["en-US"])) {
        setPhoneError({
          hasError: true,
          message: "Phone Format Not Valid",
        });
        return;
      }
    },
    address1: (value) => {
      setAddress1Error({
        hasError: false,
        message: "",
      });
      if (!value) {
        setAddress1Error({
          hasError: true,
          message: "Street Address 1 Required",
        });
        return;
      }
      if (value.length > 100) {
        setAddress1Error({
          hasError: true,
          message: "Street Address 1 Max 100 Charaters",
        });
        return;
      }
    },
    address2: (value) => {
      setAddress2Error({
        hasError: false,
        message: "",
      });
      if (value.length > 100) {
        setAddress2Error({
          hasError: true,
          message: "Street Address 2 Max 100 Charaters",
        });
        return;
      }
    },
    city: (value) => {
      setCityError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setCityError({
          hasError: true,
          message: "City Required",
        });
        return;
      }
      if (value.length > 100) {
        setCityError({
          hasError: true,
          message: "City Max 100 Charaters",
        });
        return;
      }
    },
    state: (value) => {
      setStateError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setStateError({
          hasError: true,
          message: "State Required",
        });
        return;
      }
    },
    zip: (value) => {
      setZipError({
        hasError: false,
        message: "",
      });
      if (!value) {
        setZipError({
          hasError: true,
          message: "Zip Code Required",
        });
        return;
      }
      if (!isPostalCode(value, ["US"])) {
        setZipError({
          hasError: true,
          message: "Zip Code Format Not Valid",
        });
        return;
      }
    },
  };

  const handlers = {
    nameChange: (e) => {
      const value = e.target.value;
      validate.name(value);
      setName(e.target.value);
    },
    phoneChange: ({ formattedPhoneNumber, unformattedPhoneNumber }) => {
      validate.phone(unformattedPhoneNumber);
      setPhone(unformattedPhoneNumber);
    },
    address1Change: (e) => {
      const value = e.target.value;
      validate.address1(value);
      setAddress1(e.target.value);
    },
    address2Change: (e) => {
      const value = e.target.value;
      validate.address2(value);
      setAddress2(e.target.value);
    },
    cityChange: (e) => {
      const value = e.target.value;
      validate.city(value);
      setCity(e.target.value);
    },
    stateChange: (e) => {
      const value = e.target.value;
      validate.state(value);
      setState(e.target.value);
    },
    zipChange: (e) => {
      const value = e.target.value;
      validate.zip(value);
      setZip(e.target.value);
    },
    save: () => {
      //validate all
      validate.name(name);
      validate.phone(phone);
      validate.address1(address1);
      validate.address2(address2);
      validate.city(city);
      validate.state(state);
      validate.zip(zip);
      setSave(true);
    },
    cancel: () => {
      handleCancel();
    },
  };

  return (
    <>
      <FormContainer>
        <HeaderNote>
          All fields required (street address 2 is optional)
        </HeaderNote>
        <TextField
          id="location-name"
          label="Location Name"
          variant="outlined"
          value={name}
          onChange={handlers.nameChange}
          helperText={nameError.message}
          error={nameError.hasError}
        />
        <PhoneNumberInput
          id="phone-number"
          label="Phone Number"
          variant="outlined"
          value={phone}
          onChange={handlers.phoneChange}
          helperText={phoneError.message}
          error={phoneError.hasError}
          disabled={loading}
        />
        <TextField
          id="street-address-1"
          label="Street Address 1"
          variant="outlined"
          value={address1}
          onChange={handlers.address1Change}
          helperText={address1Error.message}
          error={address1Error.hasError}
        />
        <TextField
          id="street-address-2"
          label="Street Address 2 (optional)"
          variant="outlined"
          value={address2}
          onChange={handlers.address2Change}
          helperText={address2Error.message}
          error={address2Error.hasError}
        />
        <TextField
          id="city"
          label="City"
          variant="outlined"
          value={city}
          onChange={handlers.cityChange}
          helperText={cityError.message}
          error={cityError.hasError}
        />
        <TextField
          id="state"
          select
          variant="outlined"
          label="State"
          value={state}
          helperText={stateError.message}
          error={stateError.hasError}
          SelectProps={{
            native: true,
          }}
          onChange={handlers.stateChange}
        >
          <option value=""></option>
          {states.map((state) => {
            return (
              <option key={state.abbrev} value={state.abbrev}>
                {state.name}
              </option>
            );
          })}
        </TextField>
        <TextField
          id="portal-zip-code"
          label="Zip Code"
          variant="outlined"
          value={zip}
          onChange={handlers.zipChange}
          helperText={zipError.message}
          error={zipError.hasError}
        />
      </FormContainer>
      {error && (
        <FormSubmitError>
          <ErrorIcon fontSize="small" />
          {error}
        </FormSubmitError>
      )}

      <ActionButtonsContainer>
        <Button onClick={handlers.cancel}>Cancel</Button>
        {loading ? (
          <Button disabled variant="contained">
            Saving&#8230;
          </Button>
        ) : (
          <Button onClick={handlers.save} variant="contained">
            Save
          </Button>
        )}
      </ActionButtonsContainer>
    </>
  );
};

export default OrganizationLocationForm;
