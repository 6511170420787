import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import ActivityWidget from "../../components/Widgets/activityWidget";
import { Card } from "@mui/material";
import TableHeader from "../../components/Page/tableHeader";
import TableSubHeader from "../../components/Page/tableSubHeader";
import { useParams } from "react-router-dom";

const OrganizationActivityContainer = () => {
  const organization = useSelector((state) => state.organization);
  const { organizationId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "Activity",
          link: "/activity",
        },
      ])
    );
  }, [organization]);

  return (
    <>
      <BreadCrumbs />

      <Card>
        <TableHeader>
          <div>
            <h2>Activity</h2>
            <TableSubHeader>
              Below is a list of all Activity within this Organization
            </TableSubHeader>
          </div>
        </TableHeader>
        <ActivityWidget
          ready={true}
          header="Organization Activity Log"
          organizationId={organizationId}
          showButton={false}
          fullsize={true}
        />
      </Card>
    </>
  );
};

export default OrganizationActivityContainer;
