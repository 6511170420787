// Boiler Plate Imports
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  remove as removeNotification,
  add as addNotification,
} from "../../redux-store/notificationsSlice";
import { update as setBreadCrumbs } from "../../redux-store/breadCrumbsSlice";
import API from "../../api";
import colors from "../../theme/colors";
import { FullPageFormWrapper } from "../../components/Forms/shared";

// Conatiner Component Imports
import BreadCrumbs from "../../components/Navigation/breadcrumbs";
import ModalComponent from "../../components/Modal";

// Page Component Imports
import AddTankForm from "../../components/Forms/organizationTankForm";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmForm from "../../components/Forms/confirmForm";
import { updateReloadOrganization } from "../../redux-store/uxSlice";
import { NoChartDataPlaceholder } from "../../components/NoDataPlaceholder";
import { CircularProgress } from "@mui/material";

const OrganizationAddTankContainer = () => {
  const userProfile = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const { organizationId } = useParams();

  const [savingTank, setSavingTank] = useState(false);
  const [savingTankError, setSavingTankError] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [locations, setLocations] = useState([]);
  const [locationsLoaded, setLocationsLoaded] = useState(false);
  const [attributes, setAttributes] = useState();
  const [ready, setReady] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (attributes && attributes.contents.length > 0) {
      setReady(true);
    }
  }, [attributes]);

  useEffect(() => {
    if (organizationId) {
      if (!locations.length) {
        dataLayer.GetLocations();
      }

      if (!attributes) {
        dataLayer.GetAttributes();
      }
    }
  }, [organizationId]);

  useEffect(() => {
    //Set Bread Crumbs
    dispatch(
      setBreadCrumbs([
        {
          title: organization.name,
          link: "/organization",
        },
        {
          title: "Tanks",
          link: "/tanks",
        },
        {
          title: "Add Tank",
          link: "/tanks/add",
        },
      ])
    );
  }, [organization]);

  const dataLayer = {
    GetLocations: async () => {
      const _locations = await API.Location.LocationsGet({ organizationId });
      setLocations(_locations);
      setLocationsLoaded(true);
    },
    GetAttributes: async () => {
      const _attributes = await API.Tank.TankGetAttributes();
      setAttributes(_attributes);
    },
  };

  const handlers = {
    confirmModalSubmit: () => {
      navigate(`/${organizationId}/tanks`);
    },
    confirmModalCancel: () => {
      setShowConfirmModal(false);
    },
    cancel: () => {
      setShowConfirmModal(true);
    },
    saveTank: async (data) => {
      setSavingTankError("");
      setSavingTank(true);
      try {
        await API.Tank.TankCreate({
          createTankData: { ...data, organizationId },
        });
        //Reload toplevel banners
        dispatch(updateReloadOrganization(true));
        //Create Success Notification
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "success",
            title: "Success",
            message: "Tank created successfully",
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
        navigate(`/${organizationId}/tanks`);
      } catch (err) {
        let errorMessage = err;
        switch (err) {
          case "scaleid-in-use":
            errorMessage =
              "ScaleId is in use on another Tank. Deactivate the other tank or connect this Tank to an new ScaleId";
            break;
          default:
            break;
        }

        setSavingTankError(errorMessage);

        console.log(err);
        const id = new Date().getTime();
        dispatch(
          addNotification({
            id,
            severity: "error",
            title: "Error Saving Tank",
            message: errorMessage,
          })
        );
        setTimeout(() => {
          dispatch(removeNotification(id));
        }, 3000);
      }
      setSavingTank(false);
    },
  };

  return (
    <>
      <BreadCrumbs />
      <FullPageFormWrapper>
        <h2>
          Add New Tank{" "}
          <span style={{ color: colors.gray }}>/ {organization.name}</span>
        </h2>
        {ready && locationsLoaded && locations.length > 0 && (
          <AddTankForm
            handleSave={handlers.saveTank}
            handleCancel={handlers.cancel}
            loading={savingTank}
            error={savingTankError}
            locations={locations}
            contents={attributes.contents}
            makeAndModel={attributes.makeAndModel}
            standardAlarmValues={attributes.standardAlarmValues}
            types={attributes.types}
          />
        )}

        {!ready && <CircularProgress></CircularProgress>}
        {locationsLoaded && locations.length === 0 && (
          <NoChartDataPlaceholder
            header={"THERE ARE NO LOCATIONS IN THIS ORGANIZATION"}
            body={"To add a tank, you must have at least one location created"}
            buttonText={"Add Location"}
            buttonPath={`/${organizationId}/locations`}
          />
        )}
      </FullPageFormWrapper>
      <ModalComponent
        open={showConfirmModal}
        handleClose={handlers.confirmModalCancel}
        headerText={"Cancel Add New Tank"}
      >
        <ConfirmForm
          handleSave={handlers.confirmModalSubmit}
          handleCancel={handlers.confirmModalCancel}
          loading={false}
        >
          <div>
            <b>Are you sure you want to Cancel Adding a New Tank?</b>
            <p>You will lose all progress.</p>
          </div>
        </ConfirmForm>
      </ModalComponent>
    </>
  );
};

export default OrganizationAddTankContainer;
